import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Carousel from "react-bootstrap/Carousel";
import { homeSliderData } from "./HomeSliderData";
import CustomButton from "../../constants/Button";
import { capitalizeStr } from "../../helpers/Capitalize";
import { useHistory } from "react-router-dom";
import { useLanguageContext } from "../../Context/Context";
import Loader from "../Loaders/Loader";
import { useTranslation } from "react-i18next";

const HomeSlider = ({ allSliderList = [], isLoading, openInput }) => {
  const history = useHistory();
  const { checkLanguage } = useLanguageContext();
  const { t } = useTranslation();

  return (
    <Wrapper onClick={openInput}>
      <div className="slider_container">
        <Carousel variant="dark">
          {allSliderList &&
            allSliderList?.map((item, index) => {
              return (
                <Carousel.Item key={item.id}>
                  <div className="background_image">
                    <img
                      className="slider_bakcground d-block w-100 "
                      src={item?.mainphoto}
                      alt="First slide"
                    />
                    <div className="background_overlay"></div>
                  </div>

                  <div className="container">
                    <div className="container-fluid">
                      <Carousel.Caption className="d-flex justify-content-end align-items-center">
                        <div className="w-100">
                          {item.title.length > 100 ? (
                            <h5>
                              {checkLanguage(item)?.title.substring(0, 100)} ...{" "}
                            </h5>
                          ) : (
                            <h5>{checkLanguage(item)?.title}</h5>
                          )}
                          {/*<h5>{checkLanguage(item)?.title}</h5>*/}
                          <p>
                            {checkLanguage(item).description?.substring(0, 200)}
                            ...
                          </p>
                          {item?.startdate ? (
                            <CustomButton
                              onClick={() =>
                                history.push(`/eventsfeed/${item.id}`)
                              }
                            >
                              {t("Читать далее")}...
                            </CustomButton>
                          ) : (
                            <CustomButton
                              onClick={() => history.push(`/news/${item.id}`)}
                            >
                              {t("Читать далее")}...
                            </CustomButton>
                          )}
                        </div>
                      </Carousel.Caption>
                    </div>
                  </div>
                </Carousel.Item>
              );
            })}
        </Carousel>
      </div>

      {isLoading && <Loader />}
    </Wrapper>
  );
};

export default HomeSlider;

const Wrapper = styled.div`
  //min-height: 60vh;
  position: relative;

  .slider_container {
    @media (min-width: 1500px) {
      max-width: 1300px;
      margin: 0 auto;
    }
  }

  .carousel-indicators {
    bottom: 52px !important;
    @media (max-width: 600px) {
      bottom: 32px !important;
    }
  }

  .carousel-dark .carousel-control-prev-icon {
    filter: none !important;
  }

  .carousel-dark .carousel-control-next-icon,
  .carousel-dark .carousel-control-prev-icon {
    filter: none !important;
  }

  .container {
    //position: relative;
  }

  .carousel_content_info {
    width: 400px;

    .carousel_content_inner {
    }
  }

  .slider_bakcground {
    object-fit: cover;
    min-height: 400px;
    position: relative;
  }

  .background_overlay {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
  }

  .background_image {
    position: relative;
    //:before{
    //  position: absolute;
    //  content: "adsvsd";
    //  background: rgba(0,0,0,0.5);
    //  width: 100%;
    //  height: 100%;
    //  inset: 0;
    //  z-index: 2000;
    //}

    img {
      height: 500px;
      background-position: center;
      background-size: cover;
      @media (max-width: 768px) {
        height: 300px;
      }
    }
  }

  .carousel-indicators [data-bs-target] {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    //border: 1px solid #fff;
  }

  .carousel-caption {
    margin-bottom: clamp(50px, 10vw, 100px);

    @media (max-width: 600px) {
      width: 100%;
      right: 0;
      left: 0;
    }

    h5 {
      font-weight: 500;
      //font-size: 36px;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      color: #ffffff;
      @media (max-width: 768px) {
        font-weight: 500;
        font-size: 24px;
        line-height: 20px;
        text-align: right;
        text-align: center;

        color: #ffffff;
      }
    }

    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 29px;
      text-align: center;
      width: 60%;
      margin: 30px auto;

      color: #ffffff !important;
      @media (max-width: 768px) {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        width: 90%;
        margin: 20px auto;
        color: #ffffff;
      }
    }
  }

  .home_slider_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
