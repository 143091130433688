import React, { Component } from 'react';

import ErrorIndicator from './ErrorIndicator';

export default class ErrorBoundary extends Component {

	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			error: error,
			errorInfo: errorInfo
		})
	}

	render() {
		if (this.state.errorInfo) {
			return (
				<ErrorIndicator />
			);
		}
		return this.props.children;
	}
}
