import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import SectionTitles from "../../constants/SectionTitles";
import moment from "moment";
import timeIcon from "../../assets/images/events/timeIcon.png";
import zoomIcon from "../../assets/images/events/zoomIcon.png";
import zoomArrow from "../../assets/images/events/zoomArrow.png";
import {Link, useHistory} from "react-router-dom";
import {useLanguageContext} from "../../Context/Context";
import {useTranslation} from "react-i18next";
import Loader from "../Loaders/Loader";

const HomeEventNews = ({announcement, isLoading}) => {

	const {checkLanguage, language} = useLanguageContext()
	const {t} = useTranslation();

	const history = useHistory()

	return (
		<Wrapper>
			<div className="container">
				<div className = "container-fluid">

				<Link to={"/eventsfeed"}>
					<SectionTitles>{t("Announce")}</SectionTitles>
				</Link>
				<div className="home_news_items">
					{
						announcement.slice(0, 3).map((el, index) => {
							return <div className="home_news_item" onClick={() => history.push(`/eventsfeed/${el.id}/`)}
							            key={el.id}>
								<div className='title'>
									<h3>{el.title.length > 30 ? checkLanguage(el).title.substr(0, 30) + '...' : checkLanguage(el).title} </h3>
									<div>
										<div className={'date-month'}>
											<div> {moment(el?.startdate).format('DD')}</div>
											<div>{moment(el?.startdate).locale('ru').format('MMMM').substr(0, 3)}</div>
										</div>
									</div>
								</div>
								<hr/>

								<div>
									<div className='info'>
										<div className='info-img'>
											<img
												src={el.small ? el.small : 'https://image.shutterstock.com/image-vector/prohibition-sign-no-photography-600w-209270626.jpg'}
												alt=""/>
										</div>
										<div className="info-text">
											<p>{!el.description && 'Приглашаем Вас принять участие в онлайн-тренинге '}
												{el.description.length > 100 ? checkLanguage(el).description.substr(0, 100) + '...' : checkLanguage(el).description}</p>
											<div className="announce_bottom">
												<p className='time'>
													<img src={timeIcon}
													     alt=""/>
													{moment(el.startdate).format('LT')} - {el?.enddate ? moment(el?.enddate).locale(language).format('DD') : t('дата не указана')}-{el?.enddate && moment(el?.enddate).locale(language).format('MMMM').substring(0, 3)}
													{/*/ {el?.enddate && moment(el?.enddate).format("LT")}*/}
												</p>
												<div className='zoom'>
													<p>
														<img src={zoomIcon}
														     alt=""/>
														<span>{el.place.length > 25 ? checkLanguage(el).place.substring(0, 25)+ '...' : checkLanguage(el).place}</span>

													</p>
													<p>
														<img src={zoomArrow} alt=""/>
														<img src={zoomArrow} alt=""/>
														<img src={zoomArrow} alt=""/>

													</p>
												</div>
											</div>

										</div>
									</div>

								</div>
							</div>
						})
					}
				</div>
				</div>
			</div>
			{
				isLoading && <Loader/>
			}
		</Wrapper>
	)
}

export default HomeEventNews

const Wrapper = styled.div`
  .home_news_btn {
    img {
      width: 100%;
      height: 15px;
    }
  }

  .home_news_items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-gap: 20px;
    width: 100%;
    @media (max-width: 768px) {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }

  .home_news_item {
    background: #FFFFFF;
    cursor: pointer;
    border-radius: 7.19212px;
    position: relative;
    margin: 0 5px;
    margin-bottom: 30px;
    box-shadow: 0px 6.0566px 12.1132px rgba(0, 0, 0, 0.2);
 

    .date-month {
      display: block !important;
    }


    .title {
      display: flex;
      justify-content: space-between;
      min-height: 74px;

      h3 {
        font-family: "Montserrat", sans-serif;
        margin-top: 18px;
        //margin-bottom: 14px;
        padding-bottom: 10px;
        padding-left: 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 17.9803px;
        line-height: 22px;
        color: #000000;
        //width: 330px;
        white-space: break-spaces;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        position: relative;

        :before {
          position: absolute;
          content: "";
          height: 2px;
          width: 100px;
          bottom: 0%;
          background: #CADB36;
        }

      }

      > div {
        width: 65px;
        height: 64px;
        background: #CADB36;
        border-radius: 0px 7.19212px 0px 0px;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Montserrat", sans-serif;
          text-align: center;
          font-style: normal;
          font-weight: 600;
          font-size: 26.9704px;
          line-height: 33px;
          min-width: 60px;
          color: #FAFAFA;
        }
      }
    }

    .info {
      display: flex;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12.9458px;
      line-height: 15px;
      color: #000000;

      .info-img {
        width: 140px;
        height: 145px;

        img {
          border-radius: 0px 7.19212px;
          height: 100%;
          width: 100%;
          width: 140px;
          object-fit: cover;
          @media (max-width: 500px) {
            height: 125px;
            width: 125px;
          }
        }
      }

      .info-text {
        position: relative;

        .announce_bottom {
          position: absolute;
          bottom: 0;
          width: 100%;
        }

        p {
          display: flex;
          color: #000000;
          padding-left: 11px;
          margin-bottom: 18px;
          align-items: center;
          font-size: 12px;


          img {
            margin-right: 3px;
          }
        }

      }

      .time {
        font-size: 10.069px;
        line-height: 12px;
        color: #000000;
        white-space: nowrap;
      }

      .zoom {
        display: flex;
        justify-content: space-between;
        font-size: 10.069px;
        line-height: 12px;
        color: #000000;
        margin-right: 16px;
      }
    }


  }

  .eventnews_date {
    position: absolute;
    right: 0;
    top: 0;
    background: #CADB36;
    border-radius: 0px 10px 0px 0px;
    padding: 5px 10px 0px 10px;

    p {
      font-weight: 600;
      font-size: 16px;
      //line-height: 20px;
      color: #FAFAFA;
      word-wrap: break-word;
    }
  }
`
