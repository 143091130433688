import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ContentSidebar from "../../constants/ContentSidebar";
import SectionTitles from "../../constants/SectionTitles";
import Path from "../../constants/Path";
import ToShare from "../../constants/ToShare";
import PhazeComponent from "../../components/PhazeComponent/PhazeComponent";
import { useParams } from "react-router-dom";
import { getPhase } from "../../api/Requests";
import { useLanguageContext } from "../../Context/Context";
import Loader from "../../components/Loaders/Loader";
import { useTranslation } from "react-i18next";
import Share from "../../components/Share/Share";
import ResourcesModal from "../Resources/ResourcesModal";
import ScrollToTop from "../../constants/ScrollToTop/ScrollToTop";

const Phaze = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [phase, setPhase] = useState({});
  const { id } = useParams();
  const { checkLanguage } = useLanguageContext();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [file, setFile] = useState(null);

  const handleModal = (e, id, file) => {
    e.preventDefault();

    setFile(file);
    setOpenModal(!openModal);
  };

  const fetchPhaze = async () => {
    try {
      setIsLoading(true);
      const response = await getPhase(id);
      setPhase({...response.data});
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchPhaze(id);
  }, [id]);

  return (
    <Wrapper>
      <ScrollToTop />
      <div className="container ">
        <div className="container-fluid">
          {openModal && (
            <ResourcesModal handleModal={handleModal} file={file} />
          )}
          <SectionTitles>
            {id == 1 && t("Phase1")}
            {id == 2 && t("Phase2")}
            {id == 3 && t("Phase3")}
          </SectionTitles>
          <Path aboutProject={true} title={checkLanguage(phase)?.title}></Path>
          <div className="home_news_content">
            <div className="row">
              <div className="col-md-9 col-sm-12 col-xs-12">
                <div className="grid_parent">
                  <PhazeComponent
                    phase={phase}
                    paramId={id}
                    handleModal={handleModal}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <ContentSidebar
                  calendar={true}
                  opros={false}
                  recpublic={false}
                />
              </div>
            </div>
            <Share />
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </Wrapper>
  );
};

export default Phaze;

const Wrapper = styled.div`
  .home_news_image {
    margin-bottom: -10px;
    width: 100%;
  }

  .home_news_title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  .home_news_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }

  .news_date {
    font-size: 12px;
    line-height: 15px;
    color: #949494;
  }

  .home_news_btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #1862af;
  }

  @media screen and (max-width: 1024px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
  }
  @media screen and (max-width: 768px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 100%;
    }

    .col-md-3 {
      width: 100%;
    }

    .home_news_info_content {
      margin-bottom: 50px;
    }
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 40px;
    .col-md-9 {
      flex: 0 0 auto;
      width: 100%;
    }

    .col-md-3 {
      width: 100%;
    }
  }
`;
