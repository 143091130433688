import React from 'react';
import {Alert, Button} from "react-bootstrap";
import styled from "styled-components"

const ErrorIndicator = () => {
	return (
		<Wrapper>
			<div className="container">
				<div className="text-center">
					<Alert color="warning" variant={"danger"}>
						<h1>Упс...</h1>
						<p>Что то пошло не так</p>
						<Button variant="primary" color="info" onClick={() => window.location.href = "/"}>Back to
							Home</Button>
					</Alert>
				</div>
			</div>
		</Wrapper>
	);
};

export default ErrorIndicator;

const Wrapper = styled.div`
	display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

`
