import React from "react";
import styled, { css } from "styled-components";
import imo from "../../assets/images/staffall/IMO.png";
import mer from "../../assets/images/staffall/MER.png";
import email from "../../assets/images/staffall/email.png";
import miniemail from "../../assets/images/staffall/ic_email.png";
import { useLanguageContext } from "../../Context/Context";
import { capitalizeStr } from "../../helpers/Capitalize";
import { useHistory, useParams } from "react-router-dom";

export const StaffCard = ({ el }) => {
  const { checkLanguage } = useLanguageContext();
  const history = useHistory();
  const { id } = useParams();
  const handleRedirect = () => {
    history.push(`/staff-all/${el?.id}`);
  };

  return (
    <Wrapper onClick={handleRedirect}>
      <div className="img">
        <img
          src={
            el.image
              ? el.image
              : "https://hope.be/wp-content/uploads/2015/05/no-user-image.gif"
          }
          alt=""
        />
      </div>
      <div className={"text"}>
        <h3>{checkLanguage(el).title}</h3>
        <p className="position">
          {el.position ? el.position : "Должность не указана."}
        </p>
        <p className="email">
          <img src={email} alt="" /> {el.email ? el.email : "Email не указан."}
        </p>
        <div className="company">
          {el.organization === 1 && <img src={imo} alt="" />}
          {el.organization === 2 && <img src={mer} alt="" />}
        </div>
      </div>
    </Wrapper>
  );
};

export const ResponsiveStaffCard = ({ el }) => {
  const { checkLanguage } = useLanguageContext();
  const { id } = useParams();
  const handleRedirect = () => {
    history.push(`/staff-all/${el?.id}`);
  };
  return (
    <RespWrapper onClick={handleRedirect}>
      <div className={"img"}>
        <div className={"avatar"}>
          <img
            src={
              el.image
                ? el.image
                : "https://hope.be/wp-content/uploads/2015/05/no-user-image.gif"
            }
            alt=""
          />
        </div>
        <div className={"position"}>
          <p>{el.position ? el.position : "Должность не указана."}</p>
          <div className={"email"}>
            <div>
              <img src={miniemail} alt="" />
            </div>
            <span>{el.email ? el.email : "Email не указан."}</span>
          </div>
        </div>
      </div>
      <div className={"block-2"}>
        <h3>{el.title ? checkLanguage(el).title : "Имя не указано."}</h3>
        <h5
          style={
            el.organization === 2
              ? { background: "#008DD2" }
              : { background: "#CADB36" }
          }
        >
          {el.organization === 2 ? "MER" : "ИМО"}
        </h5>
      </div>
    </RespWrapper>
  );
};
const RespWrapper = styled.div`
  display: none;
  background: #fafafa;
  box-shadow: 0px 10.177px 20.3541px rgba(204, 204, 204, 0.4);
  border-radius: 11.3078px;
  max-width: 330px;
  min-width: 250px;
  min-height: 200px;
  max-height: 220px;
  position: relative;
  margin: 0 auto;
  @media screen and (max-width: 425px) {
    display: block;
  }

  .img {
    display: flex;

    div img {
      width: 100%;
      height: 100px;
    }

    .avatar {
      min-width: 100px;
      margin-right: 20px;
    }

    div p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      color: #000000;
      margin-bottom: 0;
      max-width: 120px;
      margin-left: 14px;
      margin-right: 34px;
      display: flex;
      align-items: center;
      margin-top: 20%;
    }
  }

  .email {
    position: absolute;
    bottom: 25%;
    right: 10%;
    max-width: 90px;
    word-break: break-word;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-left: 5px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      color: #1862af;
    }
  }

  .block-2 {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      width: 180px;
      text-align: left;
      margin-left: 10px;
    }

    h5 {
      border-radius: 0px 0px 11.3078px 0px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      font-size: 18.7858px;
      line-height: 22px;
      color: #ffffff;
      position: absolute;
      right: 0;
      bottom: 0;
      margin-bottom: 0;
      padding: 3px 12px 2px 8px;
    }
  }
`;

const Wrapper = styled.div`
  cursor: pointer;
  background: #fafafa;
  box-shadow: 0px 6.42135px 12.8427px rgba(204, 204, 204, 0.4);
  border-radius: 10px;
  max-width: 265px;
  margin-bottom: 45px;
  .img img {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    height: 191px;
    object-fit: cover;
  }
  .text {
    margin-left: 10px;
  }
  h3 {
    margin-top: 11px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }
  .position {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
  .email {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #1862af;
    margin-bottom: 5px;
  }
  .company {
    display: flex;
    justify-content: flex-end;
  }
  @media screen and (max-width: 425px) {
    max-width: 100%;
    display: none;
  }
`;
