import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SectionTitles from "../../constants/SectionTitles";
import Path from "../../constants/Path";
import ContentSidebar from "../../constants/ContentSidebar";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../api/axiosInstance";
import Loader from "../../components/Loaders/Loader";
import ScrollToTop from "../../constants/ScrollToTop/ScrollToTop";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import { useLanguageContext } from "../../Context/Context";
import { capitalizeStr } from "../../helpers/Capitalize";
import { useTranslation } from "react-i18next";
import ImagesCarousel from "../../components/News/NewsCarousel";

const ReformFaceDetailPage = () => {
  const [reformFaceDetail, setReformFaceDetail] = useState();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { checkLanguage } = useLanguageContext();
  const { t } = useTranslation();

  const getReformFaceDetail = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`reforma/${id}/`);
      setReformFaceDetail(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log("error =>", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getReformFaceDetail();
  }, []);
  return (
    <Wrapper>
      <ScrollToTop />

      <div className="container">
        <div className="container-fluid">
          <SectionTitles>{t("ReformFace")}</SectionTitles>
          <Path
            title={checkLanguage(reformFaceDetail)?.title}
            reformface={true}
          />
          <div className="reform_face_content">
            <div className="row">
              <div className="col-md-9">
                <div className="reform_face_content_header">
                  <div className="reform_face_content_image">
                    <img src={reformFaceDetail?.image} alt="" />
                  </div>
                  <div className="reform_face_content_info">
                    <h4>{checkLanguage(reformFaceDetail)?.title} </h4>
                    <p>{checkLanguage(reformFaceDetail)?.research}</p>
                  </div>
                </div>
                <div className="reform_face_content_text">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: checkLanguage(reformFaceDetail)?.text,
                    }}
                  ></p>
                </div>
              </div>
              <div className="col-md-3">
                <ContentSidebar recpublic={false} />
              </div>
            </div>
          </div>
        </div>
        {reformFaceDetail?.gallery &&
          reformFaceDetail?.gallery?.length >= 1 && (
            <ImagesCarousel images={reformFaceDetail?.gallery} />
          )}
      </div>

      {isLoading && <Loader />}
    </Wrapper>
  );
};

export default ReformFaceDetailPage;

const Wrapper = styled.div`
  padding: 30px 0 100px;

  .reform_face_content_header {
    display: flex;
    //justify-content: space-between;
    align-items: center;

    img {
      //width: 100%;
      //outline: 7px solid #008DD2;
      //outline-offset: 5px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      object-fit: cover;
      height: 300px;
      margin-right: 30px;
      width: 300px;
      border: 7px solid #008dd2;
      padding: 5px;
      @media (max-width: 768px) {
        height: 250px;
        width: 250px;
      }
      @media (max-width: 500px) {
        height: 100px;
        width: 100px;
      }
    }

    margin-bottom: 30px;
    margin-top: 30px;
  }

  .reform_face_content_info {
    h4 {
      font-weight: 600;
      font-size: 28.799px;
      line-height: 35px;
      text-align: center;
      color: #20386e;
      margin-bottom: 10px;
      @media (max-width: 768px) {
        font-size: 18.799px;
        text-transform: capitalize;
      }
    }

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 17px;
      text-align: center;
      color: #949494;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  .swiper_carousel {
    //max-height: 100px;
    max-width: 80%;
    margin: 0 auto;
    margin-top: 30px;
    @media (max-width: 768px) {
      display: none;
    }

    .swiper-container {
      position: relative;
      width: 90vw;
      height: 300px;
    }

    .swiper-container > .swiper-slide__content {
      position: absolute;
      top: 0;
    }

    .swiper-slide {
      height: 300px;
      display: flex;
      //background: #eee;
      align-items: center;
      justify-content: center;
      transition: all 200ms linear;
      transform: scale(0.8);

      &.swiper-slide-active {
        transform: scale(1);
        //background: #fc0
      }
    }

    .swiper-slide__content {
      height: 300px;
    }

    .slick-next:before,
    .slick-prev:before {
      font-size: 20px;
      line-height: 1;
      opacity: 0.75;
      color: #000;
    }
  }

  .image_parent {
    width: 250px;

    img {
      margin-left: 25px;
      width: 100%;
      object-fit: cover;
    }

    .slider_image {
      //margin: 0 30px;
      //width: 100%;
      height: 250px;
    }
  }

  .swiper_carousel_responsive {
    display: none;

    .image_parent {
      width: 200px;

      img {
        margin-left: 25px;
        width: 100%;
        object-fit: cover;
      }

      .slider_image {
        //margin: 0 30px;
        //width: 100%;
        height: 250px;
      }
    }

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
