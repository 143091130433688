import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ImagesCarousel from "../News/NewsCarousel";
import { axiosInstance } from "../../api/axiosInstance";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useLanguageContext } from "../../Context/Context";
import { capitalizeStr } from "../../helpers/Capitalize";
import { useTranslation } from "react-i18next";
import PdfIcon from "../../assets/images/pdf-type-icon.svg";
import WordTypeIcon from "../../assets/images/word-type-icon.svg";
import DownloadIcon from "../../assets/images/majesticons_folder-download.png";

const PhazeComponent = ({ phase = {}, paramId, handleModal }) => {
  const { t } = useTranslation();
  const { checkLanguage } = useLanguageContext();

  const fileType = phase?.file?.split(".").pop();

  const PdfType = fileType === "pdf";
  const DocxType = fileType === "docx";

  return (
    <>
      <NewsDetail>
        <div className="image-container">
          <div className={"card-image"}>
            <h1
              dangerouslySetInnerHTML={{
                __html: checkLanguage(phase.data)?.title,
              }}
            ></h1>
            <span>{moment(phase?.created).format("DD.MM.YY")}</span>
            <div className="card-container">
              <h3>
                {paramId == 1 && t("Данные по проекту первой фазы")}
                {paramId == 2 && t("Данные по проекту второй фазы")}
                {paramId == 3 && t("Данные по проекту третий фазы")}
              </h3>
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: checkLanguage(phase)?.description,
                }}
              ></div>
            </div>
          </div>
          <div className="img-right">
            <img src={phase?.image} alt="" />
          </div>
        </div>
        <p
          dangerouslySetInnerHTML={{ __html: checkLanguage(phase.data)?.text }}
        ></p>
        {phase?.file && (
          <div className="file_content">
            <div className="doc_type_img">
              <a
                href={"#"}
                onClick={(e) => handleModal(e, phase?.id, phase?.file)}
              >
                {PdfType && <img src={PdfIcon} alt="" />}
                {DocxType && <img src={WordTypeIcon} alt="" />}
                {!PdfType && !DocxType && (
                  <img
                    src="https://thumbs.dreamstime.com/b/document-icon-vector-stack-paper-sheets-illustration-131104983.jpg"
                    alt=""
                  />
                )}
              </a>
            </div>
            <div className="download_button">
              <img src={DownloadIcon} alt="" />
              <a target={"_blank"} href={phase?.file}>
                {t("Скачать")}
              </a>
            </div>
          </div>
        )}
        {phase?.gallery_phaze?.length >= 1 && (
          <ImagesCarousel images={phase?.gallery_phaze} />
        )}
      </NewsDetail>
    </>
  );
};

export default PhazeComponent;

const NewsDetail = styled.div`
  max-width: 877px;

  //img {
  //  //max-width: 877px;
  //  width: 100%;
  //}
  .doc_type_img {
    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
  }

  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #1862af;
    margin-top: 22px;
  }

  .file_content {
    display: flex;
    align-items: center;
  }
  .download_button {
    margin-left: 30px;
    img {
      margin-right: 10px;
    }
    a {
      text-decoration: none;
      font-weight: 500;
      font-size: 16.677px;
      line-height: 20px;
      color: #1862af;
      cursor: pointer;
    }
  }

  .text {
    padding-left: 20px;
    padding-top: 10px;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      //line-height: 20px;
      color: #fff;

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        //line-height: 20px;
        color: #fff !important;
      }

      //margin-bottom: 61px;
    }
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;

    color: #000000;
  }

  .image-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      display: block;
    }
  }

  .card-image {
    position: relative;

    h1 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #1862af;

      p {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #1862af;
      }
    }
  }

  .card-container {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
    background: #1862af;
    position: absolute;
    border-radius: 10px;
    width: 400px;
    left: 0px;
    bottom: 30px;
    z-index: 20;
    @media (max-width: 1024px) {
      width: 300px;
    }
    @media (max-width: 768px) {
      width: 90%;
      margin: 0 auto;
      margin-top: 30px;
      position: relative;
      padding: 10px;
    }

    h3 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      text-align: justify;
      color: #ffffff;
      padding-left: 15px;
      padding-top: 25px;
      padding-right: 90px;
    }

    p {
      span {
        background-color: transparent;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: justify;
        color: #ffffff;
        background: none;
      }
    }
  }

  ul li {
    display: flex;
    margin-bottom: 13px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    color: #ffffff;
    width: 324px;
    padding-right: 28px;
  }

  ul li div {
    margin-bottom: 0;
  }

  ul li div span {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #cadb36;
    padding-right: 20px;
    margin-bottom: 0;
  }

  .img-right {
    margin-left: 50px;

    img {
      width: 450px;
      height: 330px;
      object-fit: cover;
      @media (max-width: 768px) {
        width: 100%;
      }
    }

    //height: 300px;
    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }

  @media screen and (max-width: 1024px) {
    .img-right img {
      max-width: 400px;
    }
  }
`;
