import React from 'react'
import styled from "styled-components"
import {capitalizeStr} from "./Capitalize";


const ToNormalDate = ({date}) => {
    const month = date.toLocaleString("ru-RU", { month: "long" });
    const day = date.toLocaleString("ru-RU", { day: "2-digit" });
    const year = date.getFullYear();
    return (
        <Wrapper>
            <span>{day}</span> - <span>{capitalizeStr(month )}</span> ,
                <span>{year}</span>
        </Wrapper>
    )
}

export default ToNormalDate

const Wrapper = styled.div`

`
