import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SectionTitles from "../../constants/SectionTitles";
import Path from "../../constants/Path";
import gps from "../../assets/images/map/gps.png";
import carbonSearch from "../../assets/images/map/carbon_search.svg";
import { Map, Placemark, YMaps, ZoomControl } from "react-yandex-maps";
import { getMap, searchFromMap } from "../../api/Requests";
import { Accordion } from "react-bootstrap";
import MapAccordtion from "./Accordtion";

import Loader from "../../components/Loaders/Loader";
import { useTranslation } from "react-i18next";

const InteractiveMap = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [mapItems, setMapItems] = useState([]);
  const [ltd, setLtd] = useState(null);
  const [lng, setLng] = useState(null);
  const [centerZoom, setCenterZoom] = useState([42.878645, 74.616205]);
  const [logo, setLogo] = useState("");
  const [mapLngLtd, setMapLngLtd] = useState([]);
  const [logoName, setLogoName] = useState("");
  const [address, setAddress] = useState("");
  const [mapSearchInput, setMapSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [allMapsAddress, setAllMapsAddress] = useState([]);
  const [center, setCenter] = useState("");
  const map = useRef();

  const { t } = useTranslation();

  const interactiveMapData = async () => {
    try {
      setIsLoading(true);
      const response = await getMap();

      setMapItems(response);

      const transformAddress = response.map((item) => {
        item?.map?.map((item) => {
          allMapsAddress?.push(item);
        });
      });
      // setAllMapsAddress(transformAddress)
      setIsLoading(false);
    } catch (error) {
      console.log("error =>", error);
      setIsLoading(true);
    }
  };

  const searchFromInteractiveMap = async (e) => {
    e.preventDefault();
    try {
      if (mapSearchInput) {
        const response = await searchFromMap(mapSearchInput);
        setSearchResults({ data: response.data });

        setMapLngLtd(null);
        setLng(null);
        setLtd(null);
        setAllMapsAddress(null);
      }
    } catch (error) {
      console.log("error =>", error);
    }
  };

  useEffect(() => {
    interactiveMapData();
  }, []);

  const showPlacemark = (item) => {
    setLtd(item.latitude);
    setLng(item.longitude);
    setLogo(item.logo);
    setLogoName(item.title);
    setAddress(item.place);
    setCenterZoom([item.latitude, item.longitude]);
    onclickToAccordtion(12);
    window.scrollTo(0, 70);

    setMapLngLtd(null);
    setAllMapsAddress(null);
    setSearchResults(null);
  };

  const onclickToAccordtion = (number) => {
    map && map.current && map.current.setZoom(number);
  };

  const showPlacemarks = (map) => {
    setMapLngLtd(map);
    setLng(null);
    setLtd(null);
    setAllMapsAddress(null);
    onclickToAccordtion(10);
    setSearchResults(null);
  };

  return (
    <Wrapper>
      <div className="container">
        <div className="container-fluid">
          <SectionTitles> {t("InteractiveMap")}</SectionTitles>
          <Path contacts={true} title={"Интерактивная карта"} />
          <div className="videogallery_content">
            <div className="row">
              <div>
                <div className="page">
                  <div className={"search"}>
                    <form onSubmit={searchFromInteractiveMap}>
                      <div className="addresses">
                        <div>
                          <img src={gps} alt="" />
                        </div>
                        <input
                          placeholder={"Поиск места"}
                          type="text"
                          value={mapSearchInput}
                          required={true}
                          onChange={(e) => setMapSearchInput(e.target.value)}
                        />
                        <button type={"submit"}>
                          <img src={carbonSearch} alt="" />
                        </button>
                      </div>
                    </form>
                    <div className="accordtion">
                      <Accordion defaultActiveKey="0">
                        {mapItems?.map((item) => {
                          return (
                            <MapAccordtion
                              showPlacemarks={showPlacemarks}
                              showPlacemark={showPlacemark}
                              item={item}
                              key={item.id}
                            />
                          );
                        })}
                      </Accordion>
                    </div>
                  </div>
                  <YMaps>
                    <div className="map">
                      <Map
                        defaultState={{ center: centerZoom, zoom: 10 }}
                        options={{ suppressMapOpenBlock: true }}
                        width={"100%"}
                        height={"100%"}
                        modules={[
                          "templateLayoutFactory",
                          "layout.ImageWithContent",
                        ]}
                        instanceRef={map}
                      >
                        <ZoomControl
                          options={{
                            float: "left",
                            size: "small",
                            position: { bottom: 0 },
                          }}
                        />

                        {allMapsAddress &&
                          allMapsAddress.flat().map((el) => {
                            return (
                              <Placemark
                                key={el.id}
                                options={{
                                  iconLayout: "default#image",
                                  iconImageHref: el.logo,
                                  iconImageSize: [30, 30],
                                  iconImageOffset: [-10, -10],
                                  iconCaption: el.title,
                                }}
                                geometry={[el.latitude, el.longitude]}
                                properties={{
                                  hintContent: `<p><strong>${el?.title}</strong> <br/> <small>${el?.place}</small></p> `,
                                }}
                                modules={[
                                  "geoObject.addon.balloon",
                                  "geoObject.addon.hint",
                                ]}
                              />
                            );
                          })}
                        {lng && ltd && (
                          <Placemark
                            options={{
                              iconImageHref: logo,
                              iconLayout: "default#image",
                              iconImageSize: [30, 30],
                              iconImageOffset: [-10, -10],
                            }}
                            properties={{
                              hintContent: `<p><strong>${address}</strong> <br/> <small>${logoName}</small></p> `,
                            }}
                            geometry={[ltd, lng]}
                            modules={[
                              "geoObject.addon.balloon",
                              "geoObject.addon.hint",
                            ]}
                          />
                        )}

                        {mapLngLtd &&
                          mapLngLtd.map((el, id) => {
                            return (
                              <Placemark
                                key={el.id}
                                options={{
                                  iconLayout: "default#image",
                                  iconImageHref: el.logo,
                                  iconImageSize: [30, 30],
                                  iconImageOffset: [-10, -10],
                                  iconCaption: el.title,
                                }}
                                geometry={[el.latitude, el.longitude]}
                                properties={{
                                  hintContent: `<p><strong>${el?.title}</strong> <br/> <small>${el?.place}</small></p> `,
                                }}
                                modules={[
                                  "geoObject.addon.balloon",
                                  "geoObject.addon.hint",
                                ]}
                              />
                            );
                          })}
                        {searchResults?.data?.map?.length > 0 &&
                          searchResults.data.map.map((el) => {
                            return (
                              <Placemark
                                key={el.id}
                                modules={[
                                  "templateLayoutFactory",
                                  "layout.ImageWithContent",
                                ]}
                                options={{
                                  iconLayout: "default#image",
                                  iconImageHref: el.logo,
                                  // iconImageHref: el.logo,
                                  iconImageSize: [30, 30],
                                  iconImageOffset: [-10, -10],
                                  iconCaption: el.title,
                                }}
                                geometry={[el.latitude, el.longitude]}
                                properties={{
                                  hintContent: `<p><strong>${el?.title}</strong> <br/> <small>${el?.place}</small></p> `,
                                }}
                                modules={[
                                  "geoObject.addon.balloon",
                                  "geoObject.addon.hint",
                                ]}
                              />
                            );
                          })}
                      </Map>
                    </div>
                  </YMaps>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </Wrapper>
  );
};

export default InteractiveMap;

const Wrapper = styled.div`

  padding: 70px 0;
  padding-bottom: 60px;

  .page {
    display: flex;
    justify-content: space-between;
  }

  .photogallery_item {

    background: #FAFAFA;
    box-shadow: 0px 6.47069px 12.9414px rgba(204, 204, 204, 0.4);
    border-radius: 7.18965px;

    iframe {
      width: 100%;
      border-radius: 7.18965px 7.18965px 0px 0px;
      position: relative;
      z-index: 100;
    }
  }

  .videogallery_content {
    margin-top: 25px;
  }

  .accordtion {
    margin-right: 40px;
  }

  .photogallery_footer {
    .date {
      padding: 7px 10px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #505050;
    }
  }

  .right_arrow {
    display: flex;
    margin-right: 20px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #767676;
  }

  .videogallery_text {
    font -family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }

  .addresses {
    display: flex;
    border-radius: 5.70232px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #FAFAFA;
    justify-content: space-evenly;
    margin-bottom: 25px;

    input {
      border: none;
    }

    input:focus {
      border: none;
      outline: none;
    }

    button {
      border: none;
      background: transparent;
    }
  }

  .search {
    margin-right: 30px;
  }

  .univer-point {
    margin-top: 8.5px;
    background: #FFFFFF;
    box-shadow: 0px 9px 18px rgba(204, 204, 204, 0.4);
    border-radius: 7px;
    display: flex;
    align-items: center;

    div img {
      padding-left: 10px;
    }

    div h4 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      margin-bottom: 0;
      padding: 10px 0px;
      padding-right: 7px;
      padding-left: 7px;
    }

    div span {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #999999;
    }

    div h5 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      color: #1862AF;
    }
  }

  .map {
    width: 60vw !important;
    height: 65vh !important;
  }

  .ymaps-2-1-79-float-button {
    background: #1862AF;
    border-radius: 0px 4.08305px 0px 0px;
  }

  .ymaps-2-1-79-float-button-icon {
    -webkit - tap - highlight - color: white !important;
    color: white;
  }

  @media screen and (max-width: 1024px) {
    .map {
      height: 100vh !important;
    }
  }
  @media screen and (max-width: 768px) {
    .map {
      height: 40% !important;
      width: 90vw !important;
      margin: 0 auto !important;
      margin-bottom: 30px;
    }

    .page {
      flex-flow: column-reverse;
    }

    .addresses {
      display: none;
    }

    .search {
      margin-top: 12px;
      margin-right: 30px;
    }
  }
  @media screen and (max-width: 425px) {
  }
`;
const SideBarWrapper = styled.div`
  width: 245px;
  margin: 0 auto;
  //background: red;
  .new_info_title {
    margin -bottom: 30px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #1862AF;
  }

  .button {
    display: flex;
    align-items: center;
    text-align: left;
    background: #F9F9F9;
    box-shadow: 0px 6.41512px 12.8302px rgba(204, 204, 204, 0.4);
    border-radius: 5.70232px;
    border: none;
    width: 100%;
    padding: 12px 0px;

    img {
      margin-left: 17px;
      margin-right: 8px;
      margin-top: 18px;
      margin-bottom: 13px;
    }
  }

`;
