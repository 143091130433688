import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import SectionTitles from "../../constants/SectionTitles";
import {getDonor, getPartner} from "../../api/Requests";
import Loader from "../Loaders/Loader";
import {useLanguageContext} from "../../Context/Context";
import {capitalizeStr} from "../../helpers/Capitalize";
import {useTranslation} from "react-i18next";
import Path from "../../constants/Path";
import noImage from "../../assets/images/homePartners/no-image-square.jpg"

const PartnersComp = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [partner, setPartner] = useState([])
    const [donors, setDonor] = useState([])
    const { checkLanguage} = useLanguageContext()
    const {t} = useTranslation();

    useEffect(() => {
        getPartner(setIsLoading, setPartner )
    }, [])

    if(isLoading) return <Loader/>
    return (
        <>
                 <NewsDetail >
                    <SectionTitles>{t("Partners")}</SectionTitles>
                     <Path  aboutUs={true} title={t('Partners')}></Path>
                    {/*<div className='list-partners'>*/}
                    {/*    <div className='name-organization'>*/}
                    {/*        <h2>*/}
                    {/*            {t("Название организаций")}*/}
                    {/*        </h2>*/}
                    {/*        /!*<h2>*!/*/}
                    {/*        /!*    {t("Contacts")}*!/*/}
                    {/*        /!*</h2>*!/*/}
                    {/*    </div>*/}
                    {/*    {*/}
                    {/*        partner?.map((el, id) => {*/}
                    {/*            return    <div className='name-organization white-color' key={el.id}>*/}
                    {/*                <h2>*/}
                    {/*                    {(checkLanguage(el).title)}*/}
                    {/*                </h2>*/}
                    {/*                /!*<h2>*!/*/}
                    {/*                /!*    <a href={el.link} target={'_blank'}>*!/*/}
                    {/*                /!*        {el.link}*!/*/}
                    {/*                /!*    </a>*!/*/}
                    {/*                /!*</h2>*!/*/}
                    {/*            </div>*/}
                    {/*        })*/}
                    {/*    }*/}
                    {/*</div>*/}
                     <div className="donor_content">
                         {
                             partner?.map((item) => {
                                 return <div key={item.id}>
                                     <a href={item.link}  target={"_blank"}> <h4>{capitalizeStr(checkLanguage(item).title)}</h4></a>
                                     <div className = "image">
                                         <img src={item.logo ? item.logo : noImage} alt=""/>
                                     </div>
                                 </div>
                             })
                         }

                     </div>
                </NewsDetail>
        </>
    );
};

export default PartnersComp;
const NewsDetail = styled.div`
  .donor_content {
    h4 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 29px;
      color: #20386E;
      margin-top: 53px;
      margin-bottom: 34px;
    }
.image{
  margin-bottom: 42px;
  img{
    //width: 100%;
    height: 200px;
    
  }
}
    a{
      text-decoration: none;
    }
  }
  `
// const NewsDetail = styled.div`
//     max-width: 877px;
//
//     h4 {
//         font-family: "Montserrat";
//         font-style: normal;
//         font-weight: 600;
//         font-size: 24px;
//         line-height: 29px;
//         color: #20386E;
//         margin-top: 53px;
//         margin-bottom: 34px;
//     }
//
//
//     h5 {
//         font-style: normal;
//         font-weight: 600;
//         font-size: 24px;
//         line-height: 29px;
//         color: #1862AF;
//         margin-top: 22px;
//     }
//     div {
//         font-style: normal;
//         font-weight: 500;
//         font-size: 16px;
//         line-height: 20px;
//         color: #7E7E7E;
//     }
//
//     p {
//         margin-top: 38px;
//         margin-bottom: 20px;
//         font-style: normal;
//         font-weight: 500;
//         font-size: 16px;
//         line-height: 20px;
//         text-align: justify;
//         color: #000000;
//     }
//
//     button {
//
//         font-family: Montserrat;
//         font-style: normal;
//         font-weight: 600;
//         font-size: 18px;
//         line-height: 22px;
//         text-align: center;
//         color: #008DD2;
//         border: none;
//         background: none;
//         text-decoration: underline;
//     }
//
//     .contract {
//         margin-bottom: 67px;
//     }
//
//     .list-partners {
//         margin-bottom: 15px;
//     }
//
//     .name-organization {
//         display: flex;
//         justify-content: space-between;
//
//         h2 {
//             background: #008DD2;
//             border-radius: 10px;
//             font-family: "Montserrat";
//             padding: 18px 0;
//             font-style: normal;
//             font-weight: 600;
//             font-size: 20px;
//             line-height: 24px;
//             text-align: center;
//             color: #FFFFFF;
//         }
//     }
//
//     .name-organization :first-child {
//         flex: 0.8;
//         margin-right: 20px;
//     }
//     //
//     //.name-organization :nth-child(2) {
//     //    flex: 0.3;
//     //}
//
//     .contacts-organization {
//         h2 {
//             background: #008DD2;
//             border-radius: 10px;
//             font-family: "Montserrat";
//             font-style: normal;
//             font-weight: 600;
//             font-size: 20px;
//             line-height: 24px;
//             color: #FFFFFF;
//         }
//     }
//
//     .map-list h3 {
//         background: #FFFFFF;
//         box-shadow: 0px 6px 15px rgba(204, 204, 204, 0.4);
//         border-radius: 10px;
//         font-family: "Montserrat";
//         padding: 18px 0;
//         font-family: Montserrat;
//         font-style: normal;
//         font-weight: 500;
//         font-size: 16px;
//         line-height: 20px;
//         color: #1862AF;
//         text-align: center;
//         margin-top: 30px;
//         padding-left: 10px;
//         padding-right: 10px;
//         white-space: nowrap; /* Запрещаем перенос строк */
//         overflow: hidden; /* Обрезаем все, что не помещается в область */
//         text-overflow: ellipsis; /* Добавляем многоточие */
//     }
//
//     .list-contact {
//         max-width: 300px;
//     }
//
//     .white-color {
//         margin-top: 30px;
//     }
//
//     .white-color h2 {
//         background: #FFFFFF;
//         box-shadow: 0px 6px 15px rgba(204, 204, 204, 0.4);
//         border-radius: 10px;
//         font-family: "Montserrat", sans-serif;
//         font-style: normal;
//         font-weight: 500;
//         font-size: 16px;
//         line-height: 20px;
//         color: #1862AF;
//     }
//
//     .white-color :nth-child(2) {
//         white-space: nowrap; /* Запрещаем перенос строк */
//         overflow: hidden; /* Обрезаем все, что не помещается в область */
//         text-overflow: ellipsis; /* Добавляем многоточие */
//         cursor: pointer;
//     }
//
//     .white-color :nth-child(2):hover {
//         text-decoration: underline;
//     }
//
//     @media screen and (max-width: 425px) {
//
//         .name-organization :first-child {
//             font-family: "Montserrat";
//             font-style: normal;
//             font-weight: 500;
//             font-size: 12px;
//             line-height: 15px;
//             color: #FFFFFF;
//             padding: 5px 0px;
//             border-radius: 3px;
//         }
//
//         .name-organization :nth-child(2) {
//             font-family: "Montserrat";
//             font-style: normal;
//             font-weight: 500;
//             font-size: 12px;
//             line-height: 15px;
//             border-radius: 3px;
//             padding: 5px 0px;
//             color: #FFFFFF;
//         }
//
//         .white-color h2 {
//             color: #1862AF !important;
//             text-align: left;
//             margin-left: 15px;
//         }
//         .contract img {
//             max-width: 250px;
//         }
//     }
// `