import React from 'react'
import styled from "styled-components"
import {useTranslation} from "react-i18next";


const OfflineModal = () => {
	const {t} = useTranslation();
	return (
		<Wrapper>
			<div className="content">
				<h1>{t("Network Error")} !!!</h1>
				<p>{t("Please connect to the Internet")}</p>
			</div>
		</Wrapper>
	)
}

export default OfflineModal

const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  background: red;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  z-index: 1000;

  .content {
    padding: 50px;
    text-align: center;
    color:#fff	;
  }
`
