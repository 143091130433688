import React from 'react'
import styled from "styled-components"
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";


const VacancySidebar = ({el={}, vacanciesCompany}) => {
    const {t} = useTranslation();
    return (
        <Wrapper>
            <div className="home_news_info_content">
                <div className="mx-auto text-center">
                    <div className={'company-info'}>
                        <h5>{el?.organization}</h5>
                        <strong>{t("Адрес")}:</strong>
                        <p>
                            {el?.address}
                        </p>
                        <strong>{t("Контактное лицо")}:</strong>
                        <p>
                            {el?.contact_person}
                        </p>
                        <strong>{t("Телефон")}:</strong>
                        <p>
                            {el?.phone}
                        </p>
                        <strong>Web: <span>{el?.link}</span></strong>
                    </div>
                    <div className={'other-vacancies'}>
                        {
                           vacanciesCompany.length !== 0 && <h4>{t("Другие вакансии компании")}</h4>}
                        {
                          vacanciesCompany &&  vacanciesCompany.map((el, id) => {
                                return <Link to={'/vacancies/' +el.id}><p key={el.id}>{el?.title_ru_ru}</p></Link>

                            })
                        }

                    </div>

                </div>
            </div>
        </Wrapper>
    )
}

export default VacancySidebar

const Wrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
  width: 245px;
  margin: 0 auto;
  margin-bottom: 50px;
  .company-info {
    text-align: left;
    margin-bottom: 30px;

    h5 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 16.7915px;
      line-height: 20px;
      color: #008DD2;
    }

    strong {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }

    p {
      font-family: "Montserrat";
      font-style: normal;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }

    span {
      font-weight: normal;
      color: #008DD2;
    }
  }

  .other-vacancies {
    text-align: left;
    a {
      text-decoration: none;
    }
    h4 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 15.7915px;
      line-height: 20px;
      color: #1862AF;
      border-bottom: 2px solid #1862AF;
      padding-bottom: 10px;
      margin-bottom: 30px;
    }

    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 16.4912px;
      line-height: 21px;
      color: #000000;
      border-bottom: 2.09894px solid #B4B4B4;
      padding-bottom: 10px;
    }
  }
`
