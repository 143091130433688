import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import pathArrow from "../assets/images/path-arrow.png";
import { useTranslation } from "react-i18next";

const Path = ({
  title,
  gallery,
  news,
  aboutUs,
  aboutProject,
  contacts,
  vacancy,
  announc,
  reformface,
  staff,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Link to={"/"}>{t("MainPage")}</Link> <img src={pathArrow} alt="" />
      {news && (
        <>
          <Link to={"/news"}>{t("News")}</Link>
          <img src={pathArrow} alt="" />
        </>
      )}
      {gallery && (
        <>
          <Link to={"/photogallery"}>{t("PhotoGallery")}</Link>
          <img src={pathArrow} alt="" />
        </>
      )}
      {aboutUs && (
        <>
          <Link to={"/about-us"}>{t("About_Us")}</Link>
          <img src={pathArrow} alt="" />
        </>
      )}
      {contacts && (
        <>
          <Link to={"/contacts"}>{t("Contacts")}</Link>
          <img src={pathArrow} alt="" />
        </>
      )}
      {aboutProject && (
        <>
          <Link to={"/about-us"}>{t("About_Project")}</Link>
          <img src={pathArrow} alt="" />
        </>
      )}
      {announc && (
        <>
          <Link to={"/eventsfeed"}>{t("Announce")}</Link>
          <img src={pathArrow} alt="" />
        </>
      )}
      {vacancy && (
        <>
          <Link to={"/vacancies"}>{t("Vacancy")} </Link>
          <img src={pathArrow} alt="" />
        </>
      )}
      {reformface && (
        <>
          <Link to={"/reform-face"}>{t("ReformFace")}</Link>
          <img src={pathArrow} alt="" />
        </>
      )}
      {staff && (
        <>
          <Link to={"/staff-all"}>{t("Employees")}</Link>
          <img src={pathArrow} alt="" />
        </>
      )}
      <span>{title ? title : ""} </span>
    </Wrapper>
  );
};

export default Path;

const Wrapper = styled.div`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  //margin-top: -30px;
  margin-bottom: 30px;
  margin-left: 5px;

  a,
  span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #008dd2;
    text-decoration: none;
  }

  a {
    color: #000000;
    margin-right: 20px;
    //text-transform: capitalize;
  }

  img {
    margin-right: 15px;
  }
`;
