import React from 'react'
import styled from "styled-components"
import ResponsiveToShare from "../../constants/ResponsiveToShare";
import {useTranslation} from "react-i18next";
import Share from "../Share/Share";
import {Link} from "react-router-dom";

const VResponsiveSidebar = ({el={}, vacanciesCompany}) => {
    const {t} = useTranslation();
    return (
        <Wrapper>
            <div className="home_news_info_content">
                <div className="mx-auto text-center">
                    <div className={'company-info'}>
                        <strong>{t("Адрес")}:</strong>
                        <p>
                            {el?.address}
                        </p>
                        <strong>{t("Контактное лицо")}:</strong>
                        <p>
                            {el?.contact_person}
                        </p>
                        <strong>{t("Телефон")}:</strong>
                        <p>
                            {el?.phone}
                        </p>
                        <strong>Web: </strong>
                        <p><span>{el?.link}</span></p>
                    </div>
                    <div className={'other-vacancies'}>
                        {
                            vacanciesCompany.length !== 0 && <h4>{t("Другие вакансии компании")}</h4>}
                        {
                            vacanciesCompany &&  vacanciesCompany.map((el, id) => {
                                return <Link to={'/vacancies/' +el.id}><p key={el.id}>{el?.title_ru_ru}</p></Link>

                            })
                        }
                    </div>
              <Share/>
                </div>
            </div>
        </Wrapper>
    )
}

export default VResponsiveSidebar

const Wrapper = styled.div`
  display: none;
  @media screen and (max-width: 425px) {
    display: block;
  }
  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 20px;
  .company-info {
    text-align: left;
    margin-bottom: 15px;
    h5 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #008DD2;
    }
    strong {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: #000000;
    }
    p {
      font-family: "Montserrat";
      font-style: normal;
      font-size: 12px;
      line-height: 20px;
      color: #000000;
    }

    span {
      font-weight: normal;
      color: #008DD2;
    }
  }

  .other-vacancies {
    text-align: left;
    a {
      text-decoration: none;
    }
    h4 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: #1862AF;
      border-bottom: 1px solid #1862AF;
      margin-bottom: 20px;
    }

    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 21px;
      color: #000000;
      border-bottom: 1.09894px solid #B4B4B4;
      padding-bottom: 28px;
    }
  }
  .responsive-share {
    ul {
      justify-content: flex-start;
      padding-left: 0;
    }
  }
`
