import React, {useState} from 'react'
import styled from 'styled-components'
import leftArrow from '../../assets/images/modal-left-controller.png'
import rightArrow from '../../assets/images/modal-right-controller.png'

const ImagesCarousel = ({images = []}) => {
    const [main, setMain] = useState(images[0])
    const [mainIndex, setMainIndex] = useState(0)

    const onNext = () => {

        const newIndex = mainIndex + 1

        if (newIndex < images.length){
            setMain(images[newIndex])
            setMainIndex(newIndex)
        } else {
            setMain(images[0])
            setMainIndex(0)
        }
    }
    const onPrev = () => {
        const newIndex = mainIndex - 1
        if (newIndex >= 0){
            setMain(images[newIndex])
            setMainIndex(newIndex)
        } else {
            setMain(images[images.length - 1])
            setMainIndex(images.length - 1)
        }
    }
    const onSelectImage = (image, index) => {
        setMain(image)
        setMainIndex(index)
    }
    return <Wrapper>
        <ImageContainer>
            <button onClick={onPrev}><img src={leftArrow} alt=""/></button>
            <img src={main?.image ? main.image : 'https://image.shutterstock.com/image-vector/prohibition-sign-no-photography-600w-209270626.jpg'} className="main" alt=""/>
            <button onClick={onNext}><img src={rightArrow} alt=""/></button>
        </ImageContainer>

        <div className="gallery">
            {
                images.map((item, index) => {
                    return <img
                        src={item.image}
                        alt="img"
                        key={item.id}
                        onClick={() => onSelectImage(images[index], index)}
                        className = {`img ${item.image === main.image ? "active":''}`}
                    />
                })
            }
        </div>
    </Wrapper>
}

const Wrapper = styled.section`
    margin-top: 30px;
  .main {
    height: 400px;
      object-fit: cover;
      @media(max-width: 768px){
          height: 300px;
      }
  }
  img {
    width: 100%;
    display: block;
    border-radius: var(--radius);
    object-fit: cover;
  }
  .gallery {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    column-gap: 1rem;
    img {
      height: 100px;
      cursor: pointer;
    }
  }
  .img {
    width: 110px;
    width: 110px;
  }
  .active {
    box-shadow: 0px 0px 0px 2px var(--clr-primary-5);
    border: 5px solid #CADB36;
  }
  @media (max-width: 992px) {
    .main {
      height: 500px;
    }
    .gallery {
      img {
        height: 75px;
      }
    }
  }
  @media (max-width: 768px) {
      .main {
          height: 300px;
      }
    .gallery {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
      img {
        max-width: 80px;
        max-height:60px;
      }
    }
  }
`
const ImageContainer = styled.div`
    display: flex;
  margin-bottom: 56px;
  .main {
   max-width: 740px;
      object-fit: cover;
  }
  button {
    width: 60px;
    background: #20386E;
    border: none;
    
    img {
      width: 19px;
      margin: 0 auto;
        object-fit: cover;
    }
  }
  @media (max-width: 1024px) {
    max-width: 100%;
    margin-bottom: 30px;
    .main {
      max-width: 90%;
    }
  }
`
export default ImagesCarousel
