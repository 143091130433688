import React from 'react'
import styled from "styled-components"
import SectionTitles from "../../constants/SectionTitles";
import Path from "../../constants/Path";
import ContentSidebar from "../../constants/ContentSidebar";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ScrollToTop from "../../constants/ScrollToTop/ScrollToTop";


const RoadMap = () => {
	const {t} = useTranslation();
	return (
		<Wrapper>
			<ScrollToTop/>
			<div className="container">
				<div className="container-fluid">
					<SectionTitles>{t("SiteMap")}</SectionTitles>
					<Path title={"Карта сайта"}/>
					<div className="row">
						<div className="col-md-9">
							<div className="roadmap_content">

								<h1 className="mb-5"><Link to={"/"}>{t("Главная страница")}</Link></h1>
								<div className="roadmap_items">
									<div>
										<Link to={"/news"} className="item_text"> {t("News")} </Link>
										<Link to={"/eventsfeed"} className="item_text"> {t("Announce")}</Link>
										<Link to={"/reform-face"} className="item_text"> {t("ReformFace")} </Link>
										<Link to={"/photogallery"} className="item_text"> {t("PhotoGallery")} </Link>
										<Link to={"/videogallery"} className="item_text"> {t("VideoGallery")} </Link>
										<Link to={"/partners"} className="item_text"> {t("Partners")} </Link>
									</div>
									<div>
										<p className="item_text"> Календарь </p>
										<p className="item_text"> {t("Опрос")} </p>
										<p className="item_text"> Облачные теги </p>
									</div>
								</div>
								<div className="roadmap_items title_left mb-5 mt-5">
									<div>
										<h1 className="mb-5"><Link to={"/about-us"}>{t("About_Us")}</Link></h1>
										<Link to={"/staff-all"} className="item_text"> {t("Employees")} </Link>
										<Link to={"/donors"} className="item_text"> {t("Donors")}</Link>
										<Link to={"/partners"} className="item_text"> {t("Partners")}</Link>
										<Link to={"/vacancies"} className="item_text"> {t("Vacancy")}</Link>

									</div>
									<div>
										<h1 className="mb-5"><Link to={"/about-project"}>{t("About_Project")}</Link>
										</h1>
										<Link to={"/phase/1"} className="item_text"> {t("Phase1")} </Link>
										<Link to={"/phase/2"} className="item_text"> {t("Phase2")} </Link>
										<Link to={"/phase/3"} className="item_text"> {t("Phase3")} </Link>
										<Link to={"/reform-face"} className="item_text"> {t("ReformFace")}</Link>
									</div>
								</div>
								<div className="roadmap_items title_left mb-5 mt-5">
									<div>
										<h1 className="mb-5"><Link to={"/resources"}>{t("Resources")}</Link></h1>
										<Link to={"/resources"} className="item_text"> {t("Доклады")} </Link>
										<p className="item_text"> {t("Приказы")}</p>
										<p className="item_text"> {t("Постановления")}</p>
										<p className="item_text"> {t("Статьи")}</p>

									</div>
									<div>
										<h1 className="mb-5"><Link to={"/news"}>{t("News")}</Link></h1>
										<Link to={"/eventsfeed"} className="item_text"> {t("Announce")} </Link>


									</div>
								</div>
								<div className="main_page  mb-5 mt-5">
									<div>
										<h1 className="mb-5">
											<Link to={"/photogallery"}>{t("Gallery")}</Link>
										</h1>
										<Link to={"/photogallery"} className="item_text"> {t("PhotoGallery")} </Link>
										<Link to={"/videogallery"} className="item_text"> {t("VideoGallery")}</Link>


									</div>

								</div>
								<div className="main_page  mb-5 mt-5">
									<div>
										<h1 className="mb-5"><Link to={"/contacts"}>{t("Contacts")}</Link></h1>
										<Link to={"/roadmap"} className="item_text"> {t("SiteMap")} </Link>
										<Link to={"/map"} className="item_text"> {t("InteractiveMap")}</Link>
									</div>

								</div>
							</div>
						</div>
						<div className="col-md-3">
							<ContentSidebar recpublic={false}/>
						</div>

					</div>

				</div>

			</div>
		</Wrapper>
	)
}

export default RoadMap

const Wrapper = styled.div`
  padding: 10px 0 50px;

  .item_text {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #1862AF;
    padding-left: 20px;
    position: relative;
    display: block;
    text-decoration: none;
    padding-bottom: 0;
    margin-bottom: 0;
    @media (max-width: 768px) {
      font-size: 13px;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      width: 16px;
      height: 2px;
      background: #CADB36;
      top: 50%;

    }

  }

  h1 {
    color: #1862af;

    a {
      color: #008DD2;
      transition: all 0.3s ease;

      :hover {
        color: #20386E;
      }
    }
  }

  .roadmap_items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    //grid-gap: 20px;
    margin-left: 60px;
    @media (max-width: 768px) {
      margin-left: 10px;
    }

  }

  .title_left {
    h1 {
      margin-left: -60px;
      color: #1862AF;
      @media (max-width: 768px) {
        margin-left: 0px;
      }
    }
  }

  .main_page {
    div {
      margin-left: 60px;
      @media (max-width: 768px) {
        margin-left: 10px;
      }
    }

    h1 {
      color: #1862AF;
      margin-left: -60px;
      @media (max-width: 768px) {
        margin-left: 0px;
      }
    }
  }

  h1 {
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
`
