import React from "react";
import styled from "styled-components";
import ContentSidebar from "../constants/ContentSidebar";
import Path from "../constants/Path";
import SectionTitles from "../constants/SectionTitles";
import PartnersComp from "../components/Partners/PartnersComp";
import ScrollToTop from "../constants/ScrollToTop/ScrollToTop";

const Partners = (match) => {
  return (
    <Wrapper>
      <ScrollToTop />
      <div className="container ">
        <div className="container-fluid">
          <div className="home_news_content">
            <div className="row">
              <div className="col-md-9 col-sm-12 col-xs-12">
                <div className="grid_parent">
                  <PartnersComp />
                </div>
              </div>
              <div className="col-md-3 mt-5 col-sm-12">
                <ContentSidebar
                  recpublic={false}
                  calendar={true}
                  opros={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Partners;

const Wrapper = styled.div`
  .home_news_image {
    margin-bottom: -10px;
    width: 100%;
  }

  .home_news_title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
  .home_news_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
  .news_date {
    font-size: 12px;
    line-height: 15px;
    color: #949494;
  }
  .home_news_btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #1862af;
  }
  @media screen and (max-width: 1024px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
  }
  @media screen and (max-width: 768px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 100%;
    }
    .col-md-3 {
      width: 100%;
    }
    .home_news_info_content {
      margin-bottom: 50px;
    }
  }
  @media screen and (max-width: 425px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 100%;
    }
    .col-md-3 {
      width: 100%;
    }
  }
`;
