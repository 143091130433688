import React from 'react'
import styled from "styled-components"
import ImoLogo from "../../assets/images/footer/IMO-FOOTER-LOGO.png"
import {useTranslation} from "react-i18next";

const FooterLogo = () => {
    const {t} = useTranslation();
    return (
        <Wrapper>
            <div className="footer_logo_inner">
                <div className=" footer_logo_img first">
                    <a href={"#"}>
                    <img src={ImoLogo} alt=""/></a>
                </div>

                <div className="footer_logo_date">
                    <p>{t("Время обеда")}</p>
                    <div>12:00 - 13:00</div>
                    <p>{t("Время работы")}</p>
                    <div>09:00 - 18:00</div>

                </div>

            </div>
        </Wrapper>
    )
}

export default FooterLogo

const Wrapper = styled.footer`
position: relative;
.footer_logo_img.first{


img{
width: 100%;
z-index: 3;
@media(max-width: 900px){
width: 70%;
}
}
}
.footer_logo_date{
position: absolute;
bottom: 5px;
left:30px;  
@media(max-width: 1200px){
bottom:25px;
} 
@media(max-width: 900px){
position: absolute;
color:red;
z-index: 100;
//bottom:-100px;
} 
p{
font-weight: 600;
font-size: 15.4839px;
//line-height: 19px;
color: #20386E;
margin: 0;
}
div{
font-style: normal;
font-weight: normal;
font-size: 15.4839px;
//line-height: 19px;
color: #20386E;
margin: 0;
}
}

`
