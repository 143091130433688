import React, {useEffect, useState} from 'react';
import logoOrg from '../../assets/images/vacancies/logoOrg.png'
import styled from "styled-components";
import {ResponsiveVacanciesCard, VacanciesCard} from "./VacanciesCard"
import CustomButton from "../../constants/Button"
import {getVacancies} from "../../api/Requests";
import Loader from "../Loaders/Loader";
import FilterVacancies from "../../pages/Vacancies/FilterVacancies";

const VacanciesComp = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [vacancies, setVacancies] = useState([])
    const [filteredVacancies, setFilteredVacancies] = useState([])

    useEffect(() => {
        getVacancies(setIsLoading, setVacancies , setFilteredVacancies)
    }, [])
    return (
        <Wrapper>
<FilterVacancies setVacancies={setVacancies} setFilteredVacancies={setFilteredVacancies} vacancies={vacancies}/>
            {
                isLoading
                    ?
                    <Loader/>
                    :
                    filteredVacancies.map((el, id) => {
                    return <VacanciesCard key={id} el={el}/>
                })
            }
            {
                vacancies.map((el, id) => {
                    return <ResponsiveVacanciesCard key={id} el={el}/>
                })
            }
            {/*<CustomButton>Следующая страница</CustomButton>*/}
        </Wrapper>
    );
};

export default VacanciesComp;

const Wrapper = styled.div`
  padding-bottom: 97px;
  padding-top: 20px;

`