import React, {useRef, useState} from 'react'
import styled from "styled-components"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import {useHistory} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import {useTranslation} from "react-i18next";

const getFutureDates = (dates) => {
	return dates.filter(item => {
		// Filter out dates in the past or falsey values
		return item && (new Date(item)).getTime() > Date.now();
	})
}


const CalendarComponent = () => {

	const [value, onChange] = useState(new Date());
	const history = useHistory()
	const [getNewsDate, setGetNewsDate] = useState(localStorage.getItem("news_date"))
	const [getAnnounceDate, setGetAnnounceDate] = useState(localStorage.getItem("announce_dates"))
	const [announceStartDate, setAnnounceStartDate] = useState(localStorage.getItem("announce_start_dates"))
	const inputRef = useRef()
	const {t} = useTranslation()


	const uniqueDates = [...new Set(JSON.parse(getNewsDate)?.map((item) => item.date)), ...new Set(JSON.parse(getAnnounceDate)?.map((item) => item.date)), ...new Set(JSON.parse(announceStartDate)?.map((item) => moment(item.date).format('l').split(".").reverse().join("-")))]


	const handleSelectDate = (day) => {

		history.push(`/news/archive/${moment(day).format('l').split(".").reverse().join("-")}`)
	}


	return (
		<Wrapper>
			<div className="calendar">
				<h1 className="new_info_title calendar_title">{t("Календарь")} </h1>
				<div>
					<Calendar
						onClickDay={(day) => handleSelectDate(day)}
						onChange={onChange}
						value={value}
						// minDate={new Date((2021, 9, 10))}
						minDetail='year'
						inputRef={inputRef}
						tileClassName={({date, view}) => {

							let classes = ''
							const futureDates = getFutureDates(uniqueDates)


							if (uniqueDates?.find(x => x === moment(date).format('l').split(".").reverse().join("-"))) {
								classes = 'highlight'
							}

							if (futureDates?.find(x => x === moment(date).format('l').split(".").reverse().join("-"))) {
								classes = 'afterToday'
							}


							return classes
						}}


					/>
				</div>
			</div>
		</Wrapper>
	)
}

export default CalendarComponent


const Wrapper = styled.div`
  margin-bottom: 60px;
  margin-top: 28px;

  .tooltip_overlay {
    //position: absolute;
    //width: 100%;
    //height: 100%;
  }

  .new_info_title.calendar_title {
    margin-top: -30px;
    @media (max-width: 900px) {
      margin-top: 0;
    }
  }

  .calendar {
  }

  .react-calendar {
    min-width: 290px;
    border-radius: 10px;
    border: none;
    background: #FBFBFB;
    box-shadow: 0px 3.3px 6.6px rgba(204, 204, 204, 0.4);
  }

  .react-calendar__tile--active {
    //background: #ffc222;
    //color: white;
    ///* border-radius: 87%; */
    //width: 30px;
    //height: 30px;
    //border-radius: 50%;
    //display: flex;
    //justify-content: center;
    //padding-bottom: 25px;
    //padding-top: 9px;
    //align-content: center;
    //outline: 2px solid #fff;
    //outline-offset: -3px;
  }

  .react-calendar__month-view__weekdays__weekday {
    abbr {
      color: #CADB36;
    }
  }


  .react-calendar__navigation {
    background: #008DD2;

    border-radius: 10px 10px 0 0;
  }


  .react-calendar__navigation__label {
    color: #fff;
    text-transform: capitalize;
    white-space: nowrap;
    order: -1;
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
    display: none !important;
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none !important;
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
    color: #CADB36;
    font-size: 33px;
    transform: rotate(90deg);
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    color: #CADB36;
    font-size: 33px;
    transform: rotate(90deg);
  }

  .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
    position: relative;

  }

  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
    background: transparent;
    position: relative;

    abbr {
      color: -internal-light-dark(black, white);
      color: red;
      background: yellow;
      //border-radius: 50%;
      background: transparent;
      border: 1px solid #c9d7df;
      color: white;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 10px;
      padding-bottom: 25px;
      padding-top: 9px;
      padding: 9px 13px;
      font-size: 15px;
      align-content: center;
      outline: 2px solid #fff;
      outline-offset: -3px;
      color: #000000;
    }

  }

  .highlight {
    display: flex;
    justify-content: center;

    abbr {
      color: red;
      background: yellow;
      border-radius: 50%;
      background: #1862AF;
      color: white;
      width: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 9px 14px;
      font-size: 15px;
      align-content: center;
      outline: 2px solid #fff;
      outline-offset: -3px;
      border: 2px solid #1862AF;


    }


  }

  .afterToday {
    display: flex;
    justify-content: center;

    abbr {
      color: red;
      border-radius: 50%;
      background: #CADB36;
      color: white;
      width: 37px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 7px 11px;
      font-size: 15px;
      align-content: center;
      outline: 2px solid #fff;
      outline-offset: -3px;
      border: 2px solid #CADB36;
      animation: pulse-animation 2s infinite;

    }

    @keyframes pulse-animation {
      0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
        transform: scale(1);
      }
      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
        transform: scale(1.1);
      }
    }
  }
`
