import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TagCloud } from "react-tagcloud";
import { axiosInstance } from "../api/axiosInstance";
import { useHistory } from "react-router-dom";

const CloudTags = () => {
  const [tags, setTags] = useState([]);

  const getTags = async () => {
    try {
      const response = await axiosInstance.get("tag/");
      const transformData = response.data.map((item) => {
        const { count, title, id } = item;
        return {
          value: title,
          count,
          id,
        };
      });
      setTags(transformData);
    } catch (error) {
      console.log("error =>", error);
    }
  };

  const history = useHistory();

  const selectTag = (tags) => {
    history.push(`/tags/${tags.id}`);
  };

  useEffect(() => {
    getTags();
  }, []);

  const customRenderer = (tags, size, color) => {
    return (
      <span key={tags.value} style={{ color }} className={`tag-${size}`}>
        {tags.value}
      </span>
    );
  };
  return (
    <Wrapper>
      <TagCloud
        minSize={12}
        maxSize={25}
        tags={tags}
        onClick={(tag) => selectTag(tag)}
        // renderer={customRenderer}
      />
    </Wrapper>
  );
};

export default CloudTags;

const Wrapper = styled.div`
  margin-top: 20px;

  .tag-cloud-tag {
    cursor: pointer;
    transition: all 0.3s ease-in;
    font-weight: 600;
    font-size: 32.9588px;
    line-height: 40px;

    :hover {
      transform: scale(1.1) rotate(20deg);
      color: #000;
    }

    :nth-child(2n + 1) {
      animation: pulseForOddTags 2s infinite;
      transform: scale(1);
    }
    :nth-child(2n) {
      animation: pulseForEvenTags 2s infinite;
    }
  }
  @keyframes pulseForOddTags {
    0% {
      transform: scale(0.95);
      //box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
    50% {
      transform: scale(1);
      //box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      //box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  @keyframes pulseForEvenTags {
    0% {
      transform: scale(0.95);
      //box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
    50% {
      transform: scale(1);
      //box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      //box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;
