import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import ImagesCarousel from "../News/NewsCarousel";
import Card, {ResponsiveEventsCard} from "./Card";
import {useParams} from "react-router-dom";
import {getEventsFeedDetail} from "../../api/Requests";
import moment from "moment";
import Loader from "../Loaders/Loader";
import {ResponsiveCard} from "../Eventsfeed/EventCard";
import ResponsiveToShare from "../../constants/ResponsiveToShare";
import {useLanguageContext} from "../../Context/Context";
import {capitalizeStr} from "../../helpers/Capitalize";
import {useTranslation} from "react-i18next";
const EventNewsComp = ({announcements={}}) => {

    const { checkLanguage , language} = useLanguageContext()
    const {t}=useTranslation()
    // if (announcements === null) return <Loader/>
    return (
        <React.Fragment>
            <NewsDetail key={announcements?.id}>
                <Card el={announcements}/>
                <ResponsiveEventsCard el={announcements}/>
                <div className='date'>
                    <span>{moment(announcements?.created).locale('ru').format('LL')}</span>
                    {/*<span>{moment(announcements.startdate).format('LT')} - {announcements?.enddate ? moment(announcements?.enddate).locale(language).format('DD') : t('дата не указано')}-{announcements?.enddate && moment(announcements?.enddate).locale(language).format('MMMM').substring(0, 3)} / {announcements?.enddate && moment(announcements?.enddate).format("LT")}</span>*/}
                    <span>{announcements?.publisher}</span>
                </div>
                {announcements?.gallery != false && <ImagesCarousel images={announcements?.gallery}/>}
            </NewsDetail>
            <ResponsiveToShare/>
        </React.Fragment>
    );
};

export default EventNewsComp;

const NewsDetail = styled.div`
  max-width: 877px;
  img {
    width: 100%;
  }
  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #1862AF;
    margin-top: 22px;
  }
  > h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #1862AF;
    text-align: left;
  }
  .date {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #7E7E7E;
    margin-bottom: 23px;
    span {
      margin-right: 21px;
    }
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    color: #000000;
  }
`