import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import SectionTitles from "../../constants/SectionTitles";
import Carousel from "react-bootstrap/Carousel";
import {axiosInstance} from "../../api/axiosInstance";
import ToNormalDate from "../../helpers/ToNormalDate";
import {Link, useHistory} from "react-router-dom";
import moment from "moment";
import {useLanguageContext} from "../../Context/Context";
import {useTranslation} from "react-i18next";

const HomePhotoGallery = () => {
	const history = useHistory()
	const [photoGalleryData, setPhotoGalleryData] = useState([])
	const {checkLanguage} = useLanguageContext()
	const {t} = useTranslation();

	const getPhotos = async () => {
		try {
			const response = await axiosInstance.get("photo_gallery/")
			setPhotoGalleryData([...response.data])
		} catch (error) {
			console.log("error =>", error)
		}
	}
	useEffect(() => {
		getPhotos()
	}, [])
	return (
		<Wrapper>
			<div className="home_photogallery_responsive_header">
			</div>
			<div className="container">
				<div className="container-fluid">
					<Link to={"/photogallery"}>
						<SectionTitles>{t("PhotoGallery")} </SectionTitles>
					</Link>
					<div className="home_photogallery_content">
						<div className="row">
							<div className="col-md-6">
								<div className="grid_parent">
									{
										photoGalleryData.slice(1, 5).map((item) => {
											return <div className="home_photogallery_item" key={item.id}
											            onClick={() => history.push(`/photogallery/${item.id}`)}>
												<div>
													<img className="w-100" src={item.preview} alt=""/>
												</div>
												<div className="home_photogallery_text d-flex">
													<div className="text">
														{item.title.length > 46 ? checkLanguage(item).title.substring(0, 46) + "..." : checkLanguage(item).title}
													</div>
													<div className="date">
														{moment(item.created).locale('ru').format('L')}
													</div>
												</div>

											</div>
										})
									}

								</div>
							</div>
							<div className="col-md-6">
								{
									photoGalleryData.slice(0, 1).map((item) => {
										return <div className="gallery_main"
										            onClick={() => history.push(`/photogallery/${item.id}`)}
										            key={item.id}>
											<div className="gallery_main_img">
												<img src={item.preview} alt=""/>
											</div>
											<div className="gallery_main_content d-flex">
												<div className="text">
													{item.title.length > 46 ? checkLanguage(item).title.substring(0, 46) : checkLanguage(item).title}
												</div>
												<div className="date">
													{moment(item.created).locale('ru').format('L')}

												</div>
											</div>
										</div>
									})
								}

							</div>
						</div>

					</div>
					<div className="home_photogallery_responsive">
						<Carousel>
							{
								photoGalleryData.map((item, index) => {
									const date = new Date(item.created)
									return <Carousel.Item className="photogallery_responsive_item">
										<img
											className="d-block w-100 photogallery_responsive_image"
											src={item.preview}
											alt="First slide"
										/>
										<Carousel.Caption>
											{/*<h3>{item.text}</h3>*/}
											<div className="gallery_main_content d-flex align-items-scretch">
												<div className="text">
													{checkLanguage(item).title}
												</div>
												<div className="date">
													{moment(item.created).locale('ru').format('L')}
												</div>
											</div>
										</Carousel.Caption>
									</Carousel.Item>
								})
							}


						</Carousel>
					</div>
				</div>
			</div>
		</Wrapper>
	)
}

export default HomePhotoGallery

const Wrapper = styled.div`
  padding: 30px 0 40px;
  position: relative;

  .home_photogallery_responsive_header {
    display: none;
    @media (max-width: 768px) {
      position: absolute;
      z-index: -1;
      top: 55px;
      display: block;
      width: 100%;
      /* Color 3 */
      background: #1862AF;
      border: 1px solid #1862AF;
      box-sizing: border-box;
      height: 50px;
      transform: translateY(30px);
    }
  }

  .home_photogallery_responsive {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }


    .carousel-indicators {
      top: -20px;
      left: -50px;
      right: auto;
    }

    .carousel-indicators [data-bs-target] {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      //border: 1px solid #fff;
    }
  }

  .home_photogallery_content {
    text-align: center;
    margin: 0 auto;
    @media (max-width: 768px) {
      display: none;
    }

  }


  .home_photogallery_item {
    position: relative;
    margin-bottom: 60px;
    cursor: pointer;

    img {
      //border-radius: 0px 0px 15px 0px;
      border-radius: 70px 0px 35px 0px;
      height: 200px;
      object-fit: cover;
    }

    &:nth-child(even) {
      //margin-top: 80px;
      //margin-bottom: -80px;
      transform: translateY(60px);
      @media (max-width: 900px) {
        transform: translateY(0);
      }
    }
  }

  .gallery_main {
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
      border-radius: 105px 0px 50px 0px;
      height: 400px;
      @media (max-width: 768px) {
        height: 300px;
      }
    }
  }

  .gallery_main_content {
    width: 90%;
    //padding: 10px;
    position: absolute;
    bottom: -28px;
    right: 0px;
    color: #fff;
    font-weight: 500;
    font-size: 11.1636px;
    line-height: 14px;
    height: 70px;
    @media (max-width: 900px) {
    
      height: auto;
    }

    @media (max-width: 768px) {
      right: -80px;
      bottom: -30px;
      z-index: 200;
      height: auto;
    }

    .text {
      background: #20386E;
      padding: 5px;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      //hello
      width: 100%;
      color: #FFFFFF;
      //padding-bottom: 10px;
      @media (max-width: 768px) {
        font-size: 11px;
        line-height: 15px;
      }
    }

    .date {
      font-weight: 500;
      font-size: 18px;
      padding-top: 5px;
      padding-right: 9px;
      padding-left: 5px;
      color: #FFFFFF;
      background: #CADB36;
      border-radius: 0px 0px 70px 0px;
      //border-radius: 0px 0px 45px 0px;
      //padding: 15px;
      line-height: 30px;
      word-break: break-all;
      width: 130px;
      padding-bottom: 12px;
      //padding-right: 10px;
      @media (max-width: 768px) {
        font-size: 11px;
      }
    }
  }

  .home_photogallery_text {
    //width: 100%;
    height: 55px;
    //padding: 10px;
    position: absolute;
    bottom: -25px;
    right: 0px;
    color: #fff;
    font-weight: 500;
    font-size: 11.1636px;
    line-height: 14px;
    color: #FFFFFF;

    .text {
      background: #20386E;
      padding: 5px;
      width:160px;
    }

    .date {
      background: #CADB36;
      border-radius: 0px 0px 45px 0px;
      padding: 10px;
    }

  }

  .photogallery_responsive_item {
    .photogallery_responsive_image {
      height: 250px;
      object-fit: cover;
      width: 100%;
      border-radius: 0px 0px 65px 0px;
    }
  }
`
//