import React from 'react'
import styled from "styled-components"
import error from "../../assets/images/404/404.svg"
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const NotFound = () => {
    const {t} = useTranslation();
    return (
        <Wrapper>
            <div className="container">
                <div className="container-fluid">
                    <div>
                        <img src={error} alt=""/>
                    </div>
                    <h2>{t("В данный момент страница не доступна")}</h2>
                    <p>{t("Вы можете перейти на следующие страницу")}:</p>
                    <Link to={'/'}>
                        <h5>{t("Главная страница")}</h5>
                    </Link>
                </div>
            </div>
        </Wrapper>
    )
}

export default NotFound

const Wrapper = styled.div`
  padding-bottom: 60px;
  margin-top: 124px;

  .container-fluid {
    display: block;
  }

  div {
    display: flex;
    justify-content: center;
    margin-bottom: 21px;
    text-align: center;
    
    h2 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 42px;
      color: #000000;
      margin-bottom: 57px;
    }
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #000000;
      margin-bottom: 18px;
    }
    a {
      text-decoration: none;
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #1862AF;
    }
  }

`
