import React, {useState} from 'react'
import styled from "styled-components"
import {css} from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
import {Spinner} from "react-bootstrap";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loader = () => {

	let [color, setColor] = useState("#fff");

	return (
		<Wrapper>
			<div className = "content">
				<Spinner animation="border" role="status" variant="primary">
					<span className="visually-hidden">Loading...</span>
				</Spinner>
			</div>
		</Wrapper>
	)
}

export default Loader

const Wrapper = styled.div`
  position: fixed;
  //background: rgba(0,0,0,0.5);
  //inset: 0;
  z-index: 1000;
  height: 100vh;
  width: 100%;
  left: 50%;
  top:50%;
  transform: translate(-50%, -50%);
  .content{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

`
