import React, {useEffect, useState} from 'react';
import {EventCard, ResponsiveCard} from "./EventCard";
import eventCardImg from '../../assets/images/events/eventimg1.png'
import styled from "styled-components";
import Pagination from "../../constants/Pagination";
import {getEventsFeed} from "../../api/Requests";
import Loader from "../Loaders/Loader";
import {useQuery} from 'react-query'
import {axiosInstance} from "../../api/axiosInstance";
import ReactPaginate from "react-paginate";
import {useLanguageContext} from "../../Context/Context";

const EventComponent = () => {

	const [announces, setAnnounces] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const count = announces?.count
	const { setLastAnnounces} = useLanguageContext()

	const getAnnounces = async (page) => {
		setIsLoading(true)
		const perPage = 6;
		const page_size = page * perPage - perPage;

		try {
			const response = await axiosInstance.get(`announcement/?page=${page}&page_size=${perPage}`)
			setAnnounces({...response.data})
			setLastAnnounces(response.data.results)
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
		}
	}


	useEffect(() => {
		getAnnounces(1)
	},[])

	return (
		<Wrapper>
			{
				isLoading === true
					?
					<Loader/>
					:
					announces?.results?.map((el, id) => {
						return <EventCard key={id} el={el}/>
					})
			}
			{
				isLoading === true
					?
					<Loader/>
					:
					announces?.results?.map((el, id) => {
						return <ResponsiveCard key={id} el={el}/>
					})
			}
			{
				announces?.results?.length >9 &&	<div className = "pagination">
					<ReactPaginate
						pageCount={Math.ceil(count/6 )}
						pageRangeDisplayed={2}
						// nextLabel={"Следующая страница"}
						marginPagesDisplayed={1}
						onPageChange={(data) => getAnnounces(data.selected + 1)}
						activeClassName={"active_paginate"}/>
				</div>
			}


		</Wrapper>
	);
};

export default EventComponent;

const Wrapper = styled.div`
  padding-bottom: 97px;
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  grid-gap: 40px;

  .pagination{
    margin-top: 40px;
    margin-bottom: 60px;
    ul {
      position: relative;
      display: flex;
      //width: 100%;
      justify-content: space-around;
      max-width: 90vh;
      margin: 0 auto;
      padding-left: 0px;
      align-items: center;
      li {

        //width: 20px;
        a{
          text-decoration: none;
          margin: 0 20px;
          list-style: none;
          padding: 10px;
          //border: 2px solid red;
          cursor: pointer;
        }
      }
    }

    .active_paginate{
      background: rgba(202, 219, 54, 0.8);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      //padding-right: 0;
    }
    .previous{
      display: none;
    }
    .next{
      position: absolute;
      bottom: -50px;
      display: none;

    }
    //.next.disabled{
    //  a{
    //    background: #ccc;
    //  }
    //}
  }
  
  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  @media screen and (max-width: 345px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`