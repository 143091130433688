import React from 'react';
import eventCardImg from '../../assets/images/events/eventimg1.png'
import styled from "styled-components";
import clockIcon from '../../assets/images/events/clockIcon.png'
import calendarIcon from '../../assets/images/events/calendarIcon.png'
import homeIcon from '../../assets/images/events/homeIcon.png'
import moment from "moment";
import 'moment/locale/ru'
import Loader from "../Loaders/Loader";
import {capitalizeStr} from "../../helpers/Capitalize";
import {useLanguageContext} from "../../Context/Context";

const Card = ({el={}}) => {
    const { checkLanguage} = useLanguageContext()
    // if (el === null) return <Loader/>
    return (
        <CardWrapper>
            <div className='img'>
                <img
                    src={el?.medium ? el.medium : 'https://image.shutterstock.com/image-vector/prohibition-sign-no-photography-600w-209270626.jpg'}
                    alt=""/>
            </div>
            <div className='text-info'>
                {/*<h4 dangerouslySetInnerHTML={{__html:checkLanguage(el)?.text}}></h4>*/}
                <div>
                    <p>
                        <div className="icon">
                            <img src={calendarIcon} alt="icon"/>
                        </div>
                        {moment(el?.startdate).locale('ru').format('LL')}
                    </p>
                    <p>
                        <div className="icon">
                            <img src={clockIcon} alt="icon"/>
                        </div>
                        {moment(el?.startdate).format('HH:mm')}-{moment(el?.enddate).format('HH:mm')}
                    </p>
                    <p>
                        <div className="icon">
                            <img src={homeIcon} alt="icon"/>
                        </div>
                        {checkLanguage(el)?.place}
                    </p>
                </div>
            </div>
        </CardWrapper>
    );
};
export const ResponsiveEventsCard = ({el={}}) => {
    const { checkLanguage} = useLanguageContext()
    // if (el === null) return <Loader/>
    return (
        <>
            <h4 dangerouslySetInnerHTML={{__html:(checkLanguage(el)?.title)}}></h4>
            <p dangerouslySetInnerHTML={{__html:(checkLanguage(el)?.text)}}></p>
            <ResponsiveCardWrapper>
                <div className='img'>
                    <img
                        src={el?.medium ? el?.medium : 'https://image.shutterstock.com/image-vector/prohibition-sign-no-photography-600w-209270626.jpg'}
                        alt=""/>
                </div>
                <div className='text-info'>

                    <div>
                        <p>
                            <div className="icon">
                                <img src={calendarIcon} alt="icon"/>
                            </div>
                            {moment(el?.startdate).locale('ru').format('LL')}
                        </p>
                        <p>
                            <div className="icon">
                                <img src={clockIcon} alt="icon"/>
                            </div>
                            {moment(el?.startdate).format('HH:mm')}-{moment(el?.enddate).format('HH:mm')}
                        </p>
                        <p>
                            <div className="icon">
                                <img src={homeIcon} alt="icon"/>
                            </div>
                            {checkLanguage(el)?.place}
                        </p>
                    </div>
                </div>
            </ResponsiveCardWrapper>
        </>
    );
};

export default Card;

const CardWrapper = styled.div`
  display: flex;
  margin-bottom: 51px;
cursor: pointer;
  .img img {
    border-radius: 7.64398px;
    //width: 293px;
      height: 250px;
      width: 100%;
      max-width: 230px;
      min-width: 230px;
      min-width: 230px;
      object-fit: cover;
  }

  .text-info {
    padding-left: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #1862AF;

    h4 {
      margin-bottom: 45px;
    }

    div p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: justify;
      color: #1A1A1A;
      display: flex;

      .icon {
        padding-right: 12px;
          cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 425px) {
    display: none;
  }
`

const ResponsiveCardWrapper = styled.div`
  display: none;
  margin-bottom: 51px;

  .img img {
    border-radius: 7.64398px;
    width: 143px;
  }

  .text-info {
    padding-left: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #1862AF;
    display: flex;
    align-items: center;
    h4 {
      margin-bottom: 45px;
    }

    div p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: justify;
      color: #1A1A1A;
      display: flex;
      align-items: center;

      .icon {
        padding-right: 12px;
      }
    }
  }

  @media screen and (max-width: 425px) {
    display: flex;
  }
`