import React from 'react'
import styled from "styled-components"
import DocViewer, {DocViewerRenderers} from "react-doc-viewer"
import FileViewer from "react-file-viewer"
import CloseModal from "../../assets/images/modal-close-controller.png"
const getFileTypeByUrl = (url) => {
	const arr = url?.split('.')
	return arr[arr?.length - 1]
}

const ResourcesModal = ({handleModal ,file}) => {

	return (
		<Wrapper>
			<div className="modal_content">
				<div className="close" onClick={handleModal}>
					<img src={CloseModal} alt=""/>
				</div>
				<FileViewer
					fileType={getFileTypeByUrl(file)}
					filePath={file}
					errorComponent={<div>fd</div>}
					onError={() => {}}
				/>
				<DocViewer pluginRenderers={DocViewerRenderers} documents={[{ uri: file}]} />;
			</div>
		</Wrapper>
	)
}

export default ResourcesModal

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  inset: 0;
  //top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  //height: 100vh;
  //height: 600px;
  z-index: 1000;
  padding-top: -10px;

  .modal_content {
    width: 60%;
    background: #fff;
    //padding: 76px;
    height:500px;
    position: relative;
    @media (max-width: 768px) {
      //height: 100%;
      width: 90%;
      margin: 0 auto;
      max-height: 500px;
    }

    //iframe {
    //  height: 100%;
    //  width: 700px;
    //  @media(max-width: 768px){
    //    width: 100%;
    //  }
    //}
  }

  .close {
    font-size: 40px;
    color: red;
    position: absolute;
    top: -7px;
    right: 25px;
    cursor: pointer;
    font-weight: 900;
    z-index: 1001;
  }
`
