import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import moment from "moment";
import {useLanguageContext} from "../../Context/Context";
import {capitalizeStr} from "../../helpers/Capitalize";
import {useTranslation} from "react-i18next";


export const VacancyComp = ({isLoading, setIsLoading, vacancy, setVacancy}) => {
    const { checkLanguage} = useLanguageContext()
    const {t} = useTranslation();
    return (
        <React.Fragment>
            <NewsDetail>
                <h2>{(checkLanguage(vacancy).title)}</h2>
                <VacancyCard el={vacancy}/>
                <ResponsiveVacancyCard el={vacancy}/>
                <p dangerouslySetInnerHTML={{__html: (checkLanguage(vacancy)?.description)}}></p>
            </NewsDetail>
        </React.Fragment>
    );
};
export const VacancyCard = ({el={}}) => {
    const {t} = useTranslation();
    const { checkLanguage} = useLanguageContext()
    return (
        <CardWrapper>
            <div className='img'>
                { el?.logo && <img
                    src={el?.logo && el?.logo}
                    alt=""/>}
            </div>
            <div className='text-info'>
                <div>
                    <p>
                        {t("Дата публикации:")}
                        <span>{moment(el?.created).format('DD.MM.YY')}</span>
                    </p>
                    <p>
                        {t("Крайний срок")}:
                        <span>{moment(el?.deadline).format('DD.MM.YY')}</span>
                    </p>
                    <p>
                        {t("Оклад")}: <small>{el?.salary}</small>
                    </p>
                    <p>
                        {t("Опыт работы")}: <small>{el?.experience}</small>
                    </p>
                    <p>
                        {t("Место")}: <small>{el?.address}</small>
                    </p>
                    <p>
                        {t("Занятость")}: <small>{el?.title}</small>
                    </p>
                    <p>
                        {t("График работы")}:
                        <span>
                            {el?.schedule}
                        </span>
                    </p>
                </div>
            </div>
        </CardWrapper>
    );
};

export const ResponsiveVacancyCard = ({el}) => {
    const {t} = useTranslation();
    return <ResponsiveCardWrapper>
        <div>
            <h3>{t("Компания")}</h3>
            <p>{el?.organization} </p>
        </div>
        <div>
            <h3>{t("Дата публикации")} </h3>
            <p>{moment(el?.created).locale('ru').format('LL')}</p>
        </div>
        <div>
            <h3>{t("Опыт работы")}</h3>
            <p>{el?.experience}т</p>
        </div>
        <div>
            <h3>{t("Оклад")}</h3>
            <p>{el?.salary} </p>
        </div>
        <div>
            <h3>{t("Крайний срок")}</h3>
            <p>{moment(el?.deadline).locale('ru').format('LL')}</p>
        </div>
        <div>
            <h3>{t("Место")}</h3>
            <p>{el?.address}</p>
        </div>
    </ResponsiveCardWrapper>
}

const NewsDetail = styled.div`
  max-width: 877px;
  h2 {
    line-height: 1.5;
    margin-bottom: 20px;
    font-size: 28px;
    width: 97%;
  }
  img {
    width: 100%;
  }
  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #1862AF;
    margin-top: 22px;
  }
  .date {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #7E7E7E;
    margin-bottom: 23px;
    span {
      margin-right: 21px;
    }
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    color: #000000;
  }
  @media screen and (max-width: 425px) {
    > h2 {
      font-size: 16px;
      line-height: 20px;
      text-align: left;
    }
  }
`

const CardWrapper = styled.div`
  display: flex;
  margin-bottom: 51px;
  
  .img {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  .img img {
    border-radius: 7.64398px;
    width: 200px;
  }

  .text-info {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 17.3894px;
    line-height: 21px;
    
    color: #1862AF;
    
    h4 {
      margin-bottom: 45px;
    }

    div p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      text-align: justify;
      color: #1A1A1A;
      display: flex;
      margin-bottom: 15px;
        font-weight: 800;
        small {
            margin-left: 10px;
            font-weight: 500;
        }
      span {
        margin-left: 5px;
        color:  #008DD2;
        max-width: 370px;
          font-weight: 500;
          font-size: 14px;
      }
    }
  }
  @media screen and (max-width: 425px) {
    display: none;
  }
`
const ResponsiveCardWrapper = styled.div`
  display: none;

  @media screen and (max-width: 425px) {
    display: block;
    margin-bottom: 17px;
    div {
      text-align: left;
      background: rgba(196, 196, 196, 0.2);
      margin-bottom: 3px;
      padding: 5px;
      padding-left: 20px;
      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1862AF;
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #0F0F0F;
        margin-bottom: 0;
        width: 80%;
      }
    }
  }
`