import React from 'react';
import styled from "styled-components";
import recPubImg from "../assets/images/home-background-layer.svg"
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useLanguageContext} from "../Context/Context";




const RecentPublic = ({recentPublicData , route}) => {
	const {t} = useTranslation();
	const history = useHistory()
const {checkLanguage} = useLanguageContext()
	return (
		<Wrapper>
			<h3>{t("Последние публикации")}</h3>
			{
				recentPublicData?.slice(0, 3)?.map((el, id) => {
					return <RecPublic key={id}>
						<div className="image">
							<img src={el.small} alt=""/>
						</div>
						<h4>{checkLanguage(el).title}</h4>
						<p>{checkLanguage(el).description}</p>
						<p  className = "link" onClick={()=>history.push(`/${route}/${el.id}`)}>{t("Читать далее")}...</p>
						<hr/>
					</RecPublic>
				})
			}
			{/*<h2>См. все новости</h2>*/}
		</Wrapper>

	);
};

export default RecentPublic;

const Wrapper = styled.div`
  width: 240px;
  padding-bottom: 59px;

  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #1862AF;
    margin-bottom: -10px;
    white-space: nowrap;
  }

  h2 {
    ont-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    justify-content: flex-end;
    color: #959EA8;
  }
`
const RecPublic = styled.div`
  //height: 360px;
  //border-bottom: 3px solid #1862AF;
  color: #1862AF;
  margin: 25px 0px;

  .image {
    height: 150px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  h4 {
    padding: 14px 0px;
    width: 279px;
    display: flex;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }

  p {
    padding-bottom: 23px;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
.link{
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1862AF;
  cursor: pointer;
}
  div {
    display: flex;
    justify-content: flex-end;
  }

  hr {
    height: 3px;
    margin-bottom: 30px;
    color: #1862AF;
    opacity: 1;
  }
`