import React from 'react'
import styled from "styled-components"
import {
	FacebookShareButton,
	FacebookIcon,
	WhatsappShareButton,
	WhatsappIcon,
	TelegramShareButton,
	TelegramIcon
} from "react-share";
import shareIcon from "../../assets/images/contact/share-icon.png"
import {useTranslation} from "react-i18next";

const Share = () => {
	const {t} = useTranslation()

	const facebook = "https://www.gov.kg/ky"
	return (
		<Wrapper>
			<div className = "content">

			<div className="share_icon">
				<h4>{t("Поделиться")} : </h4>
			</div>
			<div className="icons">
				<FacebookShareButton
					url={facebook}
				>
					<FacebookIcon size={40} round={true}/>
				</FacebookShareButton>
			</div>
			<div className="icons">
				<WhatsappShareButton
					url={facebook}
				>
					<WhatsappIcon size={40} round={true}/>
				</WhatsappShareButton>
			</div>
			<div className="icons">
				<TelegramShareButton
					url={facebook}
				>
					<TelegramIcon size={40} round={true}/>
				</TelegramShareButton>
			</div>
			</div>

		</Wrapper>
	)
}

export default Share

const Wrapper = styled.div`
  display: inline-block;
  .content{
  display: flex;
  align-items: center;
  justify-content: center;

  .icons {
    margin: 20px 10px;
  }

  .share_icon {
    h4 {
      font-weight: 500;
      font-size: 17.7062px;
      line-height: 22px;
      /* identical to box height */

      text-align: justify;
      margin-top: 5px;
      color: #000000;
    }
  }
  }

`
