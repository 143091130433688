import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import ReactPlayer from "react-player";
import {Modal} from "react-bootstrap";

export const ModalForVideo = (props) => {
    return (
            <VideoModal
                {...props}
                // size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={'modal-bg'}
            >

                <Modal.Body>
                    <ReactPlayer playbackRate controls playing={false}
                                         playsinline
                                         url={props.el} style={{objectFit:"contain"}}/>
                </Modal.Body>
            </VideoModal>

    );
}

const VideoModal = styled(Modal)`
    .modal-bg{
        background: transparent;
    }
.modal-body{
    padding: 0;
    background: transparent;
    @media(max-width: 768px){
        div:first-child{
            width: 100% !important;
        }
    }
}
`