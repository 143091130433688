import React, {useEffect, useState} from "react";
import styled from "styled-components";
import DownloadIcon from "../../assets/images/majesticons_folder-download.png";
import axios from "axios";
import PdfIcon from "../../assets/images/pdf-type-icon.svg";
import WordTypeIcon from "../../assets/images/word-type-icon.svg";
import {useLanguageContext} from "../../Context/Context";
import {capitalizeStr} from "../../helpers/Capitalize";
import {useTranslation} from "react-i18next";

const SingleResourceItem = ({handleModal, item}) => {
	const {title, id, files} = item
	const [amount, setAmount] = useState("");
	const {checkLanguage} = useLanguageContext()
	const {t} = useTranslation()


	const fileType = files.split(".").pop();

	const PdfType = fileType === "pdf"
	const DocxType = fileType === "docx"


	return (
		<Wrapper>
			<div className="resources_item">
				<div className="doc_type_img">
					{PdfType && <img src={PdfIcon} alt=""/>}
					{DocxType && (
						<img
							src={
								WordTypeIcon
							}
							alt=""
						/>
					)}
					{!PdfType && !DocxType && (
						<img
							src="https://thumbs.dreamstime.com/b/document-icon-vector-stack-paper-sheets-illustration-131104983.jpg"
							alt=""
						/>
					)}
				</div>
				<a
					className="description"
					// target="_blank"
					onClick={(e) => handleModal(e, id, files)}
					// href={files}
				>
					{(checkLanguage(item).title)}
				</a>
				<div className="size">
					<h5>{t("Размер")}</h5>
					*
				</div>
				<div className="download_button">
					<img src={DownloadIcon} alt=""/>
					<a target="_blank" href={files}>
						{t("Скачать")}
					</a>
				</div>
			</div>
		</Wrapper>
	);
};

export default SingleResourceItem;

const Wrapper = styled.div`
  .download_button {
    a {
      cursor: pointer;
      text-decoration: none;
    }
  }

  .description {
    text-decoration: none;
  }

  .size {
    text-align: center;
  }
`;
