import React, {useEffect, useRef, useState} from 'react'
import styled from "styled-components"
import ContentSidebar from "../../constants/ContentSidebar";
import SectionTitles from "../../constants/SectionTitles";
import Path from "../../constants/Path";
import NewsDetailComponent from "../../components/News/NewsDetailComponent";
import ToShare from "../../constants/ToShare";
import {useParams} from "react-router-dom";
import {axiosInstance} from "../../api/axiosInstance";
import Loader from "../../components/Loaders/Loader";
import ScrollToTop from "../../constants/ScrollToTop/ScrollToTop";
import {useLanguageContext} from "../../Context/Context";
import {capitalizeStr} from "../../helpers/Capitalize";
import {useTranslation} from "react-i18next";
import Share from "../../components/Share/Share";
import NotFound from "../../components/NotFound/NotFound";

const NewDetails = () => {
	const [newsDetails, setNewsDetails] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const {id} = useParams()
	const [lastPublication, setLastPublication] = useState([])
	const {checkLanguage} = useLanguageContext()
	const {t} = useTranslation();



	const getNewsDetails = async () => {
		setIsLoading(true)
		try {
			const response = await axiosInstance.get(`news_api/${id}/`)
			setNewsDetails(response.data)
			setIsLoading(false)
		} catch (error) {
			// console.log("error =>", error)
			setIsLoading(false)
		}
	}

	const getNewsForLastPublications = async (page) => {
		setIsLoading(true)
		try {
			const response = await axiosInstance.get(`news_api/`)
			setLastPublication(response.data.results)
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
		}
	}




	useEffect(() => {
		getNewsDetails()
		getNewsForLastPublications()
	}, [id])


	if(!newsDetails && !isLoading){
		return <NotFound loading={newsDetails?.isLoading}/>
	}

	return (
		<Wrapper>
			<ScrollToTop/>
			<div className="container ">
				<div className="container-fluid">
					<SectionTitles>{t("News")}</SectionTitles>
					<Path title={(checkLanguage(newsDetails).title)} news={true}></Path>
					<div className="home_news_content">
						<div className="row">

							<div className="col-md-9 col-sm-12 col-xs-12">
								<NewsDetailComponent newsDetails={newsDetails} isLoading={isLoading}/>
							</div>
							<div className="col-md-3 col-sm-12">
								<ContentSidebar calendar={true} opros={false} recentPublicData={lastPublication}
								                route={"news"}/>
							</div>
						</div>
						<Share/>
					</div>
				</div>
			</div>
			{
				isLoading && <Loader/>
			}
		</Wrapper>
	)
}

export default NewDetails

const Wrapper = styled.div`
  position: relative;

  .home_news_image {
    margin-bottom: -10px;
    width: 100%;
  }

  .home_news_title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  .home_news_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }

  .news_date {
    font-size: 12px;
    line-height: 15px;
    color: #949494;
  }

  .home_news_btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #1862AF;
  }
  

`
