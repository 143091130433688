import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import {Link, useHistory, useParams} from "react-router-dom";
import {useLanguageContext} from "../../Context/Context";
import {axiosInstance} from "../../api/axiosInstance";
import ScrollToTop from "../../constants/ScrollToTop/ScrollToTop";
import SectionTitles from "../../constants/SectionTitles";
import Path from "../../constants/Path";
import moment from "moment";
import {capitalizeStr} from "../../helpers/Capitalize";
import greenArrow from "../../assets/images/vacancies/greenArrow.png";
import ContentSidebar from "../../constants/ContentSidebar";
import Loader from "../../components/Loaders/Loader";
import {searchByDate} from "../../api/Requests";
import {useTranslation} from "react-i18next";
import NotFound from "../../components/NotFound/NotFound";


const SearchByDateResults = () => {
	const [searchResults, setSearchResults] = useState(null)
	const [isLoading, setisLoading] = useState(false)
	const {t} = useTranslation();
	const history = useHistory()
	const {date} = useParams()
	const [errors , setErrors] = useState(null)
	const {checkLanguage} = useLanguageContext()

	const getSearchResults = async () => {
		setisLoading(true)
		const {success,data} = await searchByDate(date)
		if(success){
			setSearchResults(data?.data)
			setisLoading(false)
		}
		else{
			setErrors(data)
		}
	}


	useEffect(() => {
		getSearchResults()
	}, [date])


	if (searchResults?.announcement?.length === 0 && searchResults?.news.length === 0 && searchResults?.photo?.length === 0 && isLoading) {
		return <NotFound />
	}


	return (
		<Wrapper>
			<ScrollToTop/>
			<div className="container">
				<div className="container-fluid">
					<SectionTitles>{t("Результаты поиска")}</SectionTitles>
					<Path title={"Теги"}/>
					<div className="row">
						<div className="col-md-9 col-sm-12 col-xs-12">
							<div className="section_content">
								<div className="result_items">
									{/*get news*/}
									{
										searchResults?.news?.map((item, index) => {
											return <div className="result_item" key={item.id}
											            onClick={() => history.push(`/news/${item.id}`)}>
												<div className="result_image">

													<img
														src={item?.mainphoto || "https://png.pngtree.com/png-clipart/20190921/original/pngtree-no-photo-taking-photo-illustration-png-image_4698291.jpg"}
														alt=""/>
												</div>
												<div className="result_info">
													<div>
														<div className="date">
															{/*{item.created}*/}
															{moment(item.created).locale('ru').format('LL')}
														</div>
														<h3>{capitalizeStr(checkLanguage(item).title)}</h3>

														<p>{capitalizeStr(checkLanguage(item).description)}</p>
													</div>
													<div className="arrow">
														<img src={greenArrow} alt=""/>
													</div>
												</div>
											</div>
										})
									}
									{/* end of get news*/}

									{/*get from announcement*/}
									{
										searchResults?.announcement?.map((item) => {
											return <div className="result_item" key={item.id}
											            onClick={() => history.push(`/eventsfeed/${item.id}`)}>
												<div className="result_image">
													<img
														src={item?.mainphoto || "https://png.pngtree.com/png-clipart/20190921/original/pngtree-no-photo-taking-photo-illustration-png-image_4698291.jpg"}
														alt=""/>
												</div>
												<div className="result_info">
													<div>
														<div className="date">
															{/*{item.created}*/}
															{moment(item.startdate).locale('ru').format('LL')}
														</div>
														<h3>{capitalizeStr(checkLanguage(item).title)}</h3>
														<p>{capitalizeStr(checkLanguage(item).description)}</p>
													</div>
													<div className="arrow">
														<img src={greenArrow} alt=""/>
													</div>
												</div>
											</div>
										})
									}
									{/*end of get from announcement*/}

									{/*get from resources*/}
									{
										searchResults?.photo?.map((item, index) => {
											return <div className="result_item" key={item.id}
											            onClick={() => history.push(`news/${item.id}`)}>
												<div className="result_image">
													<img
														src={item?.gallery[0]?.image || "https://media.wired.com/photos/598e35fb99d76447c4eb1f28/master/pass/phonepicutres-TA.jpg"}
														alt=""/>
												</div>
												<div className="result_info">
													<div>
														<div className="date">
															{/*{item.created}*/}
															{moment(item.created).locale('ru').format('LL')}
														</div>
														<h3>{capitalizeStr(checkLanguage(item).title)}</h3>
														<p>{item?.publisher}</p>
													</div>
													<div className="arrow">
														<img src={greenArrow} alt=""/>
													</div>
												</div>
											</div>
										})
									}
									{/*end of get from resources*/}

								</div>
							</div>
						</div>
						<div className="col-md-3 col-sm-12 col-xs-12">
							<ContentSidebar recpublic={false}/>
						</div>
					</div>
				</div>
			</div>
			{
				isLoading && <Loader/>
			}
		</Wrapper>
	)
}


export default SearchByDateResults

const Wrapper = styled.div`
  .result_items {
    .result_item {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;


      .result_info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 6px 15px rgba(204, 204, 204, 0.4);
        border-radius: 10px;
        padding: 10px;
        padding-right: 40px;
        padding-top: 25px;
        margin: 10px 0;
        position: relative;
        width: 100%;


        .date {
          position: absolute;
          top: 0;
          left: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #FFFFFF;
          background: #008DD2;
          border-radius: 10px 0px 0px 0px;
          padding: 3px;
        }

        h3 {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1862AF;
          text-align: center;
        }

        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #949494;
          margin-right: 10px;
        }
      }

      .result_image {
        margin-right: 10px;
        width: 70px;
        height: 50px;
        @media (max-width: 500px) {
          display: none;
        }

        img {
          width: 100%;
          //height: 100px;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
`


