import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "../pages/Home/Home";
import NewsPage from "../pages/NewsFeed/NewsPage";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header";
import Eventsfeed from "../pages/Eventsfeed/Eventsfeed";
import NewsDetails from "../pages/NewsDetails/NewsDetails";
import AboutUs from "../pages/AboutUs/AboutUs";
import AboutProject from "../pages/AboutProject/AboutProject";
import PhotoGalleryDetail from "../pages/PhotoGalleryDetail/PhotoGalleryDetail";
import PhotoGallery from "../pages/PhotoGallery/PhotoGallery";
import Contacts from "../pages/Contacts/Contacts";
import Eventnews from "../pages/Eventnews/Eventnews";
import ReformFacePage from "../pages/ReformFacePage/ReformFacePage";
import ReformFaceDetailPage from "../pages/ReformFaceDetailPage/ReformFaceDetailPage";
import VideoGallery from "../pages/VideoGallery/VideoGallery";
import Vacancies from "../pages/Vacancies/Vacancies";
import StaffAll from "../pages/StaffAll/StaffAll";
import Partners from "../pages/Partners";
import Phaze from "../pages/Phaze/Phaze";
import InteractiveMap from "../pages/InteractiveMap/InteractiveMap";
import NotFound from "../pages/404/NotFound";
import RoadMap from "../pages/RoadMap/RoadMap";
import Vacancy from "../pages/Vacancies/Vacancy";
import TagsSearchResult from "../pages/TagsSearchResult/TagsSearchResult";
import Resources from "../pages/Resources/Resources";
import OfflineModal from "../components/OfflineModal/OfflineModal";
import Donors from "../pages/Donors/Donors";
import SearchByDateResults from "../pages/SearchByDateResults/SearchByDateResults";
import SearchByText from "../pages/SearchByText/SearchByText";
import SingleStaff from "../pages/SingleStaff/SingleStaffAll";

const Routes = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [inputOpen, setInputOpen] = useState(false);

  const setOnline = () => {
    setIsOnline(true);
  };

  const setOffline = () => {
    setIsOnline(false);
  };

  const openInput = () => {
    setInputOpen(!inputOpen);
  };
  const onCloseInput = () => {
    setInputOpen(false);
  };

  useEffect(() => {
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);

    return () => {
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    };
  }, []);

  return (
    <BrowserRouter>
      {!isOnline && <OfflineModal />}
      <Header isOnline={isOnline} openInput={openInput} inputOpen={inputOpen} />
      <Switch>
        <Route exact path={"/photogallery"} component={PhotoGallery} />
        <Route
          exact
          path={"/photogallery/:id"}
          component={PhotoGalleryDetail}
        />
        <Route exact path={"/about-project"} component={AboutProject} />
        <Route exact path={"/about-us"} component={AboutUs} />
        <Route exact path={"/reform-face"} component={ReformFacePage} />
        <Route
          exact
          path={"/reform-face/:id"}
          component={ReformFaceDetailPage}
        />
        <Route exact path={"/contacts"} component={Contacts} /> {/*success*/}
        <Route exact path={"/map"} component={InteractiveMap} />
        {/*50%*/}
        <Route exact path={"/phase/:id"} component={Phaze} />
        <Route exact path={"/resources"} component={Resources} />
        <Route exact path={"/partners"} component={Partners} /> {/*success*/}
        <Route exact path={"/videogallery"} component={VideoGallery} />{" "}
        {/*some bags*/}
        <Route exact path={"/vacancies/:id"} component={Vacancy} />{" "}
        {/*success*/}
        <Route exact path={"/vacancies"} component={Vacancies} /> {/*success*/}
        <Route exact path={"/staff-all"} component={StaffAll} /> {/*90%*/}
        <Route exact path={"/staff-all/:id"} component={SingleStaff} />{" "}
        {/*90%*/}
        <Route exact path={"/eventsfeed/:id"} component={Eventnews} />{" "}
        {/*success*/}
        <Route exact path={"/eventsfeed"} component={Eventsfeed} />{" "}
        {/*success*/}
        <Route exact path={"/news/:id"} component={NewsDetails} /> {/*success*/}
        <Route
          exact
          path={"/news/archive/:date"}
          component={SearchByDateResults}
        />{" "}
        {/*success*/}
        <Route exact path={"/news"} component={NewsPage} /> {/*success*/}
        <Route exact path={"/roadmap"} component={RoadMap} /> {/*success*/}
        <Route exact path={"/donors"} component={Donors} /> {/*success*/}
        <Route exact path={"/tags/:tag"} component={TagsSearchResult} />{" "}
        {/*success*/}
        <Route exact path={"/search/:text"} component={SearchByText} />{" "}
        {/*success*/}
        <Route exact path={"/"}>
          <Home openInput={onCloseInput} />
        </Route>
        <Route path={"*"} component={NotFound} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
