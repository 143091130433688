import React from 'react'
import styled from "styled-components"
import Triangle from "../../assets/images/footer/triangle.png"
import ResponsiveMerLogo from "../../assets/images/footer/responsive_mer_logo.png"
import ResponsiveImoLogo from "../../assets/images/footer/responsive_imo_logo.png"
import {responsiveFooterData} from "./ResponsiveFooterData";
import FooterResponsiveLinks from "./FooterResponsiveLinks";
import Instagram from "../../assets/images/footer/footer_instagram.png"
import Youtube from "../../assets/images/footer/footer_youtube.png"
import Facebook from "../../assets/images/footer/footer_facebook.png"
import {useTranslation} from "react-i18next";

const ResponsiveFooter = () => {
	const {t} = useTranslation();

	return (
		<Wrapper>
			<footer className="footer_responsive">
				<div className="footer_content">
					<div className="footer_header">
						<div className="triangle">
							<img className="main_triangle_img" src={Triangle} alt=""/>
							<div className="triangle_images">
								<img src={ResponsiveImoLogo} alt=""/>
								<img className="mer_image" src={ResponsiveMerLogo} alt=""/>
							</div>
						</div>
						<div className="footer_header_info">
							<h3>{t("Наш адрес")}:</h3>
							<p>ул. Московская, 184</p>
							<p>тел: +996 553 707 437</p>
							<p>email: merproject_gmail.com</p>
							<div className="d-flex footer_date">
								<h4>{t("Время работы")}:
									<span>09:00 - 18:00</span></h4>
								<h4>Обед: <span>12:00 - 13:00</span></h4>
							</div>
						</div>
					</div>
					<div className="footer_content_inner">
						{
							responsiveFooterData.map((item) => {
								return <FooterResponsiveLinks key={item.id} {...item}/>
							})
						}

					</div>
					<div className="footer_footer">
						<div className="footer_footer_inner">
							<h4>{t("Мы в соц сетях")}</h4>
							<ul className="footer_social_icons">
								<li>
									<a href="#">
										<img src={Instagram} alt=""/>
									</a>
								</li>
								<li>
									<a href="#">
										<img src={Facebook} alt=""/>
									</a>
								</li>
								<li>
									<a href="#">
										<img src={Youtube} alt=""/>
									</a>
								</li>
								<li>
									<a href="#">
										<img src={Youtube} alt=""/>
									</a>
								</li>
							</ul>
							<div className="footer_footer_text">
								{t("2021, Проект реализован при поддержке Щвейцарского Агентсва развития и сотрудничеству")}
							</div>
						</div>
					</div>
				</div>
			</footer>
		</Wrapper>
	)
}

export default ResponsiveFooter

const Wrapper = styled.div`
  .footer_responsive {
    background: #20386E;

    .footer_header {
      display: flex;
      //align-items: flex-start;
      .triangle {
        position: relative;
        width: 230px;

        .main_triangle_img {
          width: 100%;
        }

        @media (max-width: 500px) {
          width: 180px;
        }

        .triangle_images {
          position: absolute;
          top: -10px;
          margin-left: 7px;


          .mer_image {
            height: 45px;
          }

          img {
            //width: 100%;
            height: 65px;
            display: block;
            margin: 10px 0;

          }
        }
      }


    }
  }

  .footer_header_info {
    //text-align: center;
    padding-top: 20px;
    margin-left: 21px;
    margin: 0 auto;

    h3 {
      font-weight: bold;
      font-size: 15px;
      line-height: 12px;
      color: #FFFFFF;
      //text-align: center;
      margin-bottom: 25px;
      margin-left: 20px;
      @media (max-width: 400px) {
        font-size: 12px;
      }
    }

    p {
      font-weight: 500;
      font-size: 13px;
      line-height: 12px;
      margin-bottom: 15px;

      color: #FFFFFF;
      @media (max-width: 400px) {
        font-size: 10px;
      }
    }

    .footer_date {
      margin-left: -130px;
      //margin-top: 22px;
      padding-bottom: 40px;
      @media (max-width: 400px) {
        margin-left: -120px;
      }

      h4 {
        font-weight: bold;
        font-size: 15px;
        line-height: 12px;
        color: #FFFFFF;
        text-align: center;
        white-space: nowrap;
        @media (max-width: 400px) {
          font-size: 12px;
        }

        span {
          font-weight: 500;
          font-size: 13px;
          line-height: 12px;
          color: #FFFFFF;
          margin: 0 10px;
          @media (max-width: 400px) {
            font-size: 10px;
          }
        }
      }
    }
  }

  .footer_content_inner {
    padding: 10px 20px;

    .footer_accordtion {
      border-top: 3px solid #FFFFFF;;
      padding: 10px 0;
      text-align: center;
      color: #fff;

      h4 {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        cursor: pointer;
        align-items: flex-start;

        span {
          font-size: 17px;
          margin-left: 10px;
          //background: ;
          color: #CADB36;
          //transform: rotate(-90deg);

        }
      }
    }

    .footer_accordtion_item {
      transition: all 1s ease-in-out;

      a {
        transition: all 0.3s ease;
        text-decoration: none;
        font-weight: 500;
        font-size: 12px;
        line-height: 25px;
        color: #FFFFFF;
        display: block;
        text-align: center;
      }
    }
  }

  .footer_footer_inner {
    padding-bottom: 20px;

    h4 {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #FFFFFF;
      text-align: center;
    }

    .footer_social_icons {
      display: flex;
      text-align: center;
      justify-content: center;

      li {
        margin: 0 10px;
      }
    }
  }

  .footer_footer_text {
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    color: #FFFFFF;
    width: 250px;
    margin: 0 auto;
  }
`
