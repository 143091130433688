import React from 'react'
import styled from "styled-components"
 import RecentPublic from "./RecentPublic";
import CalendarComponent from "./Calendar";
import Opros from "./Opros";
import CloudTags from "./CloudTags";


const ContentSidebar = ({recpublic = true,recentPublicData,route, calendar =true, opros = true ,cloud_tag=true}) => {

    return (
        <Wrapper>
            <div className="home_news_info_content">
                {recpublic && <RecentPublic recentPublicData={recentPublicData} route={route}/>}
                {calendar && <CalendarComponent/>}
                {opros && <Opros/>}

                    {
                        cloud_tag && <CloudTags/>
                    }
            </div>
        </Wrapper>
    )
}

export default ContentSidebar

const Wrapper = styled.div`
    @media(max-width: 768px){
        display: none;
    }
width: 245px;
margin: 0 auto;
//background: red;
.new_info_title{
margin-bottom: 30px;
font-weight: 600;
font-size: 24px;
line-height: 29px;
color: #1862AF;
}


`
