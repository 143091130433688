import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import SectionTitles from "../../constants/SectionTitles";
import Path from "../../constants/Path";
import ContentSidebar from "../../constants/ContentSidebar";
import RightArrow from "../../assets/images/next_right_arrow.png"
import {Link, useHistory} from "react-router-dom";
import {axiosInstance} from "../../api/axiosInstance";
import {Button} from "react-bootstrap";
import moment from "moment";
import {useLanguageContext} from "../../Context/Context"
import {capitalizeStr} from "../../helpers/Capitalize";
import {useTranslation} from "react-i18next";
import ScrollToTop from "../../constants/ScrollToTop/ScrollToTop";

const PhotoGallery = () => {
	const [photoData, setPhotoData] = useState([])
	const [downloadMorePagination, setDownloadMorePagination] = useState(6)
	const sliceOfPagination = photoData.slice(0, downloadMorePagination)
	const history = useHistory()
	const { checkLanguage} = useLanguageContext()
	const {t} = useTranslation();

	const getPhotoGallery = async () => {
		try {
			const response = await axiosInstance.get("photo_gallery/")
			setPhotoData([...response.data])
		} catch (error) {
			console.log("error =>", error)
		}
	}

	const LoadMore = () => {
		setDownloadMorePagination(downloadMorePagination + downloadMorePagination)
	}

	useEffect(() => {
		getPhotoGallery()
	}, [])

	return (
		<Wrapper>
			<ScrollToTop/>
			<div className="container">
				<div className="container-fluid">
					<Link to={"/news"}>
						<SectionTitles>{t("PhotoGallery")}</SectionTitles>
					</Link>
					<Path title={"Фотогалерея"}/>
					<div className="photogallery_content">
						<div className="row">
							<div className="col-md-9 col-sm-12 mx-auto">
								<div className="grid_parent">
									{
										sliceOfPagination.map((item) => {
											return <div className="photogallery_item" key={item.id}
											            onClick={() => history.push(`/photogallery/${item.id}`)}>
												<div className="photogallery_item_image">
													<img src={item.preview} alt=""/>
												</div>
												<div className="card pr-3  ">
													<div className="photogallery_text pt-2">
														<p className="m-3">{capitalizeStr(checkLanguage(item).title)}</p>
													</div>
													<div className="photogallery_footer d-flex justify-content-between">
														<div className="date">
															{moment(item.created).locale('ru').format('LL')}
														</div>
														<Link to={`/photogallery/${item.id}`}>
															<div className="right_arrow">
																<img src={RightArrow} alt=""/>
															</div>
														</Link>

													</div>
												</div>

											</div>
										})
									}
								</div>

								{photoData.length >= 6 && <div className="mx-auto text-center mt-5">
									<Button onClick={LoadMore} variant="primary">{t("DownloadMore")}</Button>
								</div>}

							</div>
							<div className="col-md-3 col-sm-12">
								<ContentSidebar recpublic={false}/>
							</div>
						</div>

					</div>
				</div>
			</div>
		</Wrapper>
	)
}

export default PhotoGallery

const Wrapper = styled.div`
  padding: 70px 0;

  .photogallery_content {
    //padding-top: 60px;
  }

  .photogallery_item_image {
    margin-bottom: -20px;

    img {
      width: 100%;
      border-radius: 10px 10px 15px 15px;
      position: relative;
      z-index: 10;
    }
  }

  .photogallery_footer {
    .date {
      padding: 7px 10px;
      background: #CADB36;
      border-radius: 0px 0px 0px 10px;
      font-size: 16px;
      line-height: 19px;
      color: #FFFFFF;
    }
  }

  .right_arrow {
    width: 30px;
    margin-right: 20px;

    img {
      width: 100%;
    }
  }

  .download_button {
    text-align: center;
    margin-top: 70px;

    button {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #F7F7F7;
      background: #1862AF;
      border-radius: 10px;
      border: none;
      outline: none;
      padding: 14px 120px;

    }

  }

  .photogallery_item {
    max-width: 260px;
    margin: 0 auto;
    cursor: pointer;
  }

  .photogallery_text {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    color: #000000;
  }

`
