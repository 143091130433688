import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import SectionTitles from "../../constants/SectionTitles";
import {getVideos} from "../../api/Requests";
import moment from "moment";
import eye from "../../assets/images/videogallery/eye.png";
import {ModalForVideo} from "../../constants/ModalForVideo";
import ReactPlayer from "react-player";
import {useLanguageContext} from "../../Context/Context";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const HomeVideoGallery = () => {
	const {checkLanguage} = useLanguageContext()
	const [modalShow, setModalShow] = useState(false);
	const [videos, setVideos] = useState([])
	const [isLoading, setIsLoading] = useState("")
	const [linkForModal , setLinkForModal] = useState("")
	const {t} = useTranslation();

	useEffect(() => {
		getVideos(setIsLoading, setVideos)

	}, [])

	const openModal = (url ) => {
		setModalShow(true)
		setLinkForModal(url)
	}


		return (
		<Wrapper>
			<div className="container">
				<div className="container-fluid">
					<Link to={"/videogallery"}>
						<SectionTitles >{t("VideoGallery")} </SectionTitles>
					</Link>
					<div className="video_gallery_content">
						<div className="video_gallery_main_img">
							<div className="grid_parent">
								{
									videos.slice(0,3).map((item) => {
										return <div className={'videogallery'}>
											<div className="player">
												<div className="player_overlay" onClick={()=>openModal(item.link)}>
												</div>
												<ReactPlayer playbackRate controls playing={false} muted={false}
												             playsinline={false} width='100%'
												             height='100%' url={item.link}/>
											</div>
											<div
												className="photogallery_footer d-flex justify-content-between align-items-center">
												<div className="date">
													{moment(item.created).format('DD.MM.YY')}
												</div>

											</div>
											<div className="videogallery_text">
												<p className="m-3">{checkLanguage(item).title}</p>
											</div>
											<ModalForVideo
												el={linkForModal}
												show={modalShow}
												onHide={() => setModalShow(false)}
											/>
										</div>
									})
								}

							</div>


						</div>


					</div>

				</div>
			</div>
		</Wrapper>
	)
}

export default HomeVideoGallery

const Wrapper = styled.div`
  padding: 47px 0 70px;
  //min-height: 100vh;
  @media (max-width: 600px) {
    min-height: 80vh;
  }

  .video_gallery_content {
    display: grid;
    //grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    @media (max-width: 900px) {
      grid-template-columns: 1fr ;
    }
    @media (max-width: 600px) {
      //display: none;
    }
  }
  
  .videogallery{
  position: relative;
}
  .player {
    position: relative;
    width: 100%;
    height: 200px;
    .player_overlay {
      position: absolute;
      z-index: 100;
      width: 100%;
      width: 100%;
      inset: 0;
      //background: rgba(255,255,255,0.5);
      cursor: pointer;
    }
  }
    
  .photogallery_footer {
    .date {
      padding: 7px 10px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #505050;
    }
  }

  .right_arrow {
    display: flex;
    margin-right: 20px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #767676;
  }


  .videogallery_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    @media(max-width: 450px){
      p {
        position: absolute;
        bottom: 20px;
        background: #1862AF;
        border-radius: 0px 7.2549px 7.2549px 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 14.1569px;
        line-height: 12px;
        color: #FCFCFC;
        max-width: 190px;
        padding: 10px;

      }
    }
  }

  @media screen and (max-width: 1024px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
  }
  @media screen and (max-width: 768px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 100%;
    }

    .col-md-3 {
      width: 100%;
    }

    .home_news_info_content {
      margin-bottom: 50px;
    }
  }

  @media screen and (max-width: 425px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 100%;
    }

    .col-md-3 {
      width: 100%;
    }

    p {
      position: absolute;
      bottom: 2%;
      background: #1862AF;
      border-radius: 0px 7.2549px 7.2549px 0px;
      font-style: normal;
      font-weight: 600;
      font-size: 10.1569px;
      line-height: 12px;
      color: #FCFCFC;
      max-width: 190px;
      padding: 3px 0;

    }
  }
\` 





`
