import React from 'react';
import styled from "styled-components";
import ImagesCarousel from "./NewsCarousel";
import moment from "moment";
import {useLanguageContext} from "../../Context/Context";
import {capitalizeStr} from "../../helpers/Capitalize";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Loader from "../Loaders/Loader";
import NotFound from "../NotFound/NotFound";


const NewsDetailComponent = ({newsDetails} ) => {
	const { checkLanguage} = useLanguageContext()
	const {t} = useTranslation();




	return (
		<>
			<NewsDetail key={newsDetails?.id}>
				<img className = "detail_news_photo" src={newsDetails?.mainphoto} alt=""/>
				<h5>{capitalizeStr(checkLanguage(newsDetails)?.title)}</h5>
				<div className="author_date">
					<span className="date">{moment(newsDetails?.created).locale('ru').format('LL')}</span>
					<span className="author">{newsDetails?.publisher}</span>
				</div>
				<p dangerouslySetInnerHTML={{
					__html: (checkLanguage(newsDetails)?.text)
				}}></p>
				{
					newsDetails?.gallery &&	newsDetails?.gallery?.length >= 1 && <ImagesCarousel images={newsDetails?.gallery}/>
				}

			</NewsDetail>
		</>
	);
};

export default NewsDetailComponent;

const NewsDetail = styled.div`
  max-width: 877px;
.detail_news_photo{
  //max-width: 877px;
  width: 100%;
  height: 400px;
  object-fit: cover;
  background-position: center;
  @media(max-width: 768px){
    height: 250px;
  }
}


  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #1862AF;
    margin-top: 22px;
    margin-bottom: 20px;
  }

  div {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #7E7E7E;
    margin-bottom: 21px;

    span {
      margin-right: 21px;
    }
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;

    color: #000000;
  }

  .author_date {
    display: flex;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #888888;
    }

    .date {

    }

    .author {

    }
  }
`

