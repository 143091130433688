import React from 'react'
import styled from "styled-components"
import {Link} from "react-router-dom";
import Loader from "../Loaders/Loader";
import {useTranslation} from "react-i18next";


const NotFound = () => {

	const {t} = useTranslation();




	return <NotFoundWrapper>
		<h1>{t("NotFound")}</h1>
		<div className="image">
			<img src="https://i.pinimg.com/originals/49/e5/8d/49e58d5922019b8ec4642a2e2b9291c2.png" alt=""/>
		</div>
		<div className="button">
			<Link to={"/"}>{t("BackТoHome")}</Link>
		</div>
	</NotFoundWrapper>
}

export default NotFound

const NotFoundWrapper = styled.div`
  //max-height: 100vh;
  padding: 100px 0;
  @media (max-width: 768px) {
    padding-top: 50px;
  }

  h1 {
    text-align: center;
  }

  .button {
    text-align: center;
    margin-top: 80px;

    a {
      padding: 10px 20px;
      background: #008DD2;
      color: #fff;
      font-size: 22px;
      text-decoration: none;
      border-radius: 10px;
    }

  }
  .image{
    width: 50%;
    margin: 0 auto;
    margin-top: 20px;
    height: 300px;
    img{
      width: 100%;
      object-fit: cover;
      
    }

`
