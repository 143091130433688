import React, { useEffect, useState } from "react";
import styled from "styled-components";
import homeLayer from "../../assets/images/home-background-layer.svg";
import HomeNews from "../NewsFeed/HomeNews";
import HomeSlider from "../../components/HomeSlider/HomeSlider";
import ReformFaces from "../../components/ReformFaces/ReformFace";
import HomePhotoGallery from "../../components/HomePhotoGallery/HomePhotoGallery";
import HomeVideoGallery from "../../components/HomeVideoGallery/HomeVideoGallery";
import HomePartners from "../../components/HomePartners/HomePartNers";
import HomeEventNews from "../../components/HomeEventNews/HomeEventNews";
import { axiosInstance } from "../../api/axiosInstance";
import { getEventsFeed } from "../../api/Requests";
import Loader from "../../components/Loaders/Loader";

const Home = ({ openInput }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [news, setNews] = useState([]);
  const [sliderList, setSliderList] = useState([]);
  const [announceSliderList, setAnnounceSliderList] = useState([]);
  const [announcement, setAnnouncement] = useState([]);

  const allSliderList = [...announceSliderList, ...sliderList];

  const getNews = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get("news_api/");
      const data = response.data.results;
      setNews(data);
      setSliderList(data?.filter((item) => item.slider));

      const created = data.map((item) => {
        const { created } = item;
        return { date: created };
      });
      localStorage.setItem("news_date", JSON.stringify(created));
      setIsLoading(false);
    } catch (error) {
      console.log("error =>", error);
      setIsLoading(false);
    }
  };

  const fetchAnnounces = async () => {
    setIsLoading(true);
    try {
      const response = await getEventsFeed();
      const transformData = response.data.results.map((item) => {
        const {
          id,
          title,
          text,
          created,
          mainphoto,
          small,
          description,
          place,
          startdate,
          enddate,
        } = item;
        return {
          id,
          title,
          text,
          created: new Date(created),
          small,
          mainphoto,
          description,
          place,
          startdate,
          enddate,
        };
      });

      setAnnouncement(transformData);
      setAnnounceSliderList(
        response?.data?.results?.filter((item) => item.slider)
      );

      const created = response.data.results.map((item) => {
        const { created, startdate } = item;
        return { date: created };
      });
      const startDate = response.data.results.map((item) => {
        const { startdate } = item;
        return { date: startdate };
      });

      localStorage.setItem("announce_dates", JSON.stringify(created));
      localStorage.setItem("announce_start_dates", JSON.stringify(startDate));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error =>", error);
    }
  };

  useEffect(() => {
    getNews();
    fetchAnnounces();
  }, []);

  return (
    <Wrapper>
      <div className="home_image_layer">
        <img src={homeLayer} alt="" />
      </div>
      <div className="home_image_layer2">
        <img src={homeLayer} alt="" />
      </div>

      <HomeSlider
        allSliderList={allSliderList}
        isLoading={isLoading}
        openInput={openInput}
      />
      <HomeNews news={news} isLoading={isLoading} />
      <HomeEventNews announcement={announcement} isLoading={isLoading} />
      <ReformFaces isLoading={isLoading} />
      <HomePhotoGallery />
      <HomeVideoGallery />
      <HomePartners />
      {isLoading && <Loader />}
    </Wrapper>
  );
};

export default Home;

const Wrapper = styled.div`
  position: relative;
  //min-height: 200vh;
  //  overflow-x: hidden;
  .home_image_layer {
    position: absolute;
    top: 8%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    transform: translate(-25%);

    img {
      width: 150%;
      background-position: center;
      background-size: cover;
    }

    @media (max-width: 600px) {
      display: none;
    }
    @media (min-width: 1500px) {
      display: none;
    }
  }

  .home_image_layer2 {
    position: absolute;
    //top:00vh;
    bottom: 25%;
    left: 0;
    right: 0;
    z-index: -1;
    transform: translate(-25%);

    img {
      width: 150%;
      background-position: center;
      background-size: cover;
    }

    @media (max-width: 900px) {
      display: none;
    }
    @media (min-width: 1500px) {
      display: none;
    }
  }
`;
