import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SectionTitles from "../../constants/SectionTitles";
import ContentSidebar from "../../constants/ContentSidebar";
import Path from "../../constants/Path";
import StaffFilter from "../../components/StaffAll/StaffFilter";
import {
  ResponsiveStaffCard,
  StaffCard,
} from "../../components/StaffAll/StaffCard";
import CustomButton from "../../constants/Button";
import { getCrew } from "../../api/Requests";
import Loader from "../../components/Loaders/Loader";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../../constants/ScrollToTop/ScrollToTop";

function HomeNews() {
  const [isLoading, setLoading] = useState();
  const [crew, setCrew] = useState([]);
  const [filteredItem, setFilteredItem] = useState([]);
  const [downloadMorePagination, setDownloadMorePagination] = useState(6);
  const { t } = useTranslation();

  // const LoadMore = () => {
  // 	setDownloadMorePagination(downloadMorePagination + downloadMorePagination)
  // }

  const fetchCrew = async () => {
    const { success, data } = await getCrew();
    console.log("crew async", data);
    if (success) {
      setCrew(data);
      setFilteredItem(data);
    }
  };

  useEffect(() => {
    fetchCrew();
  }, []);

  return (
    <Wrapper>
      <ScrollToTop />
      <div className="container ">
        <div className="container-fluid">
          <SectionTitles>{t("Employees")}</SectionTitles>
          <Path aboutUs={true} title={t("Employees")} />

          <StaffFilter crew={crew} setFilteredItem={setFilteredItem} />
          <div className="home_news_content">
            <div className="row">
              <div className="col-md-9 col-sm-12 col-xs-12">
                <div className="grid_parent">
                  {filteredItem?.map((item, index) => {
                    return <StaffCard el={item} key={item.id} />;
                  })}
                  {filteredItem?.map((item, index) => {
                    return <ResponsiveStaffCard el={item} key={item.id} />;
                  })}
                </div>
                {/* {
									crew?.length > 6 && <div className={'button'} onClick={LoadMore}>
										<CustomButton>{t("DownloadMore")}</CustomButton>
									</div>
								} */}
              </div>
              <div className="col-md-3 col-sm-12">
                <ContentSidebar recpublic={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </Wrapper>
  );
}

export default HomeNews;

const Wrapper = styled.div`
  .home_news_image {
    margin-bottom: -10px;
    width: 100%;
  }

  .home_news_title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  .home_news_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }

  .news_date {
    font-size: 12px;
    line-height: 15px;
    color: #949494;
  }

  .home_news_btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #1862af;
  }

  .button {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 82px;

    button {
      width: 400px;
      height: 55px;
      background: #1862af;
      border-radius: 10px;
    }
  }

  @media screen and (max-width: 1024px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
  }
  @media screen and (max-width: 768px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 100%;
    }

    .col-md-3 {
      width: 100%;
    }

    .home_news_info_content {
      margin-bottom: 50px;
    }
  }
  @media screen and (max-width: 425px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 100%;
    }

    .col-md-3 {
      width: 100%;
    }

    .button button {
      font-size: 12px;
      line-height: 14px;
      font-style: normal;
      font-weight: normal;
      max-width: 260px;
    }
  }
`;
