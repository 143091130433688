import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SectionTitles from "../../constants/SectionTitles";
import Path from "../../constants/Path";
import ContentSidebar from "../../constants/ContentSidebar";
import { axiosInstance } from "../../api/axiosInstance";
import Loader from "../../components/Loaders/Loader";
import { useLanguageContext } from "../../Context/Context";
import { capitalizeStr } from "../../helpers/Capitalize";
import { useTranslation } from "react-i18next";

const AboutProject = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [aboutProjectData, setAboutProjectData] = useState([]);
  const { checkLanguage } = useLanguageContext();
  const { t } = useTranslation();

  const getAboutProject = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get("about_project/");
      setAboutProjectData([...response.data]);
      setIsLoading(false);
    } catch (error) {
      console.log("error =>", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAboutProject();
  }, []);

  return (
    <Wrapper>
      <div className="container">
        <div className="container-fluid">
          <SectionTitles>{t("About_Project")} </SectionTitles>
          <Path title={t("About_Project")} />
          <div className="row">
            <div className="col-md-9 col-sm-12">
              {aboutProjectData?.map((item) => {
                return (
                  <div className="about_us_image">
                    <img src={item.logo} width={"100"} alt="" />
                  </div>
                );
              })}
              {aboutProjectData?.map((item) => {
                return (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: checkLanguage(item).text,
                    }}
                  ></div>
                );
              })}
            </div>
            <div className="col-md-3 col-sm-12">
              <ContentSidebar recpublic={false} calendar={true} opros={false} />
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </Wrapper>
  );
};

export default AboutProject;

const Wrapper = styled.div`
  padding: 30px 0;

  .about_content {
    .about_content_items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;
    }

    .about_content_img {
      img {
        width: 100%;
      }
    }

    .about_content_title {
      font-weight: 500;
      font-size: 24.5088px;
      line-height: 30px;
      color: #1862AF;
      margin-bottom: 25px;
    }

    .about_content_text {
      font-weight: 500;
      font-size: 12.2544px;
      line-height: 15px;
      text-align: justify;

      color: #000000;

    }
  }
  .about_us_image{
    margin-bottom: 30px;
    img{
      width: 100%;
      max-height: 400px;
      object-fit: cover;
      background-position: center;
      background-size: cover;
      @media(max-width: 768px){
        height: 250px;
      }
    }
`;
