import React from 'react';
import styled from "styled-components";
import zoomIcon from '../../assets/images/events/zoomIcon.png'
import timeIcon from '../../assets/images/events/timeIcon.png'
import zoomArrow from '../../assets/images/events/zoomArrow.png'
import eventArrow from '../../assets/images/events/eventArrow.png'
import moment from "moment";
import {useHistory} from "react-router-dom";
import {useLanguageContext} from "../../Context/Context";
import {capitalizeStr} from "../../helpers/Capitalize";
import {useTranslation} from "react-i18next";
import ScrollToTop from "../../constants/ScrollToTop/ScrollToTop";

const EventCard = ({el}) => {
	const history = useHistory()
	const {checkLanguage, language} = useLanguageContext()
	const {t} = useTranslation()


	const onLink = () => {
		history.push(`/eventsfeed/${el.id}`)
	}
	return (
		<Wrapper onClick={onLink}>
			<ScrollToTop/>
			<div className='title'>
				<h3>{el.title.length > 60 ? checkLanguage(el).title.substr(0, 60) + '...' : checkLanguage(el).title} </h3>
				<div>
					<div className={'date-month'}>
						<div> {moment(el.startdate).format('DD')}</div>
						<div>{moment(el.startdate).locale('ru').format('MMMM').substr(0, 3)}</div>
					</div>
				</div>
			</div>
			<hr/>

			<div>
				<div className='info'>
					<div className='info-img'>
						<img
							src={el.small ? el.small : 'https://image.shutterstock.com/image-vector/prohibition-sign-no-photography-600w-209270626.jpg'}
							alt=""/>
					</div>
					<div className="info-text">
						<p>{!el.description && 'Приглашаем Вас принять участие в онлайн-тренинге по вопросам электронного здравоохранения, организуе'}
							{el.description.length > 100 ? checkLanguage(el).description.substr(0, 100) + '...' : checkLanguage(el).description}</p>
						<div className="announce_bottom">
							<p className='time'>
								<img src={timeIcon}
								     alt=""/> {moment(el.startdate).format('LT')} - {el?.enddate ? moment(el?.enddate).locale(language).format('DD') : t('дата не указано')}-{el?.enddate && moment(el?.enddate).locale(language).format('MMMM').substring(0, 3)} / {el?.enddate && moment(el?.enddate).format("LT")}
							</p>
							<div className='zoom'>
								<p>
									<img src={zoomIcon} alt=""/>
									<span>{el.place.length > 25 ? checkLanguage(el).place.substring(0, 25) + '...' : checkLanguage(el).place}</span>
								</p>
								<p>
									<img src={zoomArrow} alt=""/>
									<img src={zoomArrow} alt=""/>
									<img src={zoomArrow} alt=""/>

								</p>
							</div>
						</div>

					</div>
				</div>

			</div>
		</Wrapper>
	);
};

export const ResponsiveCard = ({el}) => {
	const history = useHistory()
	const onLink = () => {
		history.push(`/eventsfeed/${el.id}`)
	}
	return (
		<Responsive onClick={onLink}>
			<div className={'block-1'}>
				<div className={'image'}>
					<img
						src={el.small ? el.small : 'https://image.shutterstock.com/image-vector/prohibition-sign-no-photography-600w-209270626.jpg'}
						alt=""/>
					<div className={'date'}>
						<div> {moment(el.startdate).format('DD')}</div>
						<div>{moment(el.startdate).locale('ru').format('MMMM').substr(0, 3)}</div>
					</div>
				</div>
				<div className="title">
					{el.title.length > 100 ? el.title.substr(0, 100) + '...' : el.title}
				</div>
			</div>
			<div className={'block-2'}>
				<div className={'date-zoom'}>
					<div><img src={timeIcon}
					          alt=""/>{moment(el.startdate).format('HH:mm')}-{moment(el.enddate).format('HH:mm').substr(1, 3)}
					</div>
					<div><img src={timeIcon} alt=""/>{el.place ? el.place : <i>Не указано</i>}</div>
				</div>
				<div>
					<img src={eventArrow} alt=""/>
				</div>
			</div>
		</Responsive>

	)
}
export {EventCard};
const Wrapper = styled.div`
  width: 100%;
  background: #FFFFFF;
  //box-shadow: 0px 6.47291px 12.9458px rgba(204, 204, 204, 0.4);
  border-radius: 7.19212px;
  cursor: pointer;
  //box-shadow: 1px 1px 12px 7px rgba(0, 0, 0, 0.2);
  background: #FAFAFA;
  box-shadow: 0px 9px 18px rgba(204, 204, 204, 0.4);


  .date-month {
    display: block !important;
  }

  .title {
    display: flex;
    justify-content: space-between;
    min-height: 74px;

    h3 {
      font-family: "Montserrat", sans-serif;
      margin-top: 18px;
      //margin-bottom: 20px;
      padding-left: 12px;
      padding-bottom: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 17.9803px;
      line-height: 22px;
      color: #000000;
      width: 330px;
      white-space: break-spaces;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      position: relative;

      :before {
        position: absolute;
        content: "";
        height: 2px;
        width: 100px;
        bottom: 0%;
        background: #CADB36;
      }
    }

    > div {
      width: 65px;
      height: 64px;
      background: #CADB36;
      border-radius: 0px 7.19212px 0px 0px;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Montserrat", sans-serif;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 26.9704px;
        line-height: 33px;

        color: #FAFAFA;
      }
    }
  }

  .info {
    display: flex;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12.9458px;
    line-height: 15px;
    color: #000000;

    .info-img {
      display: flex;
      width: 100%;
      width: 140px;
      height: 145px;

      img {
        width: 145px;
        height: 145px;
        border-radius: 0px 7.19212px;
        object-fit: cover;

      }
    }

    .info-text {
      position: relative;

      .announce_bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
      }

      p {
        display: flex;
        color: #000000;
        padding-left: 11px;
        margin-bottom: 18px;
        align-items: center;
        font-size: 12px;
        //для скрытия большого текста 
        //max-height: 48px;
        //display: block;
        //white-space: break-spaces;
        //text-overflow: ellipsis;
        //overflow: hidden;
        //display: block;

        img {
          margin-right: 3px;
        }
      }

    }

    .time {
      font-size: 10.069px;
      line-height: 12px;
      color: #000000;
    }

    .zoom {
      display: flex;
      justify-content: space-between;
      font-size: 10.069px;
      line-height: 12px;
      color: #000000;
      margin-right: 16px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      font-size: 10.069px;
      line-height: 12px;
      color: #000000;
      margin-right: 16px;
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const Responsive = styled.div`
  display: none;
  max-width: 330px;
  background: #FAFAFA;
  box-shadow: 0px 9px 18px rgba(204, 204, 204, 0.4);
  border-radius: 10px;
  cursor: pointer;

  .block-1 {
    display: flex;

    .title {
      margin-top: 33px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      margin-left: 4px;
    }
  }

  .image img {
    width: 125px;
    height: 125px;
    border-radius: 0px 7.19212px;
    border-radius: 10px 0px 0px 0px;
    object-fit: cover;

  }


  .date {
    background: #CADB36;
    border-radius: 10px 0px 0px 0px;
    max-width: 40px;
    position: relative;
    top: -125px;
    color: white;
    text-align: center;
  }

  .block-2 {
    display: flex;
    justify-content: space-between;
    margin-top: -30px;
    padding-bottom: 20px;

    div:nth-child(2) {
      margin-right: 35px;
    }

    .date-zoom {
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      margin-left: 11px;

      img {
        margin-right: 7px;
      }

      div:nth-child(2) {
        margin-top: 10px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: block;
  }
`