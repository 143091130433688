import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import SectionTitles from "../../constants/SectionTitles";
import Path from "../../constants/Path";
import ContentSidebar from "../../constants/ContentSidebar";
import ScrollToTop from "../../constants/ScrollToTop/ScrollToTop";
import {axiosInstance} from "../../api/axiosInstance";
import Loader from "../../components/Loaders/Loader";
import {capitalizeStr} from "../../helpers/Capitalize";
import {useLanguageContext} from "../../Context/Context";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const AboutUs = () => {

	const [aboutUsData, setAboutUsData] = useState([])
	const [isLoading , setIsLoading] = useState(false)
	const { checkLanguage} = useLanguageContext()
	const {t} = useTranslation();

	const getAboutUsData = async () => {
		try {
			setIsLoading(true)
			const response = await axiosInstance.get("about_us/")
			setAboutUsData(response?.data)
			setIsLoading(false)
		} catch (error) {
			console.log("error =>", error)
			setIsLoading(false)
		}
	}

	useEffect(() => {
		getAboutUsData()
	}, [])

	return (
		<Wrapper>
			<ScrollToTop/>
			<div className="container">
				<div className="container-fluid">
					<Link to={"/about-project"}>
						<SectionTitles>{t("About_Us")} </SectionTitles>
					</Link>
					<Path title={"О нас"}/>
					<div className="row">
						<div className="col-md-9 col-sm-12">
							{
								aboutUsData?.map((item) => {
									return <div className = "about_us_image">
										<img src={item.logo} width={"100"} alt=""/>
									</div>

								})
							}
							{
								aboutUsData?.map((item) => {
									return <div dangerouslySetInnerHTML={{__html: checkLanguage(item).text}}>

									</div>
								})
							}

						</div>
						<div className="col-md-3 col-sm-12">
							<ContentSidebar recpublic={false} calendar={true} opros={false}/>
						</div>
					</div>


				</div>
			</div>
			{isLoading && <Loader/>}
		</Wrapper>
	)
}

export default AboutUs

const Wrapper = styled.div`
  padding: 30px 0;
.about_us_image{
  margin-bottom: 30px;
  img{
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    @media(max-width: 768px){
      height: 250px;
    }
  }
}
  .about_content {
    .about_content_items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;
    }

    .about_content_img {
      img {
        width: 100%;
      }
    }

    .about_content_title {
      font-weight: 500;
      font-size: 24.5088px;
      line-height: 30px;
      color: #1862AF;
      margin-bottom: 25px;
    }

    .about_content_text {
      font-weight: 500;
      font-size: 12.2544px;
      line-height: 15px;
      text-align: justify;

      color: #000000;

    }
  }
`
