import React, { useEffect, useState } from "react";
import styled from "styled-components";
import greenArrow from "../../assets/images/vacancies/greenArrow.png";
import ContentSidebar from "../../constants/ContentSidebar";
import SectionTitles from "../../constants/SectionTitles";
import Path from "../../constants/Path";
import ScrollToTop from "../../constants/ScrollToTop/ScrollToTop";
import { Link, useHistory, useParams } from "react-router-dom";
import { axiosInstance } from "../../api/axiosInstance";
import moment from "moment";
import Loader from "../../components/Loaders/Loader";
import { useLanguageContext } from "../../Context/Context";
import { capitalizeStr } from "../../helpers/Capitalize";
import { useTranslation } from "react-i18next";
import NotFound from "../../components/NotFound/NotFound";

const TagsSearchResult = () => {
  const [searchResults, setSearchResults] = useState([]);
  // const [tagResults , setTagResults] = useState([])
  const [isLoading, setisLoading] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const { text } = useParams();
  const { checkLanguage } = useLanguageContext();

  const getSearchResults = async () => {
    setisLoading(true);
    try {
      const response = await axiosInstance.get(`api/search/?search=${text}`);
      setSearchResults(response.data);
      setisLoading(false);
    } catch (error) {
      console.log("error =>", error);
      setisLoading(false);
    }
  };

  useEffect(() => {
    getSearchResults();
  }, [text]);

  if (
    searchResults?.announcement?.length === 0 &&
    searchResults?.news.length === 0 &&
    searchResults?.resourses?.length === 0 &&
    !isLoading
  ) {
    return <NotFound />;
  }

  return (
    <Wrapper>
      <ScrollToTop />
      <div className="container">
        <div className="container-fluid">
          <SectionTitles>{t("Результаты поиска")}</SectionTitles>
          <Path title={"Теги"} />
          <div className="row">
            <div className="col-md-9 col-sm-12 col-xs-12">
              <div className="section_content">
                <div className="result_items">
                  {/*get news*/}
                  {searchResults?.news?.map((item, index) => {
                    console.log(item, "<<==item==>");
                    return (
                      <div
                        className="result_item"
                        key={item.id}
                        onClick={() => history.push(`/news/${item.id}`)}
                      >
                        <div className="result_image">
                          {/*<img*/}
                          {/*	src={"https://media.wired.com/photos/598e35fb99d76447c4eb1f28/master/pass/phonepicutres-TA.jpg"}*/}
                          {/*	alt=""/>*/}
                          <img src={item?.small} width={"100"} alt="" />
                        </div>
                        <div className="result_info">
                          <div>
                            <div className="date">
                              {/*{item.created}*/}
                              {moment(item.created).locale("ru").format("LL")}
                            </div>
                            <h3>{capitalizeStr(checkLanguage(item).title)}</h3>

                            <p>
                              {capitalizeStr(checkLanguage(item).description)}
                            </p>
                          </div>
                          <div className="arrow">
                            <img src={greenArrow} alt="" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* end of get news*/}

                  {/*get from announcement*/}
                  {searchResults?.announcement?.map((item) => {
                    return (
                      <div
                        className="result_item"
                        key={item.id}
                        onClick={() => history.push(`eventsfeed/${item.id}`)}
                      >
                        <div className="result_image">
                          <img src={item?.small} width={"100"} alt="" />
                        </div>
                        <div className="result_info">
                          <div>
                            <div className="date">
                              {/*{item.created}*/}
                              {moment(item.created).locale("ru").format("LL")}
                            </div>
                            <h3>{capitalizeStr(checkLanguage(item).title)}</h3>
                            <p>
                              {capitalizeStr(checkLanguage(item).description)}
                            </p>
                          </div>
                          <div className="arrow">
                            <img src={greenArrow} alt="" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/*end of get from announcement*/}

                  {/*get from resources*/}
                  {searchResults?.resourses?.map((item, index) => {
                    return (
                      <div
                        className="result_item"
                        key={item.id}
                        onClick={() => history.push(`news/${item.id}`)}
                      >
                        <div className="result_image">
                          <img
                            src={
                              "https://thumbs.dreamstime.com/b/document-icon-vector-stack-paper-sheets-illustration-131104983.jpg"
                            }
                            alt=""
                          />
                        </div>
                        <div className="result_info">
                          <div>
                            <div className="date">
                              {/*{item.created}*/}
                              {moment(item.created).locale("ru").format("LL")}
                            </div>
                            <h3>{capitalizeStr(checkLanguage(item).title)}</h3>
                            <p>{item?.publisher}</p>
                          </div>
                          <div className="arrow">
                            <img src={greenArrow} alt="" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/*end of get from resources*/}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 col-xs-12">
              <ContentSidebar recpublic={false} />
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </Wrapper>
  );
};

export default TagsSearchResult;

const Wrapper = styled.div`
  .result_items {
    .result_item {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      .result_info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 6px 15px rgba(204, 204, 204, 0.4);
        border-radius: 10px;
        padding: 10px;
        padding-right: 40px;
        padding-top: 25px;
        margin: 10px 0;
        position: relative;
        width: 100%;

        .date {
          position: absolute;
          top: 0;
          left: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          background: #008dd2;
          border-radius: 10px 0px 0px 0px;
          padding: 3px;
        }

        h3 {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1862af;
          text-align: center;
        }

        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #949494;
          margin-right: 10px;
        }
      }

      .result_image {
        margin-right: 10px;
        width: 85px;
        @media (max-width: 500px) {
          display: none;
        }

        img {
          border-radius: 5px;
          width: 100%;
          object-fit: cover;
          height: 80px;
        }
      }
    }
  }
`;
