export const responsiveHeaderData =[
    {
        title_ru:"О нас",
        title_en:"About Us",
        title_kg:"Биз жөнүндө",
        id:1,
        info:[
            {
                title_ru: "Об ИМО",
                title_kg: "ИМО жөнүндө",
                title_en: "About ИМО",
                link: '/about-us'
            },
            {
                title_ru: "Сотрудники",
                title_kg: "Кызматкерлер",
                title_en: "Employees",
                link: '/staff-all'
            },
            {
                title_ru: "Партнеры",
                title_kg: "Өнөктөштөр",
                title_en: "Partners",
                link: '/partners'
            },
            {
                title_ru: "Вакансии",
                title_kg: "Вакансиялар",
                title_en: "Vacancies",
                link: '/vacancies'
            },
        ]
    },    {
        title_ru:"О проекте",
        title_en:"About Project",
        title_kg:"долбоор жөнүндө",
        id:2,
        info:[
            {
                title_ru: "Фаза |",
                title_kg: "Фаза |",
                title_en: "Phase |",
                link: '/phase/1'
            },
            {
                title_ru: "Фаза ||",
                title_kg: "Фаза ||",
                title_en: "Phase ||",
                link: '/phase/2'
            },
            {
                title_ru: "Фаза |||",
                title_kg: "Фаза |||",
                title_en: "Phase |||",
                link: '/phase/3'
            },
            {
                title_ru: "Реформа в лицах",
                title_kg: "Реформа жүзүндө",
                title_en: "Reform in faces",
                link: '/reform-face/'
            },
        ]
    },
    {
        title_ru:"Новости",
        title_en:"news",
        title_kg:"кабарлар",
        id:3,
        info:[
            {
                title_ru: "Анонсы",
                title_kg: "Кулактандыруулар",
                title_en: "Announcements",
                link: '/eventsfeed'
            },
            {
                title_ru: "Новости",
                title_kg: "кабарлар",
                title_en: "news",
                link: '/news'
            },
        ]
    },
    {
        title_ru:"Ресурсы",
        title_en:"Resources",
        title_kg:"Ресуртар",
        id:4,
        info:[
            {
                title_ru:"Ресурсы",
                title_en:"Resources",
                title_kg:"Ресуртар",
                link: '/resources'
            },



        ]
    },

    {
        title_ru:"Галерея",
        title_en:"Gallery",
        title_kg:"Галерея",
        id:5,
        info:[
            {
                title: "Фотогалерея",
                title_ru:"Фотогалерея",
                title_en:"Photo gallery",
                title_kg:"Сүрөт галерея",
                link: '/photogallery'
            },
            {
                title: "Видеогалерея",
                title_ru:"Видеогалерея",
                title_en:"Video gallery",
                title_kg:"Видео галереясы",
                link: '/videogallery'
            },
        ]
    },
    {
        title_ru:"Контакты",
        title_en:"Contacts",
        title_kg:"Байланыштар",
        id:6,
        info:[
            {
                title_ru:"Контакты",
                title_en:"Contacts",
                title_kg:"Байланыштар",
                link: '/contacts'
            },
            {
                title: "Карта сайта",
                title_ru:"Карта сайта",
                title_en:"Map of site",
                title_kg:"Cайттын картасы",
                link: '/roadmap'
            },
            {
                title_ru:"Интерактивная карта",
                title_en:"Interactive map",
                title_kg:"Интерактивдүү карта",
                title: "Интерактивная карта",
                link: '/map'
            },
        ]
    },
]

export const langData = [
    {
        title: 'Кыргызча',
        info:[
            {
                title: "Русский",
                link: '/staff-all'
            },
            {
                title: "Английский",
                link: '/staff-all'
            },
        ]
    },
]