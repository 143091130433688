import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import {axiosInstance} from "../api/axiosInstance";
import {useTranslation} from "react-i18next";
import {useLanguageContext} from "../Context/Context";
import Chart from "../assets/images/oprosr-chart.png"

const   Opros = () => {
const {t} = useTranslation()
	const { checkLanguage  , language} = useLanguageContext()

	const [questions, setQuestions] = useState([])

	const getQuestions = async () => {
		try {
			const response = await axiosInstance.get("quiz/")
			setQuestions(response.data)
		} catch (error) {
			console.log("error =>", error)
		}
	}
	

	useEffect(() => {
		getQuestions()
	}, [])

	return (
		<Wrapper>
			<div className="question pb-5">
				<h1 className="new_info_title">{t("Опрос")}</h1>
				{
					questions.map((item) => {
						return <div key={item.id}>
							<p dangerouslySetInnerHTML={{__html: checkLanguage(item).text}}>
							</p>
							<div className="question_chart">
								<img src={Chart} alt=""/>
							</div>
							<div className="mx-auto text-center button">
								<a  target="_blank" href={checkLanguage(item).link} className="mt-4 btn">{t("Пройти опрос")}</a>
							</div>
						</div>
					})
				}


			</div>
		</Wrapper>
	)
}

export default Opros

const Wrapper = styled.div`
  margin-top: ;

  .question {
    p {
      font-weight: 600;
      font-size: 16.4068px;
      line-height: 20px;
      color: #949494;
    }

    .question_chart {
      img {
        width: 100%;
      }
    }
  }
  .button {
    margin-top: 30px;

    .btn {

      background: #CADB36;
      border-radius: 6.83616px;
      color: #fff;
      font-weight: 600;
      font-size: 18.851px;
      line-height: 23px;
      padding: 10px 40px;
      outline: none;
      border: none;
      text-decoration: none;
      //white-space: nowrap;
    }
  }
`
