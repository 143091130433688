import React, {useState} from 'react'
import styled from 'styled-components'
import logo from '../../assets/images/header/header-logo.png'
import MiniLogo from '../../assets/images/header/MiniLogo.png'
import instIcon from '../../assets/images/header/instagramIcon.svg'
import facebookIcon from '../../assets/images/header/facebookIcon.svg'
import youtubeIcon from '../../assets/images/header/youtubeIcon.svg'
import navArrow from '../../assets/images/header/navArrow.png'
import BurgerNav from "../../assets/images/header/burger.png"
import LogoMer from "../../assets/images/header/MER-LOGO.png"
import LogoImo from "../../assets/images/header/IMO-logo.svg"

import mbInst from "../../assets/images/header/mb-inst.png"
import mbFB from "../../assets/images/header/mb-fb.png"
import mbYoutube from "../../assets/images/header/mb-yt.png"
import {Link, useHistory} from "react-router-dom";
import {langData, responsiveHeaderData} from "./ResponsiveHeaderData";
import ResponsiveHeaderMenu from "./ResponsiveHeaderMenu";
import {useTranslation} from 'react-i18next'
import {useLanguageContext} from "../../Context/Context";
import {capitalizeStr} from "../../helpers/Capitalize";
import SearchInput from "./SearchInput";


const Header = ({isOnline , openInput , inputOpen}) => {


	const {t} = useTranslation();
	const [input, setInput] = useState(false)
	const [burger, setBurger] = useState(false)

	const {switchLang, language} = useLanguageContext()


	return (
		<Wrapper top={isOnline}>
			<div className="header-container">
				<HeaderNav>
					<TopNav>
						<div className='topNav-container'>
							<ul>
								<li className="image_logo">
									<a href="https://www.instagram.com/mer.project/?utm_medium=copy_link"
									   target="_blank">
										<img src={instIcon} alt='inst'/>
									</a>
								</li>
								<li>
									<a href="https://www.facebook.com/MER.Project/" target="_blank">
										<img src={facebookIcon} alt='facebook'/>
									</a>
								</li>
								<li>
									<a href="https://www.youtube.com/channel/UC40ufL3yQpk1q9xAysyVujQ" target="_blank">
										<img src={youtubeIcon} alt='youtube'/>
									</a>
								</li>
							</ul>
							<ul>
								<li onClick={() => switchLang('ky')} className={language == "ky" && "active_language"}>
									KG <i></i>
								</li>
								<li onClick={() => switchLang('ru')} className={language == "ru" && "active_language"}>
									RU<i></i>
								</li>
								<li onClick={() => switchLang('en')}
								    className={language == "en" && "active_language"}>EN
								</li>
							</ul>
						</div>
					</TopNav>
					<BottomNav>
						<Link to={"/"} className='logo_image_content'>
							<div className="logo_content">
								<div className = "logo_for_big_screen">
									<div className = "imo_logo">
										<img src={LogoImo} alt=""/>
									</div>
									<div className = "mer_logo">
										<img src={LogoMer} alt=""/>
									</div>
									{/*<img src={logo} alt='logo'/>*/}

								</div>
								<div className = "logo_for_small_screen">
									<img src={MiniLogo} alt="mini"/>
								</div>
							</div>

						</Link>
						<div className="links-navbar">
							<ul className='bottomNav show-hidden'>
								<li>
									<a href="#" className="desktop-link">{t("About_Us")}</a>
									<input type="checkbox" id="show-features"/>
									<label htmlFor="show-features">{t("About_Us")}</label><img src={navArrow}
									                                                           alt='arrow'/>
									<ul id={'dropdown-items'}>
										<li><Link to={'/about-us'}>{t("About_Us")}</Link></li>
										<li><Link to={'/staff-all'}>{t("Employees")}</Link></li>
										<li><Link to={'/partners'}>{t("Partners")}</Link></li>
										<li><Link to={'/donors'}>{t("Donors")}</Link></li>
										<li><Link to={'/vacancies'}>{t("Vacancy")}</Link></li>
									</ul>
								</li>
								<li>
									<Link to={"/about-project"} className="desktop-link">{t("About_Project")}</Link>
									<input type="checkbox" id="show-features"/>
									<label htmlFor="show-features">{t("About_Project")}</label><img src={navArrow}
									                                                                alt='arrow'/>
									<ul id={'dropdown-items'}>

										<li><Link to={"/about-project"}>{t("About_Project")}</Link></li>
										<li><Link to={'/phase/1'}>{t("Phase1")}</Link></li>
										<li><Link to={'/phase/2'}>{t("Phase2")}</Link></li>
										<li><Link to={'/phase/3'}>{t("Phase3")}</Link></li>
										<li><Link to={'/reform-face'}>{t("ReformFace")}</Link></li>
									</ul>
								</li>
								<li>
									<a href="#" className="desktop-link">{t("News")}</a>
									<input type="checkbox" id="show-features"/>
									<label htmlFor="show-features">{t("News")}</label><img src={navArrow} alt='arrow'/>
									<ul id={'dropdown-items'}>
										<li><Link to={'/eventsfeed'}>{t("Announce")}</Link></li>
										<li><Link to={'/news'}>{capitalizeStr(t("News"))}</Link></li>
									</ul>
								</li>
								<li>
									<a href="#" className="desktop-link">{t("Resources")}</a>
									<input type="checkbox" id="show-features"/>
									<label htmlFor="show-features">{t("Resources")}</label><img src={navArrow}
									                                                            alt='arrow'/>
									<ul id={'dropdown-items'}>
										<li><Link to={'/resources'}>{t("Resources")}</Link></li>

									</ul>
								</li>
								{!input && <li>
									<a href="#" className="desktop-link">{t("Gallery")}</a>
									<input type="checkbox" id="show-features"/>
									<label htmlFor="show-features">{t("Gallery")}</label><img src={navArrow}
									                                                          alt='arrow'/>
									<ul id={'dropdown-items'}>
										<li><Link to={'/photogallery'}>{t("PhotoGallery")}</Link></li>
										<li><Link to={'/videogallery'}>{t("VideoGallery")}</Link></li>
									</ul>
								</li>}
								{!input && <li>
									<a href="#" className="desktop-link">{t("Contacts")}</a>
									<input type="checkbox" id="show-features"/>
									<label htmlFor="show-features">{t("Contacts")}</label><img src={navArrow}
									                                                           alt='arrow'/>
									<ul id={'dropdown-items'}>
										<li><Link to={'/contacts'}>{t("Contacts")}</Link></li>
										<li><Link to={'/roadmap'}>{t("SiteMap")}</Link></li>
										<li><Link to={'/map'}>{t("InteractiveMap")}</Link></li>
									</ul>
								</li>}
							</ul>
							<div className="search_input">
								<SearchInput openInput={openInput} inputOpen={inputOpen}/>

								<div className="burger_navbar">
									<img onClick={() => setBurger(!burger)} src={BurgerNav} alt=""/>
								</div>
							</div>

						</div>
					</BottomNav>
				</HeaderNav>
			</div>
			<div className={burger ? 'burger-menu active-menu' : 'burger-menu'}>
				<ul className={'header_content_inner'}>
					{responsiveHeaderData.map((el, id) => {
						return <ResponsiveHeaderMenu key={id} el={el} setBurger={setBurger} lang={language}/>
					})}

				</ul>
				<div className=" responsive_translate d-flex justify-content-around my-3 ">
					<div onClick={() => switchLang('ky')} className={language == "ky" && "active_language"}>
						KG
					</div>
					<div onClick={() => switchLang('ru')} className={language == "ru" && "active_language"}>
						RU
					</div>
					<div onClick={() => switchLang('en')} className={language == "en" && "active_language"}>EN</div>
				</div>
				<div className={'icons-menu'}>
					<a href="https://www.instagram.com/mer.project/?utm_medium=copy_link" target="_blank">
						<img src={mbInst} alt=""/>
					</a>
					<a href="https://www.facebook.com/MER.Project/" target="_blank">
						<img src={mbFB} alt=""/>
					</a>
					<a href="https://www.youtube.com/channel/UC40ufL3yQpk1q9xAysyVujQ" target="_blank">
						<img src={mbYoutube} alt=""/>
					</a>
				</div>
			</div>
		</Wrapper>
	)
}

export default Header

const Wrapper = styled.header`
  margin-top: ${({top}) => top ? "0px" : "100px"};
  //margin-top: {({top}=>top ? "":"")};
  height: 137px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #f9f9f9;
  //background: #20386E;
  position: relative;
  @media (max-width: 768px) {
    height: 93px;
  }

	#dropdown-items li a{
		font-size: 16px;
	}
  .search_input {
    margin-left: 25px;
    display: flex;
    align-items: center;
  }

  .burger_navbar {
    cursor: pointer;
    display: none;
    margin: 15px 23px 0 23px;
    @media (max-width: 900px) {
      display: block;
    }

    img {
      width: 100%;
      width: 25px;
      height: 20px;
    }
  }
}

.responsive_translate {
  div {
    cursor: pointer !important;
  }

}

.active_language {
  color: #008DD2;
}

label {
  font-size: 17px;
  white-space: pre-wrap;
  margin-right: 10px;
  margin-right: 10px;

  //width: 110px;
}


.burger-menu {
  display: none;
}

.header-container {
  max-width: 1300px;
  margin: 0 auto;
}

.logo_image_content {
  z-index: 3;
  margin-top: -45px;
}

.logo_for_small_screen img {
  display: none;
}
.logo_for_big_screen {
  clip-path: polygon(0 0, 100% 0%, 71% 100%, 0% 100%);
  background: #fff;
  width: 264px;
  height: 138px;
  .imo_logo{
    width: 200px;
    height: 90px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .mer_logo{
    width: 150px;
    height: 33px;
    //margin: 0 auto;
    margin-left: 10px;
    margin-top: 5px;
    img{
      width: 100%;
      height: 100%;
    }
  }

}
@media (max-width: 768px) {
  .logo_image_content {
    margin-top: 0px;
  }
  .logo_for_big_screen {
    display: none;
  }
 .logo_for_big_screen img{
    display: none;
  }

  .logo_for_small_screen img {
    height: 93px;
    display: block;
  }
}

@media (max-width: 450px) {
  .logo_image_content {
    margin-top: 0;
  }

  .logo_for_big_screen img {
    display: none;
  }

  .logo_image_content .logo_content img:nth-child(2) {
    height: 93px;
    display: block;
  }
}

@media (max-width: 1024px) {
  .burger-menu {
    display: block;
    z-index: -2;
    position: relative;
    color: black;
    background-color: white;
    opacity: 0;
    transform: translateY(-100%);
    transition: all 0.2s;
  }

  .active-menu {
    transform: translateY(0);
    opacity: 1;
    z-index: 50;
    transition: all 0.2s;
    position: relative;
    z-index: 1001;
  }

  .icons-menu {
    z-index: 50;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;

    img {
      width: 40px;
      margin-right: 15px;
    }
  }

  .header_content_inner {
    padding: 10px 20px;
    position: relative;
    z-index: 1002;

    .header_accordtion {
      border-top: 3px solid #FFFFFF;
      padding: 5px 0;
      text-align: center;
      color: #fff;
      border-bottom: 1px solid #d5d5d5;


      a {
        text-decoration: none;
      }

      span {
        padding-bottom: 10px;
        font-weight: bolder;
        font-size: 16px;
        line-height: 17px;
        color: black;
        display: flex;
        justify-content: center;
        cursor: pointer;
        align-items: flex-start;
      }

      i {
        border: solid #CADB36;;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin-left: 20px;
        transition: all 0.5s ease;
      }

      .top-arrow {
        border: solid #CADB36;;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        margin-left: 20px;
        transition: all 0.5s ease;
      }

      ul {
        padding-left: 0;

      }
    }

    .header_accordtion_item {
      transition: all 1s ease-in-out;
      color: black;
      margin-bottom: 8px;


      a {
        transition: all 0.3s ease;
        text-decoration: none;
        font-weight: 500;
        font-size: 12px;
        line-height: 25px;
        color: black;
        display: block;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  height: 93px;
  .header_content_inner .header_accordtion {
    text-align: left;

    > span {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      align-items: flex-start;
    }

    li {
      margin-left: 20px;
    }
  }

  .header_content_inner .lang > span {
    justify-content: center;
  }

  .lang ul {
    text-align: center;
  }
}
`
const HeaderNav = styled.div`
  .main-container {
    display: flex;
  }
`
const TopNav = styled.div`
  background-color: #20386e;
  height: 45px;
  position: relative;

  :after {
    position: absolute;
    content: '';
    height: 45px;
    width: 200%;
    background: #20386e;
    top: 0;
    right: -200%;
  }

  :before {
    position: absolute;
    content: '';
    height: 45px;
    width: 200%;
    background: #20386e;
    top: 0;
    left: -200%;
  }

  @media (max-width: 1024px) {
    :before, :after {
      display: none;
    }
  }

  ul {
    display: flex;
    list-style: none;
    padding-right: 22px;
    height: 45px;
    align-items: center;
  }

  li {
    margin-right: 15px;
    cursor: pointer;
  }

  ul li i {
    margin-left: 6.6px;
    width: 0px;
    height: 20.5px;
    border: 1px solid #ffffff;
  }

  .topNav-container {
    //max-width: 1366px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @media (max-width: 900px) {
    display: none;
  }

`
const BottomNav = styled.div`
  background-color: #008dd2;
  position: relative;
  height: 93px;
  display: flex;
  align-items: center;

  :after {
    position: absolute;
    content: '';
    height: 93px;
    width: 200%;
    background: #008dd2;
    top: 0;
    right: -200%;
  }

  :before {
    position: absolute;
    content: '';
    height: 93px;
    width: 200%;
    background: #008dd2;
    top: 0;
    left: -200%;
  }

  @media (max-width: 1024px) {
    :before, :after {
      display: none;
    }
  }
}

.svg-logo {
  display: none;
  background: white;
  flex-flow: column;
  width: 66px;
  justify-content: flex-end;
  //height: 0px;
  //margin-bottom: -46px;
  z-index: 30;
  position: absolute;

  img {
    padding-left: 5px;
    margin-top: 9px;
    margin-bottom: -2px;

  }
}

.parallelogram {
  //position: absolute;
  display: none;
  width: 100px;
  height: 94px;
  -webkit-transform: skew(-30deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  background: white;
  z-index: -20;
  margin-bottom: -46px;
}

.links-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin-left: -30px;
}

.bottomNav {
  display: flex;
  list-style: none;
  padding-left: 22px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #f9f9f9;
  //margin-right: 40px;

}

.bottomNav > li {
  padding-right: 15px;
  height: 60px;
  display: flex;
  align-items: center;
  width: 145px;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;

  &:last-child {
    margin-left: -20px;
  }

  a {
    text-decoration: none;
    color: white;
  }
}

#dropdown-items {
  display: block;

  li:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  li:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  li {
    border-bottom: white 1px solid;
    background: #CADB36;
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: left;
    padding-left: 10px;
    padding-right: 55px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
  }
}

input[type="checkbox"] {
  display: none;
}

.bottomNav li a.desktop-link {
  display: none;
}

.bottomNav ul {
  position: absolute;
  border-radius: 10px 10px 10px 10px;
  text-align: left;
  padding: 0px 0px 10px 20px;
  padding-left: 0;
  top: 60px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;

}

.bottomNav li:hover > ul, .bottomNav li ul:hover {
  z-index: 50;
  top: 55px;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}

.bottomNav ul ul {
  position: static;
  opacity: 1;
  visibility: visible;
  background: none;
  max-height: 0px;
  overflow: hidden;
}

.bottomNav ul li a {
  display: block;
  width: 100%;
  line-height: 30px;
  border-radius: 0px !important;
}

.bottomNav ul ul {
  position: absolute;
  top: 0;
  right: calc(-100% + 8px);
}

.bottomNav ul li {
  position: relative;

}

.bottomNav ul li:hover ul {
  top: 0;
}


@media (max-width: 1024px) {
  .bottomNav li:nth-child(4), .bottomNav li:nth-child(5), .bottomNav li:nth-child(6) {
    display: none;
  }

  .links-navbar {
    margin-left: 0;
  }

  .burger_navbar {
    display: block;
    margin-bottom: 10px;
    margin-left: 50px;
  }

  @media (max-width: 973px) {
    .burger_navbar {
      margin-left: 30px;
    }

    .bottomNav li:nth-child(4) {
      display: none;
    }

    .burger_navbar {
      margin-left: 100px;
    }
  }

  @media screen and (max-width: 900px) {
    .logo_image_content > img:first-child {
      display: none;
    }

    .parallelogram, .svg-logo {
      display: flex;
    }

    .svg-logo img {
      padding-left: 8px;
    }

    .bottomNav {
      margin-left: 20px;
    }


  }
  @media screen and (max-width: 845px) {

    .burger_navbar {
      margin-left: 30px;
    }

    .bottomNav li:nth-child(2) {
      display: none;
    }


  }
  @media screen and (max-width: 700px) {
    .logo_image_content > img:first-child {
      //display: none;
      .logo_content {
        width: 50px;

        img {
          width: 100px;
          height: auto;
          object-fit: contain;
        }
      }
    }


    .parallelogram, .svg-logo {
      display: flex;
    }

    .svg-logo img {
      padding-left: 8px;
    }

    .bottomNav {
      margin-left: 20px;
    }

    .burger_navbar {
      margin-left: 15px;
      margin-top: 10px;
    }

  }
  @media screen and (max-width: 705px) {
    justify-content: space-between;
    .bottomNav {
      display: none;
    }

    .links-navbar {
      max-width: initial;
    }

  }


`
