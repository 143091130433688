import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import SectionTitles from "../../constants/SectionTitles";
import Path from "../../constants/Path";
import ContentSidebar from "../../constants/ContentSidebar";
import {axiosInstance} from "../../api/axiosInstance";
import {useParams} from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import ScrollToTop from "../../constants/ScrollToTop/ScrollToTop";
import Loader from "../../components/Loaders/Loader";
import {useTranslation} from "react-i18next";
import {useLanguageContext} from "../../Context/Context";
const PhotoGalleryDetail = () => {

	const {id} = useParams()
	const [galleryData, setGalleryData] = useState(null)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const {t} = useTranslation();
	const [index, setIndex] = useState(0)

	const [isLoading , setIsLoading] = useState(false)
	const {checkLanguage} = useLanguageContext()

	const openModal = (id ) => {
		setIndex(id)
		setIsModalOpen(!isModalOpen)
	}

	const getGallery = async () => {
		setIsLoading(true)
		try {
			const response = await axiosInstance.get(`photo_gallery/${id}/`)
			setGalleryData({data: response.data})
			setIsLoading(false)
		} catch (error) {
			console.log("error =>", error)
			setIsLoading(false)
		}
	}

	useEffect(() => {
		getGallery()
	}, [])
	return (
		<Wrapper>
			<ScrollToTop/>

			<div className="container">
				<div className="container-fluid">
					<SectionTitles> {t("PhotoGallery")}</SectionTitles>
					<Path gallery title={checkLanguage(galleryData?.data)?.title}/>
					<div className="photo_gallery_content">
						<div className="row">
							<div className="col-md-9 col-sm-12">
								<div className="photo_detail_content">
									<SRLWrapper className = "photo_detail_content">
									{
										galleryData?.data?.gallery?.map((item) => {
											return <div className="photo_detail_items" key={item.id}
											            onClick={() => openModal(item.id,item.gallery)}>
												<div className="image">
													<img src={item.image} alt=""/>
												</div>

											</div>
										})
									}
									</SRLWrapper>
								</div>
							</div>
							<div className="col-md-3 col-sm-12">
								<ContentSidebar recpublic={false}/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				isLoading && <Loader/>
			}
		</Wrapper>
	)
}

export default PhotoGalleryDetail

const Wrapper = styled.div`
  padding-bottom: 70px;

  .photo_detail_content {
    div{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
      grid-gap: 20px;
      @media(max-width: 768px){
        grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
      }
      @media(max-width: 500px){
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      }
    }
    //grid-column-gap: 20px;
  }

  .photo_detail_items {
    cursor: pointer;
    position: relative;

    .image {
      height: 120px;
      img {
        width: 100%;
        height: 100%;
        height: 130px;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }

  .small_camera {
    position: absolute;
    bottom: 5px;
    right: 10px;

    img {
      width: 100%;
    }
  }

`
