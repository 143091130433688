import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ContentSidebar from "../../constants/ContentSidebar";
import SectionTitles from "../../constants/SectionTitles";
import Path from "../../constants/Path";
import EventNewsComp from "../../components/Eventnews/EventnewsComp";
import ToShare from "../../constants/ToShare";
import { useParams } from "react-router-dom";
import { getVacancies, getVacancy, getVacancyDetail } from "../../api/Requests";
import { VacancyComp } from "../../components/Vacancies/VacancyComp";
import Loader from "../../components/Loaders/Loader";
import VacancySidebar from "../../components/Vacancies/VacancySidebar";
import VResponsiveSidebar from "../../components/Vacancies/VResponsiveSidebar";
import { useLanguageContext } from "../../Context/Context";
import { capitalizeStr } from "../../helpers/Capitalize";
import { useTranslation } from "react-i18next";
import Share from "../../components/Share/Share";

const Vacancy = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [vacancy, setVacancy] = useState(null);
  const [vacancies, setVacancies] = useState([]);
  const [vacanciesCompany, setVacanciesCompany] = useState([]);
  const { checkLanguage } = useLanguageContext();
  const { id } = useParams();
  const { t } = useTranslation();
  useEffect(() => {
    getVacancies(setIsLoading, setVacancies);
    getVacancy(setIsLoading, setVacancy, id);
  }, [id]);
  useEffect(() => {
    setVacanciesCompany(
      vacancies.filter(
        (el) =>
          el?.organization == vacancy?.organization && el?.id !== vacancy?.id
      )
    );
  }, [vacancies, vacancy]);
  if (isLoading) return <Loader />;

  return (
    <Wrapper>
      <div className="container ">
        <div className="container-fluid">
          <SectionTitles>{t("Vacancy")}</SectionTitles>
          <Path vacancy={true} title={checkLanguage(vacancy)?.title}></Path>
          <div className="home_news_content">
            <div className="row">
              <div className="col-md-9 col-sm-12 col-xs-12">
                <div className="grid_parent">
                  <VacancyComp
                    setVacancy={setVacancy}
                    vacancy={vacancy}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                  />
                </div>
                <Share />
              </div>
              <div className="col-md-3 col-sm-12">
                <VacancySidebar
                  vacanciesCompany={vacanciesCompany}
                  el={vacancy}
                />
                <VResponsiveSidebar
                  vacanciesCompany={vacanciesCompany}
                  el={vacancy}
                />
                <ContentSidebar
                  recpublic={false}
                  calendar={true}
                  opros={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Vacancy;

const Wrapper = styled.div`
  .home_news_image {
    margin-bottom: -10px;
    width: 100%;
  }

  .home_news_title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  .home_news_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }

  .news_date {
    font-size: 12px;
    line-height: 15px;
    color: #949494;
  }

  .home_news_btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #1862af;
  }

  @media screen and (max-width: 1024px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
  }
  @media screen and (max-width: 768px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 100%;
    }

    .col-md-3 {
      width: 100%;
    }

    .home_news_info_content {
      margin-bottom: 50px;
    }
  }
  @media screen and (max-width: 425px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 100%;
    }

    .col-md-3 {
      width: 100%;
    }
  }
`;
