import React from 'react'
import styled from "styled-components"
import {useQuery} from "react-query";
import {axiosInstance} from "../../api/axiosInstance";
import SectionTitles from "../../constants/SectionTitles";
import Path from "../../constants/Path";
import Loader from "../../components/Loaders/Loader";
import {useLanguageContext} from "../../Context/Context";
import {capitalizeStr} from "../../helpers/Capitalize";
import {useTranslation} from "react-i18next";
import ScrollToTop from "../../constants/ScrollToTop/ScrollToTop";


const Donors = () => {
	const {t} = useTranslation();
	const { checkLanguage} = useLanguageContext()
	const {isLoading, error, data} = useQuery("fetch", () =>
		axiosInstance.get("donor/")
	)
	return (
		<Wrapper>
			<ScrollToTop/>
			<div className="container">
				<SectionTitles>{t("Donors")}</SectionTitles>
				<Path title={"Доноры"}/>
				<div className="donor_content">
					{
						data?.data?.map((item) => {
							return <div key={item.id}>
								<h4>{capitalizeStr(checkLanguage(item).title)}</h4>
								<div className = "image">
									<img src={item.logo} alt=""/>
								</div>
								<p dangerouslySetInnerHTML={{__html:checkLanguage(item).text}}>
								</p>
								<a href={item.link}  target={"_blank"}>Перейти на сайт</a>
							</div>
						})
					}

				</div>

			</div>
			{
				isLoading && <Loader/>
			}
		</Wrapper>
	)
}

export default Donors

const Wrapper = styled.div`
  .donor_content {
    h4 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #20386E;
      margin-top: 53px;
      margin-bottom: 34px;
    }
.image{
  margin-bottom: 42px;
  img{
    //width: 100%;
    height: 200px;
    
  }
}
  }

`
