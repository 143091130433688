import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import SectionTitles from "../../constants/SectionTitles";
import Path from "../../constants/Path";
import ContentSidebar from "../../constants/ContentSidebar";
import eye from '../../assets/images/videogallery/eye.png'

import {getVideos} from "../../api/Requests";
import moment from "moment";
import {ModalForVideo} from "../../constants/ModalForVideo";
import ReactPlayer from 'react-player'
import Loader from "../../components/Loaders/Loader";
import {capitalizeStr} from "../../helpers/Capitalize";
import {useLanguageContext} from "../../Context/Context";
import {useTranslation} from "react-i18next";
import ScrollToTop from "../../constants/ScrollToTop/ScrollToTop";

const VideoGallery = () => {
	const [modalShow, setModalShow] = useState(false);
	const [isLoading, setIsLoading] = useState(false)
	const [videos, setVideos] = useState([])
const [linkForModal , setLinkForModal] = useState("")
	const { checkLanguage} = useLanguageContext()
	const {t} = useTranslation();
	useEffect(() => {
		getVideos(setIsLoading, setVideos)
	}, [])


	const openModal = (url ) =>{
		setModalShow(true)
		setLinkForModal(url)
	}

	return (
		<Wrapper>
			<ScrollToTop/>
			<div className="container">
				<div className="container-fluid">
					<SectionTitles >{t("VideoGallery")}</SectionTitles>

					<Path gallery={true} title={"Видеогалерея"}/>
					<div className="videogallery_content">
						<div className="row">
							<div className="col-md-9 col-sm-12">
								<div className="grid_parent">

									{
										videos.map((item) => {
											return <div className={'videogallery'}>
												<div className="player">
													<div className="player_overlay" onClick={()=>openModal(item.link)}>
													</div>
													<ReactPlayer playbackRate controls playing={false} muted={false}
													             playsinline={false} width='100%'
													             height='100%' url={item.link}/>
												</div>
												<div
													className="photogallery_footer d-flex justify-content-between align-items-center">
													<div className="date">
														{moment(item.created).format('DD.MM.YY')}
													</div>

												</div>
												<div className="videogallery_text">
													<p className="m-3">{checkLanguage(item).title}</p>
												</div>
												<ModalForVideo
													el={linkForModal}
													show={modalShow}
													onHide={() => setModalShow(false)}
												/>
											</div>
										})
									}


								</div>
								{
									videos.length > 6 && <div className="download_button">
										<button> {t("DownloadMore")}</button>
									</div>
								}

							</div>
							<div className="col-md-3 col-sm-12">
								<ContentSidebar recpublic={false}/>
							</div>
						</div>

					</div>
				</div>
			</div>
			{
				isLoading && <Loader/>
			}
		</Wrapper>
	)
}

export default VideoGallery

const Wrapper = styled.div`
  padding: 70px 0;

  .player {
    position: relative;
    width: 100%;
    height: 200px;
    .player_overlay {
      position: absolute;
      z-index: 100;
      width: 100%;
      width: 100%;
      inset: 0;
      //background: rgba(255,255,255,0.5);
      cursor: pointer;
    }
  }
.videogallery{
  position: relative;
}
  



  .videogallery_content {
    margin-top: 100px;
    
  }

  .photogallery_footer {
    .date {
      padding: 7px 10px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #505050;
    }
  }

  .right_arrow {
    display: flex;
    margin-right: 20px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #767676;
  }

  .download_button {
    text-align: center;
    margin-top: 70px;

    button {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #F7F7F7;
      background: #1862AF;
      border-radius: 10px;
      border: none;
      outline: none;
      padding: 14px 120px;
    }
  }

  .photogallery_item_image {
    position: relative;
  }

  .videogallery_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    @media(max-width: 450px){
      p {
        position: absolute;
        bottom: 20px;
        background: #1862AF;
        border-radius: 0px 7.2549px 7.2549px 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 14.1569px;
        line-height: 12px;
        color: #FCFCFC;
        max-width: 190px;
        padding: 10px;

      }
    }
  }

  .youtube-logo {
    position: absolute;
    z-index: 3;
    top: 5%;
    left: 2%;
  }

  .play {
    position: absolute;
    z-index: 103;
    top: 43%;
    left: 40%;
  }



  @media screen and (max-width: 1024px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
  }
  @media screen and (max-width: 768px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 100%;
    }

    .col-md-3 {
      width: 100%;
    }

    .home_news_info_content {
      margin-bottom: 50px;
    }
  }
  @media screen and (max-width: 425px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 100%;
    }

    .col-md-3 {
      width: 100%;
    }

 



    .download_button {
      display: none;
    }

 




  }
`
