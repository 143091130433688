import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import i18next from "./constants/internationalize/internationalize"
import {I18nextProvider} from "react-i18next";
import ErrorBoundary from "./constants/ErrorBoundary/ErrorBoundary";
import {QueryClient, QueryClientProvider, useQuery} from 'react-query'
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import Context from "./Context/Context";

const queryClient = new QueryClient()
ReactDOM.render(
	<ErrorBoundary>
		<QueryClientProvider client={queryClient}>
			<Context>
			<I18nextProvider i18n={i18next}>
				<App/>
			</I18nextProvider>
			</Context>
		</QueryClientProvider>
	</ErrorBoundary>
	,
	document.getElementById('root')

);
