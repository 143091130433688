import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import ContentSidebar from "../../constants/ContentSidebar";
import SectionTitles from "../../constants/SectionTitles";
import Path from "../../constants/Path";
import EventNewsComp from "../../components/Eventnews/EventnewsComp";
import ToShare from "../../constants/ToShare";
import {useLanguageContext} from "../../Context/Context";
import {axiosInstance} from "../../api/axiosInstance";
import {useParams} from "react-router-dom";
import {getEventsFeedDetail} from "../../api/Requests";
import Loader from "../../components/Loaders/Loader";
import ScrollToTop from "../../constants/ScrollToTop/ScrollToTop";
import {useTranslation} from "react-i18next";
import NotFound from "../../components/NotFound/NotFound";
import Share from "../../components/Share/Share";


const Eventnews = () => {
	const [lastAnnounces, setLastAnnounces] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [announcements, setAnnouncements] = useState(null)
	const {checkLanguage} = useLanguageContext()
	const {id} = useParams()
	const {t} = useTranslation();


	// if(announcements == null ) return <NotFound loading={isLoading}/>

	const getAnnouncesForRecentAnnounce = async () => {
		setIsLoading(true)
		try {
			const response = await axiosInstance.get(`announcement/`)
			setLastAnnounces(response.data.results)
			setIsLoading(false)
		} catch (error) {
			console.log(error , "<<==error==>")
			setIsLoading(false)
		}
	}


	useEffect(() => {
		getEventsFeedDetail(setIsLoading, setAnnouncements, id)
	}, [id])


	useEffect(() => {
		getAnnouncesForRecentAnnounce()
	}, [id])

	return (
		<Wrapper>
			<ScrollToTop/>
			<div className="container ">
				<div className="container-fluid">
					<SectionTitles>{t("Announce")}</SectionTitles>
					<Path announc={true} title={checkLanguage(announcements)?.title}></Path>
					<div className="home_news_content">
						<div className="row">
							<div className="col-md-9 col-sm-12 col-xs-12">
								<div className="grid_parent">
									<EventNewsComp announcements={announcements}/>
								</div>
								<Share/>
							</div>
							<div className="col-md-3 col-sm-12">
								<ContentSidebar calendar={true} opros={false} recentPublicData={lastAnnounces}
								                route={"eventsfeed"}/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				isLoading && <Loader/>
			}
		</Wrapper>
	)
}

export default Eventnews

const Wrapper = styled.div`
  .home_news_image {
    margin-bottom: -10px;
    width: 100%;
  }

  .home_news_title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  .home_news_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }

  .news_date {
    font-size: 12px;
    line-height: 15px;
    color: #949494;
  }

  .home_news_btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #1862AF;
  }

  .responsive-share ul {
    justify-content: flex-start;
  }

  @media screen and (max-width: 1024px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
  }
  @media screen and (max-width: 768px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 100%;
    }

    .col-md-3 {
      width: 100%;
    }

    .home_news_info_content {
      margin-bottom: 50px;
    }
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 40px;
    .col-md-9 {
      flex: 0 0 auto;
      width: 100%;
    }

    .col-md-3 {
      width: 100%;
    }
  }
`
