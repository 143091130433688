import React from 'react'
import styled from "styled-components"


const CustomButton = ({children ,onClick}) => {
  return (
    <Wrapper onClick={onClick}>
        {children}
    </Wrapper>
  )
}

export default CustomButton

const Wrapper = styled.button`
background: #CADB36;
border-radius: 6.83616px;
color:#fff;
font-weight: 600;
font-size: 18.851px;
line-height: 23px;
padding: 10px 40px;
outline: none;
border:none;

`
