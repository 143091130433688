import React from 'react';
import toShareFb from "../assets/images/events/toShareFacebbok.png";
import toShareInst from "../assets/images/events/toShareInst.png";
import styled from "styled-components";

const ToShare = () => {
    return (
        <Wrapper>
            <span>
            Поделиться:
            </span>
            <img src={toShareFb} alt=""/>
            <img src={toShareInst} alt=""/>
        </Wrapper>
    );
};

export default ToShare;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 60px;
    span {
      margin-right: 5px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 17.7062px;
      line-height: 22px;
      text-align: justify;
      color: #000000;
    }
    img {
      margin-right: 11px;
    }
  @media screen and (max-width: 425px) {
    display: none;
  }
`