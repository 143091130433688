export const responsiveFooterData = [
	{
		title: "О нас",
		title_ru: "Сотрудники",
		title_kg: "Биз жөнүндө",
		title_en: "About Us",
		id: 1,
		info: [
			{
				title_ru: "Сотрудники",
				title_kg: "Кызматкерлер",
				title_en: "Employees",
				link: "staff-all"

			},
			{
				title_ru: "Партнеры",
				title_kg: "Өнөктөштөр",
				title_en: "Partners",
				link: "partners"
			},
			{
				title_ru: "Вакансии",
				title_kg: "Вакансиялар",
				title_en: "Vacancies",
				link: "vacancies"
			},
		]
	}, {
		title: "О проекте",
		title_ru: "О проекте",
		title_kg: "Долбоор жөнүндө",
		title_en: "about the project",
		id: 2,
		info: [
			{
				title: "Фаза  |",
				title_ru: "Фаза  |",
				title_kg: "Фаза  |",
				title_en: "Phase  |",
				link:"phase/1"
			},
			{
				title: "Фаза  ||",
				title_ru: "Фаза  ||",
				title_kg: "Фаза  ||",
				title_en: "phase  ||",
				link:"phase/2"
			},
			{
				title: "Фаза  |||",
				title_ru: "Фаза  |||",
				title_kg: "Фаза  |||",
				title_en: "Phase  |||",
				link:"phase/3"
			},
			{
				title: "Реформа в лицах",
				title_ru: "Реформа в лицах",
				title_kg: "Реформа жүзүндө",
				title_en: "Reform in faces",
				link:"reform-face"
			},


		]
	}, {
		title: "Новости",
		title_ru: "Новости",
		title_kg: "Kабарлар",
		title_en: "News",
		id: 3,
		info: [
			{
				title: "Анонсы",
				title_ru: "Анонсы",
				title_kg: "Жарыялар",
				title_en: "Announcements",
				link:"/eventsfeed/"
			},
			{
				title: "Новости",
				title_ru: "Новости",
				title_kg: "Kабарлар",
				title_en: "News",
				link:"/news/"
			},
			{
				title: "Карта сайта",
				title_ru: "Карта сайта",
				title_kg: "Cайттын картасы",
				title_en: "Map of site",
				link:"/map/"
			}

		]
	}, {
		title: "Галерея",
		title_ru: "Галерея",
		title_kg: "Галерея",
		title_en: "Gallery",
		id: 4,
		info: [
			{
				title: "Фотогалерея",
				title_ru: "Фотогалерея",
				title_kg: "Фотогалерея",
				title_en: "Photo gallery",
				link:"photogallery"
			},
			{
				title: "Видеогалерея",
				title_ru: "Видеогалерея",
				title_kg: "Видеогалерея",
				title_en: "Video gallery",
				link:"videogallery"
			},

		]
	},
]