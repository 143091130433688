import React from 'react'
import styled from "styled-components"


const SectionTitles = ({children}) => {
	return (
		<Wrapper>
			{children}
		</Wrapper>
	)
}

export default SectionTitles

const Wrapper = styled.div`
  white-space: nowrap;
  cursor: pointer;
  margin-left: 5px;
  //text-transform: capitalize;
  text-align: left;
  font-weight: 600 !important;
  font-size: 36px !important;
  line-height: 44px !important;
  color: #484848 !important;
  border-bottom: 5px solid #CADB36;
  margin-top: 22px;
  margin-bottom: 30px !important;
  display: inline-block;
  @media (max-width: 900px) {
    font-weight: 600;
    font-size: 20px !important;
    line-height: 20px !important;
    /* identical to box height */
    color: #484848;
  }
`
