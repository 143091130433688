import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Instagram from "../../assets/images/header/instagramIcon.svg";
import Youtube from "../../assets/images/header/youtubeIcon.svg";
import Facebook from "../../assets/images/header/facebookIcon.svg";
import { footerLinksData } from "./FooterLinksData";
import { useHistory } from "react-router-dom";
import { useLanguageContext } from "../../Context/Context";
import { useTranslation } from "react-i18next";
import { getAddressInfo } from "../../api/Requests";
import axios from "axios";

const FooterMain = () => {
  const { switchLang, language } = useLanguageContext();
  const [addressInfo, setAddressInfo] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();
  const { checkLanguage } = useLanguageContext();

  const getAddressData = async () => {
    const { data } = await axios.get("https://ime.kg/addres/");
    setAddressInfo(data);
  };

  useEffect(() => {
    getAddressData();
  }, []);


  return (
    <React.Fragment>
      <Wrapper className="footer_main">
        <div className="footer_main_inner">
          <div className="footer_main_items" style={{textAlign:"right"}}>
            {addressInfo.map((item) => {
              return (
                <div key={item.id}>
                  <h5>{t("Наш адрес")}</h5>
                  <p>{checkLanguage(item)?.place}</p>
                  <p>{t("тел")}: {item.phone}</p>
                  <p>email : {item.email}</p>
                </div>
              );
            })}
          </div>
          {footerLinksData.map((item, index) => {
            return (
              <div className="footer_main_items" key={index}>
                <h5>
                  {language === "ru" && item.title_ru.trim()}
                  {language === "ky" && item.title_kg.trim()}
                  {language === "en" && item.title_en.trim()}
                </h5>

                {item.links.map((item, index) => {
                  return (
                    <p
                      key={index}
                      onClick={() => history.push(`/${item.link}/`)}
                    >
                      {language === "ru" && item.text_ru.trim()}
                      {language === "ky" && item.text_kg.trim()}
                      {language === "en" && item.text_en.trim()}
                    </p>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="footer_footer">
          <ul className="footer_icons">
            <li>
              <a
                href="https://www.instagram.com/mer.project/?utm_medium=copy_link"
                target="_blank"
              >
                <img src={Instagram} alt="" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/MER.Project/" target="_blank">
                <img src={Facebook} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UC40ufL3yQpk1q9xAysyVujQ"
                target="_blank"
              >
                <img src={Youtube} alt="" />
              </a>
            </li>
          </ul>
          <div className="footer_text">
            <p>
              {" "}
              {t(
                "2021, Проект реализован при поддержке Швейцарского управления по  развитию и сотрудничеству (SDC)"
              )}{" "}
            </p>
          </div>
        </div>
      </Wrapper>
    </React.Fragment>
  );
};

export default FooterMain;

const Wrapper = styled.div`
  background: #20386e;
  padding-top: 20px;
  //padding-bottom: 20px;
  //z-index: 100;
  //position: relative;
  width: 100%;
  padding-left: 160px;
  margin-left: -160px;
  //margin-left: 100px;
  .footer_main_inner {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }

  .address {
    position: relative;

    :before {
      position: absolute;
      height: 100%;
      width: 2px;
      background: #fff;
      content: "";
      right: 20px;
    }
  }


  .footer_main_items {
    padding-left: 20px;
    h5 {
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      color: #f7f7f7;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #f7f7f7;
      cursor: pointer;
    }
  }

  .footer_main_items:first-child {
    border-right: 1px solid white;
    padding: 0 20px 0 0;
    p {
      //white-space: nowrap;
      margin-left: -10px;
      cursor: pointer;
      word-break: break-word;
      //&:nth-child(3) {
      //  margin-left: -23px;
      //}
      //
      //&:nth-child(4) {
      //  margin-left: -35px;
      //}
      //
      //&:nth-child(5) {
      //  margin-left: -88px;
      //}
    }
  }
  .footer_icons {
    margin-left: -150px;
    margin-top: 20px;
    display: flex;
    position: relative;
    z-index: 100;

    li {
      margin: 0 10px;
    }
    a {
      cursor: pointer;
    }
  }

  .footer_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer_text {
    p {
      font-weight: 500;
      font-size: 8.38864px;
      line-height: 10px;
      color: #ffffff;
      margin-right: 20px;
      max-width: 250px;
    }
  }
`;
