import React, { useState } from "react";
import styled from "styled-components";

const StaffFilter = ({ crew = [], setFilteredItem }) => {
  const [isActive, setIsActive] = useState("all");
  console.log("crew", crew);

  const handleFilter = (e) => {
    setIsActive(e.target.value);
    if (e.target.value === "all") {
      setFilteredItem(crew);
      console.log("All", e.target.value);
    } else {
      const transformFilter = crew?.filter(
        (item) => item.organization == e.target.value
      );
      setFilteredItem(transformFilter);
    }
  };

  return (
    <Wrapper>
      <button
        onClick={handleFilter}
        value={"all"}
        className={`gray-bg ${isActive === "all" ? "active_btn" : ""}`}
      >
        Все
      </button>
      <button
        onClick={handleFilter}
        value={"1"}
        className={`gray-bg ${isActive === "1" ? "active_btn" : ""}`}
      >
        ИМО
      </button>
      <button
        onClick={handleFilter}
        value={"2"}
        className={`gray-bg ${isActive === "2" ? "active_btn" : ""}`}
      >
        MER
      </button>
    </Wrapper>
  );
};

export default StaffFilter;

const Wrapper = styled.div`
  width: 250px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

  button {
    background: #1862af;
    border-radius: 7.13483px;
    border: none;
    width: 75.62px;
    height: 37.81px;
    color: white;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    background: #949494;

    color: #ffffff;
  }

  .active_btn {
    background: #1862af;
  }
`;
