import "./App.css";
import Routes from "./routes/Routes";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SimpleReactLightbox from "simple-react-lightbox";
import { useEffect, useState } from "react";
import OfflineModal from "./components/OfflineModal/OfflineModal";
import { getAddressInfo } from "./api/Requests";
import axios from "axios";

function App() {
  return (
    <div>
      <SimpleReactLightbox>
        <Routes />
      </SimpleReactLightbox>
    </div>
  );
}

export default App;
