import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import {useLanguageContext} from "../../Context/Context";
import {capitalizeStr} from "../../helpers/Capitalize";
import DownArrow from "../../assets/images/Footer-links.png"

const FooterResponsiveLinks = ({title_ru,title_kg,title_en,info}) => {

    const {test , language} = useLanguageContext()

    const [isOpen, setIsOpen] = useState(false)

    const accordtionOpen = ({}) => {
        setIsOpen(!isOpen)
    }




    return (
        <>
            <div className="footer_accordtion lang" >
                <header>
                    <h4 onClick={accordtionOpen}>
                        { language === "ru" && (title_ru)}
                        { language === "ky" && (title_kg)}
                        { language === "en" && (title_en)}
                        <span><img src={DownArrow} alt=""/></span></h4>
                </header>
                {isOpen && info.map((item, index) => {
                    return <div className="footer_accordtion_item">
                            <Link key={index} to={`/${item.link}/`}>
                                { language === "ru" && (item.title_ru)}
                                { language === "ky" && (item.title_kg)}
                                { language === "en" && (item.title_en)}
                            </Link>



                    </div>
                })}
            </div>
        </>
    )
}

export default FooterResponsiveLinks
