import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import SectionTitles from "../../constants/SectionTitles";
import Path from "../../constants/Path";
import CurrentLocation from "../../assets/images/contact/current-location.png"
import Email from "../../assets/images/contact/email.png"
import Phone from "../../assets/images/contact/phone.png"
import {axiosInstance} from "../../api/axiosInstance";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";
import Share from "../../components/Share/Share";
import axios from "axios";
import {useLanguageContext} from "../../Context/Context";

const Contacts = () => {

	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [phone, setPhone] = useState("")
	const [message, setMessage] = useState("")
	const [addressInfo, setAddressInfo] = useState([]);

	const {t} = useTranslation();
	const { checkLanguage } = useLanguageContext();

	const resetInput = name.length > 0 || email.length > 0 || message.length > 0

	const sendMessage = async (e) => {
		e.preventDefault()
		try {
			const response = axiosInstance.post("contact_form/", {name, email, phone, message})
			console.log("response = >", response)
			Swal.fire({
				position: 'center',
				icon: 'success',
				title: 'Your message successfully sended !!! ',
				showConfirmButton: false,
				timer: 1500
			})

		} catch (error) {
			console.log("error =>", error)
		}
	}

	const getAddressData = async () => {
		const { data } = await axios.get("https://ime.kg/addres/");
		setAddressInfo(data);
	};
	useEffect(() => {
		getAddressData();
	}, []);

	const handleReset = () => {
		setName("")
		setEmail("")
		setMessage("")
		setPhone("")
	}
	return (
		<Wrapper>
			<div className="container">
				<div className="container-fluid">
					<SectionTitles>{t("Contacts")}</SectionTitles>
					<Path title={"Контакты"}/>
					{/*<ResponsiveToShare/>*/}
					<div className = "share">
						<Share/>
					</div>
					<div className="contact_content">
						<div className="row">
							<div className="col-md-6 col-sm-12 ">
								<div className="contact_content_title">
									{t("MessageUs")}
								</div>
								<form onSubmit={sendMessage}>
									<div className="form-group contact_form">
										<label htmlFor="name">{t("Name")} *</label>
										<input type="text" className="form-control" id="name" name={"name"}
										       required={true}
										       aria-describedby="nameHelp" value={name}
										       onChange={(e) => setName(e.target.value)}
										/>
										<label htmlFor="email">Email *</label>
										<input type="email" required={true} className="form-control" id="email"
										       name={"email"}
										       aria-describedby="emailHelp" value={email}
										       onChange={(e) => setEmail(e.target.value)}
										/>
										<label htmlFor="phone">{t("PhoneNumber")}</label>
										<input type="text" required={true} className="form-control" id="phone"
										       name={"phone"}
										       aria-describedby="emailHelp" value={(phone)}
										       onChange={(e) => setPhone(e.target.value)}
										/>
										<label htmlFor="measseage">{t("Ваше сообщение")}</label>
										<textarea className="form-control" id="measseage" name={"measseage"}
										          rows="3" value={message}
										          onChange={(e) => setMessage(e.target.value)}
										></textarea>

									</div>
									<div className="form-button">
										<button onClick={handleReset} disabled={!resetInput}
										        className={`${resetInput ? "send" : "reset"}`}>{t("Reset")}
										</button>
										<button type="submit " className="send"> {t("Send")}</button>
									</div>
								</form>
							</div>
							<div className="col-md-6 col-sm-12 contact_info_wrapper">
								<div className="contact_info">
									<div className="row">
										<div className="col-md-6 contact_info_inner">
											{addressInfo.map((item) => {
												return (
													<>
											<h5>{t("Наш адрес")}:</h5>
											<div className="d-flex">
                                                <span>
                                                <img src={CurrentLocation} alt=""/>
                                            </span>
												<p>{checkLanguage(item)?.place}</p>
											</div>
											<div className="d-flex">
                                                <span>
                                                <img src={Phone} alt=""/>
                                            </span>
												<p>{t("Tel")}:{item.phone}</p>
											</div>
											<div className="d-flex">
                                                <span>
                                                <img src={Email} alt=""/>
                                            </span>
												<p>email:{item.email}</p>
											</div>
													</>
											)})}
										</div>
										<div className="col-md-6">
											<div>
												<h5>{t("Время работы")}</h5>
												<div className="contact_info_inner">
													<p>09:00 - 18:00</p>
													<h5>{t("Обед")}:</h5>
													<p>12:00 - 13:00</p>
												</div>

											</div>


										</div>
									</div>
									<div className="contact_map">
										<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1229.4815658136624!2d74.60440422125137!3d42.8682753523188!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x389eb7d023eee1bf%3A0x7a7744ce5e4a97fa!2s19%20Razzakov%20St%2C%20Bishkek!5e0!3m2!1sen!2skg!4v1650961995223!5m2!1sen!2skg"											style={{border: "0"}}
											title={'youtube-video'}
											allowFullScreen=""
											loading="lazy"></iframe>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	)
}

export default Contacts

const Wrapper = styled.div`
  padding-bottom: 60px;

  .contact_social_icons {
    display: none;
    @media (max-width: 768px) {
      display: block;
      ul {
        display: flex;
        justify-content: flex-end;

        li {
          margin: 0 10px;
        }
      }
    }
  }
.share{
  display: none;
  @media(max-width: 768px){
    display: block;
    margin: 15px auto;
  }
}
  .contact_info_inner {
    img {
      //margin-left: -20px;
      margin-right: 20px;
    }

    h5 {
      font-weight: 600;
      font-size: 18.7333px;
      line-height: 23px;
      /* identical to box height */
      margin-bottom: 15px;


      color: #343434;

    }

    p {
      margin-right: -20px;
      font-weight: 500;
      font-size: 14.7333px;
      line-height: 23px;
      color: #343434;

    }
  }

  .contact_form {
    input, textarea {
      border: 1.98621px solid #CADB36;
      box-sizing: border-box;
      border-radius: 6.62069px;
    }

    input {
      margin-bottom: 40px;
    }

    label {
      font-weight: 500;
      font-size: 15.8897px;
      line-height: 19px;
      color: #000000;
    }


  }

  .form-button {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 20px;
    margin-top: 80px;
    @media (max-width: 768px) {
      margin-top: 40px;
    }

    button {
      border: none;
      outline: none;
    }

    .send {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      background: #CADB36;
      border-radius: 10px;
      padding: 10px 20px;
      color: #F4F4F4;
      @media (max-width: 768px) {
        font-size: 15px;
        padding: 5px 10px;
      }
    }

    .reset {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;

      color: #F4F4F4;
      padding: 10px 20px;
      background: #DDDDDD;
      border-radius: 10px;
      @media (max-width: 768px) {
        font-size: 15px;
        padding: 5px 10px;
      }
    }
  }


  .contact_content {
    a {
      text-decoration: none;
      font-weight: 500;
      font-size: 14.7333px;
      line-height: 23px;
      /* identical to box height */


      color: #343434;
    }

    .contact_content_title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
      margin-bottom: 30px;
    }
  }

  .contact_map {
    width: 100%;
    @media (max-width: 768px) {
      display: none;
    }

    iframe {
      width: 100%;
      height: 300px;
    }
  }

  .contact_info_wrapper {
    @media (max-width: 768px) {
      order: -1;
    }
  }
`
