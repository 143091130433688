import React from 'react'
import styled from "styled-components"
import FooterLogo from "./FooterLogo";
import FooterMain from "./FooterMain";
import ResponsiveFooter from "./ResponsiveFooter";
import Loader from "../Loaders/Loader";


const Footer = () => {



    return (
        <Wrapper>
            <div className="footer_container">
                <div className="footer_inner">
                    <FooterLogo/>
                    <FooterMain/>
                </div>
                <div className="responsive_footer">
                    <ResponsiveFooter/>
                </div>
            </div>

        </Wrapper>
    )
}

export default Footer

const Wrapper = styled.div`
margin-top: 50px;
  .footer_inner {
    display: flex;
    align-items: flex-start;
    @media (max-width: 1200px) {
      align-items: flex-start;
    }
    @media (max-width: 900px) {
      display: none;
    }
  }

  background: #20386E;

  .footer_container {
    max-width: 1400px;
    margin: 0 auto;
  }

  .responsive_footer {
    display: none;
    @media (max-width: 900px) {
      display: block;
    }
  }

`
