import React from 'react';
import {Link} from "react-router-dom";
import ShareIcon from "../assets/images/contact/share-icon.png";
import Facebook from "../assets/images/contact/Facbook.png";
import Instagram from "../assets/images/contact/Instagram.png";
import styled from "styled-components";

const ResponsiveToShare = () => {
    return (
        <Wrapper className="responsive-share">
            <ul>
                <li>
                    <Link>
                        <img src={ShareIcon} alt=""/>
                    </Link>
                </li>
                <li>
                    <Link>
                        <img src={Facebook} alt=""/>
                    </Link>
                </li>
                <li>
                    <Link>
                        <img src={Instagram} alt=""/>
                    </Link>
                </li>
                <li>
                    <Link>
                        <img src={Facebook} alt=""/>
                    </Link>
                </li>
                <li>
                    <Link>
                        <img src={Instagram} alt=""/>
                    </Link>
                </li>
            </ul>
        </Wrapper>
    );
};

export default ResponsiveToShare;

const Wrapper = styled.div`
    display: none;
    @media (max-width: 425px) {
      display: block;
     > ul {
        display: flex;
        justify-content: flex-end;
        padding-left: 0;
        li {
          margin: 0 10px;
        }
      }
`