import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import SectionTitles from "../../constants/SectionTitles";
import Path from "../../constants/Path";
import ContentSidebar from "../../constants/ContentSidebar";
import {axiosInstance} from "../../api/axiosInstance";
import {useHistory, useParams} from "react-router-dom";
import Loader from "../../components/Loaders/Loader";
import {capitalizeStr} from "../../helpers/Capitalize";
import {Button} from "react-bootstrap";
import {useLanguageContext} from "../../Context/Context";
import {useTranslation} from "react-i18next";
import ScrollToTop from "../../constants/ScrollToTop/ScrollToTop";


const ReformFacePage = () => {
	
	const [downloadMorePagination , setDownloadMorePagination] = useState(6)
	const [reformFaceData, setReformFaceData] = useState([])
	const history = useHistory()
	const [isLoading, setIsLoading] = useState(false)
	const { checkLanguage} = useLanguageContext()
	const {t} = useTranslation();

	const sliceOfPagination = reformFaceData.slice(0,downloadMorePagination)

	const getReformFace = async () => {
		setIsLoading(true)
		try {
			const response = await axiosInstance.get("reforma/")
			setReformFaceData(response.data)
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.log("error =>", error)
		}
	}

	
	const LoadMore = () => {
		setDownloadMorePagination(downloadMorePagination + downloadMorePagination)
	}

	useEffect(() => {
		getReformFace()
	}, [])

	return (
		<>
			{ reformFaceData && <Wrapper>
				<ScrollToTop/>
				<div className="container">
					<div className="container-fluid">
						<SectionTitles> {t("ReformFace")}</SectionTitles>
						<Path title={t("ReformFace")}/>
						<div className="row">
							<div className="col-md-9">
								<div className="reformface_content grid_parent">
									{
										sliceOfPagination.map((item) => {
											return <div onClick={() => history.push(`reform-face/${item.id}`)}
														className="reformface_content_item" key={item.id}>
												<div className="reformface_content_item_image">
													<img src={item.image} alt=""/>
												</div>
												<h4>{checkLanguage(item).title}</h4>
												<p>{checkLanguage(item).research.substring(0,150)}...</p>
											</div>
										})
									}

							</div>
								{
									reformFaceData.length >= 6 && <div className="mx-auto text-center mt-5">
										<Button onClick={LoadMore} variant="primary">{t("DownloadMore")}</Button>
									</div>
								}
								{
									reformFaceData > 6 && <div className="mx-auto text-center mt-5">
										<Button onClick={LoadMore} variant="primary"> {t("DownloadMore")}</Button>
									</div>
								}

							</div>
							<div className="col-md-3">
								<ContentSidebar recpublic={false}/>
							</div>
						</div>
					</div>
				</div>
				{isLoading && <Loader/>}
			</Wrapper>
			}
		</>

	)
}

export default ReformFacePage

const Wrapper = styled.div`
  padding: 50px 0 100px;
position: relative;
  .reformface_content {

    .reformface_content_item {
      margin: 10px 10px;
      cursor: pointer;
      text-align: center;


      h4 {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #20386E;
        margin-bottom: 10px;
      }

      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #949494;
      }

      .reformface_content_item_image {
        margin-bottom: 20px;
        //max-width: 230px;

        img {
          //width: 100%;
          //outline: 7px solid #008DD2;
          //outline-offset: 5px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          /* Firefox 1-3.6 */
          -moz-border-radius: 50%;
          height: 230px;
          width: 230px;
          object-fit: cover;
          padding: 5px;
          border: 7px solid #008DD2;
          @media (max-width: 800px) {
            //outline: 4px solid #008DD2;
            //outline-offset: 4px;
            padding: 4px;
            border: 4px solid #008DD2;
          }
          @media(max-width: 768px){
            height: 180px;
            width: 180px;
          }
          @media (max-width: 500px) {
            //outline: 4px solid #008DD2;
            //outline-offset: 4px;
            padding: 3px;
            border: 3px solid #008DD2;
          }
     
        }
      }
    }

  }

`
