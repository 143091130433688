import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import SectionTitles from "../../constants/SectionTitles";
import Path from "../../constants/Path";
import ContentSidebar from "../../constants/ContentSidebar";
import DownloadIcon from "../../assets/images/majesticons_folder-download.png"
import {axiosInstance} from "../../api/axiosInstance";
import ReactPaginate from "react-paginate";
import ResourcesModal from "./ResourcesModal";
import SingleResourceItem from "./SingleResourceItem";
import Loader from "../../components/Loaders/Loader";
import axios from "axios";
import {Link} from "react-router-dom";
import {useLanguageContext} from "../../Context/Context";
import {capitalizeStr} from "../../helpers/Capitalize";
import {useTranslation} from "react-i18next";
import NotFound from "../../components/NotFound/NotFound";


const Resources = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [resources, setResources] = useState([])
	const [categories, setCategories] = useState([])
	const [openModal, setOpenModal] = useState(false)
	const [file, setFile] = useState(null)
	const [categoryId, setCategoryId] = useState(3 )
	const { checkLanguage} = useLanguageContext()
	const perPage = 12;
	const count = resources?.count
	const {t} = useTranslation();

	const getResources = async (page) => {
		try {
			setIsLoading(true)
			const response = await axiosInstance.get(`resourse/?category_resource=${categoryId}&page=${page}&page_size=${perPage}`)
			setResources({...response.data})
			setIsLoading(false)
		} catch (error) {
			// console.log("error =>", error)
			setIsLoading(false)
		}
	}
	const getCategoryResources = async () => {
		try {
			const response = await axiosInstance.get("category_resource/")
			const data = response.data
			setCategories(data)
		} catch (error) {
			// console.log("error =>", error)
		}
	}

	const selectCategory = (item) => {
		setCategoryId(item)
	}


	const handleModal = (e, id, file) => {
		e.preventDefault()

		setFile(file)
		setOpenModal(!openModal)

	}

	let content =""

	if (resources?.results?.length < 1 && !isLoading) {
		 content = <NotFound/>
	}
	else{
		content = resources?.results?.map((item) => {
			return <SingleResourceItem item={item} key={item.id} handleModal={handleModal}/>
		})
	}

	useEffect(() => {
		if (openModal) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'unset'
		}
	}, [openModal])

	useEffect(() => {
		getResources(1).then(null)
		getCategoryResources().then(null)
	}, [categoryId])


	return (
		<Wrapper>

			<div className="container">
				<div className="container-fluid">
					{
						openModal && <ResourcesModal handleModal={handleModal} file={file}/>
					}
					<SectionTitles>{t("Resources")}</SectionTitles>
					<Path title={"Ресурсы"}/>
					<div className="row">
						<div className="col-md-9">
							<div className="resources_content">
								<div className="resources_content_header">
									<p>{t("Тип")}</p>
									<p>{t("Название файла")}</p>
									<p>{t("Размер")}</p>
								</div>
								{content}
							</div>
							{
								resources?.results?.length > 11 && 	<div className="pagination">
									<ReactPaginate
										pageCount={Math.ceil(count / 12)}
										pageRangeDisplayed={2}
										marginPagesDisplayed={1}
										onPageChange={(data) => getResources(data.selected + 1)}
										activeClassName={"active_paginate"}/>
								</div>
							}

						</div>
						<div className="col-md-3">
							<div className="buttons-categories">
								{
									categories.map((item) => {
										return <div className={categoryId === item.id ? "button active" : "button"}
										            key={item.id}
										            onClick={() => selectCategory(item.id)}>
											{(checkLanguage(item).title)}
										</div>
									})
								}

							</div>
							<ContentSidebar recpublic={false}/>
						</div>

					</div>

				</div>
			</div>
			{isLoading && <Loader/>}
		</Wrapper>
	)
}

export default Resources

const Wrapper = styled.div`
  padding: 0 0 50px;
  position: relative;

  .resources_content_header {
    display: grid;
    grid-template-columns: 90px 400px 65px;
    grid-gap: 30px;
    p{
      font-weight: 600;
    }
    @media (max-width: 1200px ) {
      grid-template-columns: 60px 300px 65px 110px;
    }
    @media (max-width: 1000px ) {
      display: none;
    }
  }


  .resources_item {
    display: grid;
    grid-template-columns: 90px 400px 65px 110px;
    grid-gap: 30px;
    margin: 30px 0;
    align-items: center;
    border-top: 2px solid #0a0a0a;
    padding-top: 10px;
    @media (max-width: 1200px ) {
      grid-template-columns: 60px 300px 65px 110px;
    }
    @media (max-width: 1000px ) {
      grid-template-columns: 60px 400px  ;
      .doc_type_img {
        grid-row: 1/4;
      }

      @media (max-width: 600px) {
        grid-template-columns: 60px 1fr;
      }
    }

    .type_and_text {
      //display: flex;
      //align-items: center;
    }

    .size_and_button {
      //display: flex;
      //align-items: center;
    }

    .doc_type_img {
      width: 50px;

      img {
        width: 100%;
      }
    }

    .description {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #1862AF;
      cursor: pointer;
    }

    .size {
      h5 {
        font-size: 14px;
        text-transform: capitalize;
      }
    }


    .download_nutton {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }

      p {
        font-weight: 500;
        font-size: 16.677px;
        line-height: 20px;
        color: #1862AF;
        margin-bottom: 0;
      }
    }

    .size {
      font-weight: 500;
      font-size: 16.677px;
      line-height: 20px;
      color: #000000;
    }
  }

  .pagination {
    margin-top: 40px;

    ul {
      display: flex;
      width: 100%;
      justify-content: space-around;
      max-width: 90vh;
      margin: 0 auto;
      padding-left: 0px;
      align-items: center;

      li {
        list-style: none;
        padding: 10px;
        //border: 2px solid red;
        cursor: pointer;
        //width: 20px;
        a {
          text-decoration: none;
        }
      }
    }

    .active_paginate {
      background: rgba(202, 219, 54, 0.8);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 0;
    }
  }

  .buttons-categories {
    margin-bottom: 50px;

    .button {
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-top: 8px;
      color: #FFFFFF;
      padding: 14px 40px;
      background: #008DD2;
      border-radius: 7.04606px;
      text-align: center;
    }


    .active {
      background: #CADB36;
    }
  }
`

const NotFoundWrapper = styled.div`
  //max-height: 100vh;
  padding: 100px 0;
  @media (max-width: 768px) {
    padding-top: 50px;
  }

  h1 {
    text-align: center;
  }

  .button {
    text-align: center;
    margin-top: 80px;

    a {
      padding: 10px 20px;
      background: #008DD2;
      color: #fff;
      font-size: 22px;
      text-decoration: none;
      border-radius: 10px;
    }

  }

  .image {
    width: 50%;
    margin: 0 auto;
    margin-top: 20px;

    img {
      width: 100%;
    }

`
