import React from "react";

export const footerLinksData = [
  // {
  // 	title:"Наш адрес",
  // 	title_ru:"Наш адрес",
  // 	title_kg:"Биздин дарек",
  // 	title_en:"Our address",
  // 	links:[
  // 		{
  // 			link:"",
  // 			text:"720000 г. Бишкек",
  // 			text_ru:"720000 г. Бишкек",
  // 			text_kg:"720000 г. Бишкек",
  // 			text_en:"720000 г. Bishkek",
  // 		},
  // 		{
  // 			link:"",
  // 			text:"ул. Московская, 189",
  // 			text_ru:"ул. Московская, 189",
  // 			text_kg:"ул. Московская, 189",
  // 			text_en:" Moscow str, 189",
  // 		},
  // 		{
  // 			link:"",
  // 			text:"тел: +996 555 555 555",
  // 			text_ru:"тел: +996 555 555 555",
  // 			text_kg:"тел: +996 555 555 555",
  // 			text_en:"phone: +996 555 555 555",
  // 		},
  // 		{
  // 			link:"",
  // 			text:"email: merproject_gmail.com",
  // 			text_ru:"email: merproject_gmail.com",
  // 			text_kg:"email: merproject_gmail.com",
  // 			text_en:"email: merproject_gmail.com",
  // 		},

  // 	]
  // },
  {
    title: "О нас",
    title_ru: "О нас",
    title_kg: "Биздин дарек",
    title_en: "Our address",
    links: [
      {
        link: "About-us",
        text: "Об ИМО",
        text_ru: "Об ИМО",
        text_kg: " ИМО жөнүндө",
        text_en: "About IMO",
      },
      {
        link: "Staff-all",
        text: "Сотрудники",
        text_ru: "Сотрудники",
        text_kg: " Кызматкерлер",
        text_en: "Employees",
      },
      {
        link: "Vacancies",
        text: "Вакансии",
        text_ru: "Вакансии",
        text_kg: " Вакансиялар",
        text_en: "Vacancies",
      },
      {
        text_ru: "Партнеры",
        text_kg: "Өнөктөштөр",
        text_en: "Partners",
        link: "partners",
      },

      {
        text_ru: "Доноры",
        text_kg: "Донорлор",
        text_en: "Donors",
        link: "donors",
      },
    ],
  },
  {
    title: "О проекте",
    title_ru: "О проекте",
    title_kg: "Биздин дарек",
    title_en: "Our address",
    links: [
      {
        link: "phase/1",
        text: "Фаза |",
        text_ru: "Фаза |",
        text_kg: " Фаза |",
        text_en: "Phase |",
      },
      {
        link: "phase/2",
        text: "Фаза ||",
        text_ru: "Фаза ||",
        text_kg: " Фаза ||",
        text_en: "Phase ||",
      },
      {
        link: "phase/3",
        text: "Фаза III",
        text_ru: "Фаза |||",
        text_kg: " Фаза |||",
        text_en: "Phase |||",
      },
      {
        link: "phase/3",
        text: "Реформа в лицах",
        text_ru: "Реформа в лицах",
        text_kg: " Жүзүндө реформа",
        text_en: "Reform in faces",
      },
    ],
  },
  {
    title: "Новости",
    title_ru: "Новости",
    title_kg: "Жаңылыктар",
    title_en: "News",
    links: [
      {
        link: "eventsfeed",
        text: "Анонсы",
        text_ru: "Анонсы",
        text_kg: "Жарыялар",
        text_en: "Announcements",
      },
      {
        link: "roadmap",
        text: "Карта сайта",
        text_ru: "Карта сайта",
        text_kg: "Сайттын картасы",
        text_en: "Map of site",
      },
    ],
  },
  {
    title: "Галерея",
    title_ru: "Галерея",
    title_kg: "Галерея",
    title_en: "Gallery",
    links: [
      {
        link: "photogallery",
        text: "Фотогалерея",
        text_ru: "Фотогалерея",
        text_kg: "Фотогалерея",
        text_en: "Photo gallery",
      },
      {
        link: "videogallery",
        text: "Видеогалерея",
        text_ru: "Видеогалерея",
        text_kg: " Видеогалерея",
        text_en: "Video gallery",
      },
    ],
  },
  {
    title: "Контакты",
    title_ru: "Контакты",
    title_en: "Contacts",
    title_kg: "Байланыштар",
    link: "contacts",
    links: [
      {
        text: "Карта сайта",
        text_ru: "Карта сайта",
        text_en: "Map of site",
        text_kg: "Cайттын картасы",
        link: "roadmap",
      },

      {
        text_ru: "Интерактивная карта",
        text_en: "Interactive map",
        text_kg: "Интерактивдүү карта",
        text: "Интерактивная карта",
        link: "map",
      },
    ],
  },
];
