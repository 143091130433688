import React, {useContext, useState} from 'react';
import i18n from "../constants/internationalize/internationalize";

const LanguageCheckerContext = React.createContext()

const Context = ({children}) => {

	const [language, setLanguage] = useState(localStorage.getItem("lang"))
	const [availableDates, setAvailableDates] = useState([])
	const [lastPublication, setLastPublication] = useState([])
	const [lastAnnounces , setLastAnnounces] = useState()


	const checkLanguage = (item = {}) => {
		if (language === 'ru') {
			return {
				title: item?.title_ru_ru || item?.title,
				text: item?.text_ru_ru || item?.text,
				description: item?.description_ru_ru || item?.description,
				place: item?.place_ru_ru || item?.place,
				research: item?.research_ru_ru || item?.research,
				link:item?.link_ru_ru || item?.link
			}
		}
		if (language === 'ky') {
			return {
				title: item?.title_ky || item?.title,
				text: item?.text_ky || item?.text,
				description: item?.description_ky || item?.description,
				place: item?.place_ky || item?.place,
				research: item?.research_ky || item?.research,
				link:item?.link_ky || item?.link
			}
		}
		if (language === 'en') {
			return {
				title: item?.title_en_us || item?.title,
				text: item?.text_en_us || item?.text,
				description: item?.description_en_us || item?.description,
				place: item?.place_en_us || item?.place,
				research: item?.research_en_us || item?.research,
				link:item?.text_en_us || item?.link
			}
		}
		return {
			title: item?.title,
			text: item?.text,
			description: item?.description,
			place: item?.place,
			research: item?.research,
			link:item?.link

		}

	}


	const switchLang = (lang) => {
		setLanguage(lang);
		i18n.changeLanguage(lang);
		localStorage.setItem('lang', lang);
	}


	return (
		<LanguageCheckerContext.Provider
			value={{switchLang, language, checkLanguage, setAvailableDates, availableDates ,lastPublication, setLastPublication,lastAnnounces , setLastAnnounces}}>
			{children}
		</LanguageCheckerContext.Provider>
	);
};

export default Context;


export const useLanguageContext = () => {
	return useContext(LanguageCheckerContext)
}