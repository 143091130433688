import React from 'react'
import styled from "styled-components"
import {Accordion} from "react-bootstrap";
import {capitalizeStr} from "../../helpers/Capitalize";
import {useLanguageContext} from "../../Context/Context";
import {useTranslation} from "react-i18next";


const MapAccordtion = ({showPlacemark, showPlacemarks, item }) => {
	const {map, title, id,logo} = item
const {t} = useTranslation()
	const {checkLanguage} = useLanguageContext()

	return (
		<>
			<Wrapper>
				<Accordion.Item eventKey={id} className="my-3">
					<Accordion.Header className = "d-flex align-items-center"
						onClick={() => showPlacemarks(map)}>
						<div className = "header_logo">
							<img src={logo || "https://w7.pngwing.com/pngs/857/703/png-transparent-circle-white-black-font-empty-superman-logo-rim-black-and-white-empty-superman-logo.png"} alt=""/>
						</div>
						<div>
							{(checkLanguage(item).title)}
						</div>
					</Accordion.Header>

					{
						map.map((item) => {
								return <Accordion.Body key={item.id} onClick={() => {
									showPlacemark(item)
								}}>
									<div className="logo">
										<img src={item.logo} alt=""/>
									</div>
									<div>
										<div className="title"> {(checkLanguage(item).title)}</div>
										<p className="address">{(checkLanguage(item).place)}</p>
										<p className = "show_link" >{t("Посмотреть на карте")}</p>
									</div>
								</Accordion.Body>
							}
						)
					}
				</Accordion.Item>
			</Wrapper>
		</>
	)
}

export default MapAccordtion

const Wrapper = styled.div`
  .accordion-button {
    background: #fff;
    padding-top: 9px;
    padding-bottom: 9px;
    //box-shadow: 0px 9px 18px rgba(204, 204, 204, 0.4);
    border: none;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #161616;
    //color: red;
  }
  .header_logo{
    width: 30px;
    height: 30px;
    margin-right: 10px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .accordion-header {
    border: none;
  }

  .accordion-button::after {
    flex-shrink: 0;
    width: 0;
    height: 0;
    margin-left: auto;
    content: "";
    //background-image: url(data:image/svg+xml,%3csvg xmlns= 'http://www.w3.org/2000/svg' viewBox= '0 0 16 16' fill= '%23212529' %3e%3cpath fill-rule= 'evenodd' d= 'M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' /%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
  }


  .accordion-body {
    background: #fff;
    background: #FFFFFF;
    box-shadow: 0px 9px 18px rgba(204, 204, 204, 0.4);
    border-radius: 7.12791px;
    margin: 9px 0;
    cursor: pointer;
    display: flex;
    align-items: center;

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      word-break: break-word;
    }

    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #999999;
    }

    .logo {
      width: 30px;
      height: 30px;
      margin-right: 20px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        width: 30px;
      }
    }

    .show_link {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      color: #1862AF;
    }
  }
`