import axios from "axios";
// http://209.97.135.135/
const url = "https://ime.kg/";

export const axiosInstance = axios.create({
  baseURL: url,
  headers: {
    "Content-type": "application/json",
  },
});
