import { axiosInstance } from "./axiosInstance";

export const getEventsFeed = async () => {
  try {
    const response = await axiosInstance.get("/announcement/");
    return response;
  } catch (error) {}
};
export const getEventsFeedDetail = async (
  setIsLoading,
  setAnnouncements,
  id
) => {
  setIsLoading(true);
  try {
    const response = await axiosInstance.get(`/announcement/${id}`);
    const data = response.data;
    setAnnouncements(data);
    setIsLoading(false);
  } catch (error) {
    console.log("error =>", error.response);
    setIsLoading(false);
  }
};
export const getVacancies = async (
  setIsLoading,
  setVacancies,
  setFilteredVacancies
) => {
  setIsLoading(true);
  try {
    const response = await axiosInstance.get("/vacancy/");
    const transformData = response.data.results.map((item) => {
      return item;
    });
    setVacancies(transformData);
    setFilteredVacancies(transformData);
    setIsLoading(false);
  } catch (error) {
    console.log("error =>", error);
    setIsLoading(false);
  }
};
export const getVacancy = async (setIsLoading, setVacancy, id) => {
  setIsLoading(true);
  try {
    const response = await axiosInstance.get(`/vacancy/${id}`);
    const data = response.data;
    setVacancy(data);
    setIsLoading(false);
  } catch (error) {
    console.log("error =>", error);
    setIsLoading(false);
  }
};
export const getVideos = async (setIsLoading, setVideos) => {
  setIsLoading(true);
  try {
    const response = await axiosInstance.get("/video_gallery/");

    const transformData = response.data.map((item) => {
      return item;
    });
    setVideos(transformData);
    setIsLoading(false);
  } catch (error) {
    console.log("error =>", error);
    setIsLoading(false);
  }
};

export const getCrew = async () => {
  try {
    const response = await axiosInstance.get("/crew/");
    // const data = response.data.results;
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response };
  }
};
export const getSingleCrew = async (id) => {
  try {
    const response = await axiosInstance.get(`/crew/${id}/`);
    console.log("response", response);
    // const data = response.data.results;
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response };
  }
};
export const getMap = async () => {
  try {
    const response = await axiosInstance.get("categoryorganization/");
    return response.data;
  } catch (error) {
    console.log("error =>", error);
  }
};
export const getPartner = async (setIsLoading, setPartner) => {
  setIsLoading(true);
  try {
    const response = await axiosInstance.get("/partner/");
    const data = response.data.results;
    setPartner(data);
    setIsLoading(false);
  } catch (error) {
    console.log("error =>", error);
    setIsLoading(false);
  }
};
export const getDonor = async (setIsLoading, setDonor) => {
  setIsLoading(true);
  try {
    const response = await axiosInstance.get("/donor/");
    const data = response.data;
    setDonor(data);
    setIsLoading(false);
  } catch (error) {
    console.log("error =>", error);
    setIsLoading(false);
  }
};
//
export const getPhase = async (id) => {
  try {
    const response = await axiosInstance.get(`/phaze/${id}/`);

    return response;
  } catch (error) {
    console.log("error =>", error.response);
  }
};

export const searchByDate = async (date) => {
  try {
    const response = await axiosInstance.get(
      `api/search/calendar_data/?search=${date}`
    );
    return { success: true, data: response };
  } catch (error) {
    return { success: false, data: error.data };
  }
};
export const searchFromMap = async (mapSearchInput) => {
  try {
    const response = await axiosInstance.get(
      `api/map_search/?search=${mapSearchInput}`
    );

    return response;
  } catch (error) {
    console.log("error =>", error);
  }
};

export const getAddressInfo = async () => {
  try {
    const response = await axiosInstance.get(`/address/`);
    console.log("response", response);
    return { success: true, data: response };
  } catch (error) {
    return { success: false, data: error.data };
  }
};
