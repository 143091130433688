import React, {useState} from 'react'
import styled from "styled-components"


const FilterVacancies = ({setVacancies, vacancies, setFilteredVacancies}) => {

	const [isActive, setIsActive] = useState("all")


	const handleFilter = (e) => {

		setIsActive(e.target.value)
		if (e.target.value === "all") {
			setFilteredVacancies(vacancies)
		}
		if (e.target.value === "1") {
			const transformFilter = vacancies.filter((item) => !item.partner_bool)
			setFilteredVacancies(transformFilter)
		}
		if (e.target.value === "2") {
			const transformFilter = vacancies.filter((item) => item.partner_bool)
			setFilteredVacancies(transformFilter)
		}


	}


	return (
		<Wrapper>
			<button onClick={handleFilter} value={"all"}
			        className={`gray-bg ${isActive === "all" ? "active_btn" : ""}`}>Все
			</button>
			<button onClick={handleFilter} value={"1"}
			        className={`gray-bg ${isActive === "1" ? "active_btn" : ""}`}>Наши вакансии
			</button>
			<button onClick={handleFilter} value={"2"}
			        className={`gray-bg ${isActive === "2" ? "active_btn" : ""}`}>Вакансии партнеров
			</button>
		</Wrapper>
	)
}

export default FilterVacancies

const Wrapper = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

  button {
    background: #1862AF;
    border-radius: 7.13483px;
    border: none;
    //width: 75.62px;
    //height: 37.81px;
    padding: 5px 20px;
    color: white;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    background: #949494;
    margin-top: 20px;
    color: #FFFFFF;
  }

  .active_btn {
    background: #1862AF;
  }

`
