import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import SectionTitles from "../../constants/SectionTitles";
import {axiosInstance} from "../../api/axiosInstance";
import {Link, useHistory} from "react-router-dom";
import {useLanguageContext} from "../../Context/Context";
import {capitalizeStr} from "../../helpers/Capitalize";
import {useTranslation} from "react-i18next";
import Loader from "../Loaders/Loader";


const ReformFaces = () => {
	const history = useHistory()
	const [isLoading , setIsLoading] = useState("")
	const [getReformFaces, setGetReformFaces] = useState([])
	const {checkLanguage} = useLanguageContext()
	const {t} = useTranslation();

	const getFaces = async () => {
		setIsLoading(true)
		try {
			const response = await axiosInstance.get("reforma/")
			setGetReformFaces(response.data)
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.log("error =>", error)
		}
	}


	useEffect(() => {
		getFaces()
	}, [])

	return (
		<Wrapper>
			<div className="container">
				<div className="container-fluid">
					<Link to={"/reform-face"}>
						<SectionTitles>{t("ReformFace")}</SectionTitles>
					</Link>
					<div className="  reform_face_content">
						{
							getReformFaces.slice(0, 4).map((item) => {
								return <div className="reform_face_item"
								            onClick={() => history.push(`/reform-face/${item.id}`)} key={item.id}>
									<div className="reform_face_item_img">
										<img src={item.image} alt=""/>
									</div>
									<div className="reform_face_item_title">
										{(checkLanguage(item).title)}
									</div>
									<div className="reform_face_item_text">
										{checkLanguage(item).research.substring(0,200)}...
									</div>
								</div>
							})
						}

					</div>
				</div>
			</div>
			{
				isLoading && <Loader/>
			}
		</Wrapper>
	)
}

export default ReformFaces

const Wrapper = styled.div`
  //padding: 50px 0;
  margin: 0 auto;

  .reform_face_content {
    text-align: center;
    margin: 0 auto;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    @media (max-width: 800px) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 20px;
    }
    @media (max-width: 500px) {
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      grid-gap: 20px;
    }

    img {
      margin: 0 auto;
      width: 100%;
    }
  }

  .reform_face_item {
    cursor: pointer;
    margin-top: 20px;
    max-width: 260px;
    //margin-bottom: 50px;

    &:nth-child(2) {
      margin-top: 120px;
      @media (max-width: 900px) {
        margin-top: 20px;
      }
    }

    &:last-child {
      margin-top: 140px;
      @media (max-width: 900px) {
        margin-top: 20px;
      }
    }


    .reform_face_item_title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #20386E;
      @media (max-width: 500px) {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-transform: capitalize;
        text-align: center;
        color: #20386E;
      }

    }

    .reform_face_item_text {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #949494;
      @media (max-width: 500px) {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #949494;
      }
    }

    img {
      //width: 100%;
      //outline: 7px solid #008DD2;
      //outline-offset: 5px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      /* Firefox 1-3.6 */
      -moz-border-radius: 50%;
      height: 230px;
      width: 230px;
      object-fit: cover;
      padding: 5px;
      border: 7px solid #008DD2;
      @media (max-width: 800px) {
        //outline: 4px solid #008DD2;
        //outline-offset: 4px;
        padding: 4px;
        border: 4px solid #008DD2;
      }
      @media(max-width: 768px){
        height: 180px;
        width: 180px;
      }
      @media (max-width: 500px) {
        //outline: 4px solid #008DD2;
        //outline-offset: 4px;
        padding: 3px;
        border: 3px solid #008DD2;
      }

    }
  }

  .reform_face_item_img {
    text-align: center;
  }

  .reform_face_item_img {
    margin-bottom: 15px;
  }
`

