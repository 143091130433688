import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {capitalizeStr} from "../../helpers/Capitalize";
import {useLanguageContext} from "../../Context/Context";

const ResponsiveHeaderMenu = ({el, setBurger}) => {

    const {test , language} = useLanguageContext()
    const [isOpen, setIsOpen] = useState(false)

    const accordtionOpen = ({}) => {
        setIsOpen(!isOpen)
    }
    return (
        <li className="header_accordtion" key={el.id}><span onClick={accordtionOpen}><span>
            {language === "ru" && capitalizeStr(el.title_ru)}
            {language === "en" && capitalizeStr(el.title_en)}
            {language === "ky" && capitalizeStr(el.title_kg)}

            {el.title}
        </span><div><i className={isOpen ? 'top-arrow' : ''}></i></div></span>
            <ul>
                {
                  isOpen && el.info.map((links, id) => {
                        return <Link to={links.link}>
                            <li onClick={() => setBurger(false)} className={'header_accordtion_item'}>
                                { language === "ru" &&   capitalizeStr(links.title_ru)}
                                { language === "en" &&   capitalizeStr(links.title_en)}
                                { language === "ky" &&   capitalizeStr(links.title_kg)}
                            </li>
                        </Link>
                    })
                }
            </ul>
        </li>
    );
};

export default ResponsiveHeaderMenu;