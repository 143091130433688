import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SectionTitles from "../../constants/SectionTitles";
import ContentSidebar from "../../constants/ContentSidebar";
import { Carousel } from "react-responsive-carousel";
import RightArrow from "../../assets/images/next_right_arrow.png";
import { Link, useHistory } from "react-router-dom";
import ScrollToTop from "../../constants/ScrollToTop/ScrollToTop";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../Context/Context";
import { capitalizeStr } from "../../helpers/Capitalize";
import Loader from "../../components/Loaders/Loader";

const HomeNews = ({ news, isLoading }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { checkLanguage } = useLanguageContext();

  return (
    <Wrapper>
      <ScrollToTop />
      <div className="home_news_responsive_header"></div>
      <div className="container ">
        <Link to={"/news"}>
          <SectionTitles>{t("News")} </SectionTitles>
        </Link>
        <div className="home_news_content">
          <div className="row">
            <div
              className="col-md-9 col-sm-12 col-xs-12"
              style={{ alignSelf: "auto" }}
            >
              <div className=" home_news_items">
                {news?.slice(0, 9).map((item) => {
                  return (
                    <div
                      className="home_news_item "
                      onClick={() => history.push(`/news/${item.id}`)}
                      key={item.id}
                    >
                      <div className="mb-3 home_news_image_wrapper">
                        {item.small ? (
                          <img
                            src={item.small}
                            alt=""
                            className="home_news_image"
                          />
                        ) : (
                          <img
                            className="home_news_image"
                            src="https://www.andromo.com/wp-content/uploads/2020/12/news-1.jpg"
                            alt=""
                          />
                        )}
                      </div>
                      <div className="p-2 info_content ">
                        <h4 className="home_news_title">
                          {item.title.length > 60
                            ? checkLanguage(item).title.substring(0, 60) + "..."
                            : checkLanguage(item).title}
                        </h4>
                        <p className="home_news_text">
                          {checkLanguage(item).description}...
                        </p>
                        <div className="item_footer d-flex justify-content-between">
                          <div className="news_date">
                            {moment(item.created).locale("ru").format("LL")}
                          </div>
                          <Link
                            to={`/news/${item.id}`}
                            className="home_news_btn ml-3"
                          >
                            {t("Подробнее")}...
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="col-md-3 col-sm-12">
              <ContentSidebar recpublic={false} recentPublicData={news} />
            </div>
          </div>
        </div>
        <div className="home_news_responsive">
          <div className=" home_news_items">
            {news?.slice(0, 3).map((item) => {
              return (
                <div
                  className="home_news_item card"
                  onClick={() => history.push(`/news/${item.id}`)}
                  key={item.id}
                >
                  <div className="mb-3 home_news_image_wrapper">
                    {item.mainphoto ? (
                      <img
                        src={item.mainphoto}
                        alt=""
                        className="home_news_image"
                      />
                    ) : (
                      <img
                        className="home_news_image"
                        src="https://www.andromo.com/wp-content/uploads/2020/12/news-1.jpg"
                        alt=""
                      />
                    )}
                  </div>
                  <div className="p-2 info_content ">
                    <h4 className="home_news_title">
                      {capitalizeStr(checkLanguage(item).title)}
                    </h4>
                    <p className="home_news_text">
                      {capitalizeStr(checkLanguage(item).description)}...
                    </p>
                    <div className="item_footer d-flex justify-content-between">
                      <div className="news_date">
                        {moment(item.created).locale("ru").format("LL")}
                      </div>
                      <Link
                        to={`/news/${item.id}`}
                        className="home_news_btn ml-3"
                      >
                        {t("Подробнее")}...
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </Wrapper>
  );
};

export default HomeNews;

const Wrapper = styled.div`
  padding: 10px 0 30px;
  position: relative;
  @media (max-width: 768px) {
    min-height: 60vh;
  }

  .container {
    @media (max-width: 600px) {
      padding: 0;
    }
  }

  .container-fluid {
    @media (max-width: 768px) {
      padding: 0;
    }
  }

  .home_news_content {
    @media (max-width: 768px) {
      display: none;
    }

    .home_news_image_wrapper {
      width: 100%;
    }

    .home_news_image {
      margin-bottom: -10px;
      width: 100%;
      object-fit: cover;
      height: 200px;
      border-radius: 10px;
    }

    .home_news_item {
      cursor: pointer;
      max-width: 260px;
      position: relative;
      border-radius: 10px;
      //height: 400px;
      background: #fafafa;
      box-shadow: 0px 9px 18px rgba(204, 204, 204, 0.4);
      margin: 5px 0;

      .item_footer {
        position: absolute;
        bottom: 10px;
      }
    }

    .home_news_title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      position: relative;
      display: inline-block;

      :before {
        content: "";
        position: absolute;
        width: 100px;
        height: 2px;
        background: #cadb36;
        bottom: -3px;
      }
    }

    .info_content {
      p {
        margin-left: 0 !important;
      }
    }

    .home_news_text {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      padding-bottom: 25px;
      text-overflow: ellipsis;
    }

    .news_date {
      font-size: 12px;
      line-height: 15px;
      color: #949494;
    }

    .home_news_btn {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #1862af;
      margin-left: 15px;
      cursor: pointer;
    }

    .home_news_items {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      @media (max-width: 900px) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }
    }
  }

  .home_news_responsive {
    display: none;
    //background: #ffc222;
    min-height: 180px;
    @media (max-width: 768px) {
      display: block;
    }

    .home_news_item {
      margin: 0 10px;
      margin-bottom: 30px;
      box-shadow: 1px 1px 12px 7px rgba(0, 0, 0, 0.5);
    }
  }

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    word-break: break-all;
  }

  h3 {
    color: #000;
  }

  .home_news_image_wrapper {
    width: 100%;
  }

  .home_news_image {
    margin-bottom: -10px;
    width: 100%;
    //margin: 0 auto;
    object-fit: cover;
    height: 200px;
    border-radius: 10px;
  }
`;
