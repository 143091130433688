import React from 'react';
import styled from "styled-components";
import greenArrow from '../../assets/images/vacancies/greenArrow.png'
import logoOrg from '../../assets/images/vacancies/logoOrg.png'
import {Link, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useLanguageContext} from "../../Context/Context";

export const VacanciesCard = ({el}) => {
	const {t} = useTranslation();
	const {checkLanguage} = useLanguageContext()

	return (
		<Link to={`vacancies/${el.id}/`}>

			<Wrapper>
				<div className="content"></div>
				<div className='logo'>
					<img src={el.logo ? el.logo : logoOrg} alt=""/>
				</div>
				<div className='text'>
					<div className='zagolovok'>
						<h5>
							{t("Компания")}
						</h5>
						<h5>
							{t("Должность")}
						</h5>
						<h5>
							{t("Оклад")}
						</h5>
						<h5>
							{t("Место")}
						</h5>
					</div>
					<div className='info'>
						<p>{el?.organization} </p>
						<p>{checkLanguage(el)?.title}</p>
						<p>{el?.salary} </p>
						<p>{el?.city}</p>
						<div>
							<img src={greenArrow} alt=""/>
						</div>
					</div>
				</div>
			</Wrapper>
		</Link>
	);
};

export const ResponsiveVacanciesCard = ({el}) => {
	const {t} = useTranslation();
	const history = useHistory()
	const {checkLanguage} = useLanguageContext()

	return <ResponsiveWrapper onClick={() => history.push(`/vacancies/${el.id}`)}>
		<div className={'logo'}>
			<img src={el.logo? el.logo : logoOrg} alt=""/>
		</div>
		<div className={'info'}>
			<div>
				<h2>{t("Компания")}</h2>
				<p>{el.organization}</p>
			</div>
			<div>
				<h2>{t("Должность")}</h2>
				<p>{checkLanguage(el)?.title}</p>
			</div>
			<div>
				<h2>{t("Оклад")}</h2>
				<p>{el?.salary} </p>
			</div>
			<div>
				<h2>{t("Место")}</h2>
				<p>{el?.city}</p>
			</div>
		</div>
	</ResponsiveWrapper>

}
const Wrapper = styled.div`
  margin-bottom: 24px;
  background: #FBFBFB;
  box-shadow: 0px 6.0566px 12.1132px rgba(204, 204, 204, 0.4);
  border-radius: 6.72956px;
  display: flex;

  .logo {
    display: flex;
    margin-left: 12px;
    margin-right: 17px;
    align-items: center;
width: 65px;
    img {
      width: 100%;
      height: 58px;
object-fit: cover;
      border-radius: 6.72956px;
    }
  }
}

.zagolovok {
  display: flex;
  justify-content: space-between;
  max-width: 490px;
}

.text {
  margin: 9px 0;
  width: 620px;
  margin-left: 18px;

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      margin-top: -20px;
        img{
          transform: translateX(45px);

      }
    }
  }

  div h5 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.6);
  }

  div p {
    width: 120px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #0F0F0F;
  }
}

@media screen and (max-width: 1100px) {
  .text .info {
    justify-content: space-evenly;
  }

  @media screen and (max-width: 1050px) {
    display: none;
`
const ResponsiveWrapper = styled.div`
  display: flex;
  display: none;
  background: #FBFBFB;
  box-shadow: 0px 9px 18px rgba(204, 204, 204, 0.4);
  border-radius: 10px;
  margin-bottom: 14px;
  cursor: pointer;

  .logo {
    margin-left: 10px;
    margin-top: 10px;
    width: 65px;
    img {
      width: 100%;
      height: 58px;
      object-fit: cover;
      border-radius: 6.72956px;
    }
  }

  .info {
    margin-left: 14px;
    margin-top: 10px;

    h2 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #1862AF;
    }

    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #0F0F0F;
    }

  }

  @media screen and (max-width: 1050px) {
    display: flex;
  }
`