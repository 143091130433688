import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import SectionTitles from "../../constants/SectionTitles";
import ContentSidebar from "../../constants/ContentSidebar";
import {axiosInstance} from "../../api/axiosInstance";
import {Link, useHistory} from "react-router-dom";
import Loader from "../../components/Loaders/Loader";
import Path from "../../constants/Path";
import ScrollToTop from "../../constants/ScrollToTop/ScrollToTop";
import ReactPaginate from 'react-paginate';
import moment from "moment";
import {useTranslation} from "react-i18next";
import {useLanguageContext} from "../../Context/Context";
import {capitalizeStr} from "../../helpers/Capitalize";

const HomeNews = () => {
	const [news, setNews] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const count = news?.count
	const history = useHistory()
	const {t} = useTranslation()
	const {checkLanguage} = useLanguageContext()


	const getNews = async (page) => {
		setIsLoading(true)
		const perPage = 6;
		const page_size = page * perPage - perPage;

		try {
			const response = await axiosInstance.get(`news_api/?page=${page}&page_size=${perPage}`)
			setNews({...response.data})
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
		}
	}


	useEffect(() => {
		getNews(1)
	}, [])

	return (
		<Wrapper>
			<ScrollToTop/>

			<div className="container ">
				<div className="container-fluid">
					<SectionTitles>{t("News")}</SectionTitles>
					<Path title={"Новости"}/>
					<div className="home_news_content">
						<div className="row">
							<div className="col-md-9 col-sm-12 col-xs-12">
								<div className=" home_news_items">
									{
										news?.results?.map((item) => {
											return (
												<div className="home_news_item " key={item.id}
												     onClick={() => history.push(`/news/${item.id}`)}>
													<div className="mb-3">
														{
															item.small ? <img src={item.small} alt=""
															                  className="home_news_image"/> :
																<img className="home_news_image"
																     src="https://www.andromo.com/wp-content/uploads/2020/12/news-1.jpg"
																     alt=""/>
														}
													</div>
													<div className="p-2 info_content">
														<h4 className="home_news_title">{item.title.length > 60 ?  (checkLanguage(item).title.substring(0,60)) + "..." :  (checkLanguage(item).title)}</h4>
														<p className="home_news_text">{(checkLanguage(item).description)}...</p>
														<div className="item_footer d-flex justify-content-between">
															<div className="news_date">
																{moment(item.created).locale('ru').format('LL')}
															</div>
															<Link to={`/news/${item.id}`}
															      className="home_news_btn ml-3">{t("Подробнее")}...</Link>
														</div>
													</div>
												</div>
											)
										})
									}

								</div>

							</div>

							<div className="col-md-3 col-sm-12">
								<ContentSidebar recpublic={false}/>
							</div>

						</div>
						<div className="pagination">
							<ReactPaginate
								pageCount={Math.ceil(count / 6)}
								pageRangeDisplayed={2}
								// nextLabel={"Следующая страница"}
								marginPagesDisplayed={1}
								onPageChange={(data) => getNews(data.selected + 1)}
								activeClassName={"active_paginate"}/>
						</div>
					</div>

				</div>
			</div>
			{isLoading && <Loader/>}
		</Wrapper>
	)
}

export default HomeNews

const Wrapper = styled.div`
  padding: 10px 0 30px;
  position: relative;
  @media (max-width: 600px) {
    min-height: 60vh;
  }

  .container {
    @media (max-width: 600px) {
      padding: 0;
    }
  }

  .container-fluid {
    @media (max-width: 600px) {
      padding: 0;
    }
  }

  .home_news_content {

    .home_news_image {
      margin-bottom: -10px;
      width: 100%;
      object-fit: cover;
      height: 200px;
      border-radius: 10px;
    }

    .home_news_item {
      cursor: pointer;
      position: relative;
      //margin: 0 auto;
      height: 400px;
      border-radius: 10px;
      background: #FAFAFA;
      box-shadow: 0px 9px 18px rgba(204, 204, 204, 0.4);


      @media (max-width: 768px) {
        //max-width: 260px;

      }
      @media (max-width: 500px) {
        margin: 5px 5px;
      }

      .item_footer {
        position: absolute;
        bottom: 10px;
      }
    }

    .home_news_title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      //border-bottom: 2px solid #CADB36;
      position: relative;
      display: inline-block;

      :before {
        content: "";
        position: absolute;
        width: 100px;
        height: 2px;
        background: #CADB36;
        bottom: -3px;
      }
    }

    .info_content {
      p {
        margin-left: 0 !important;
      }
    }

    .home_news_text {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      padding-bottom: 25px;
      //text-overflow: ellipsis;
      white-space: pre-wrap;

    }

    .news_date {
      font-size: 12px;
      line-height: 15px;
      color: #949494;
    }

    .home_news_btn {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #1862AF;
      margin-left: 15px;
      cursor: pointer;

    }


  }

  .home_news_items {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    @media (max-width: 900px) {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }

  h3 {
    color: #000;
  }

  .card {
    //background: rgba(255, 255, 255, 0.3);
    //height: 100%;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000;
    z-index: 1000;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #000;
    z-index: 1000;
  }

  .control-arrow {
    //background: ;
    cursor: pointer;
    z-index: 100;
  }

  .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    //opacity: .4;
    //filter: alpha(opacity=40);
    z-index: 100;
    border: 0;
    font-size: 50px;
    cursor: pointer;
  }

  .pagination {
    margin-top: 40px;
    margin-bottom: 60px;

    ul {
      position: relative;
      display: flex;
      //width: 100%;
      justify-content: space-around;
      max-width: 90vh;
      margin: 0 auto;
      padding-left: 0px;
      align-items: center;

      li {

        //width: 20px;
        a {
          text-decoration: none;
          margin: 0 20px;
          list-style: none;
          padding: 10px;
          //border: 2px solid red;
          cursor: pointer;
        }
      }
    }

    .active_paginate {
      background: rgba(202, 219, 54, 0.8);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      //padding-right: 0;
    }

    .previous {
      display: none;
    }

    .next {
      position: absolute;
      bottom: -50px;
      display: none;

    }

    .next.disabled {
      a {
        background: #ccc;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
  }
  @media screen and (max-width: 768px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 100%;
    }

    .col-md-3 {
      width: 100%;
    }

    .home_news_info_content {
      margin-bottom: 50px;
    }
  }
  @media screen and (max-width: 425px) {
    .col-md-9 {
      flex: 0 0 auto;
      width: 100%;
    }

    .col-md-3 {
      width: 100%;
    }
  }
`
