import React, {useState} from 'react'
import styled from "styled-components"
import SearchIcon from "../../assets/images/header/searchIcon.png"
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const SearchInput = ({openInput , inputOpen}) => {
	const [searchInput, setSearchInput] = useState("")
	const history = useHistory()
	const {t} = useTranslation();

	const handleSearch = (e) => {
		e.preventDefault()
		if (searchInput) {
			history.push(`/search/${searchInput}`)
		}
		setSearchInput("")
	}



	return (
		<Wrapper>
			<form onSubmit={handleSearch}>
				{
					inputOpen && <input id="search" name="search" type="text"
					                    placeholder={t("Search")}
					                    value={searchInput}
					                    className="search_input"
					                    required={true}
					                    autoFocus={true}
					                    onChange={(e) => setSearchInput(e.target.value)}
					/>
				}

				{
					inputOpen &&
					<button type={"submit"} className="submit_button " onClick={handleSearch}><img src={SearchIcon}
					                                                                               alt=""/>
					</button>
				}
				{
					!inputOpen &&
					<button type={"submit"} className="open" onClick={openInput}><img src={SearchIcon} alt=""/>
					</button>
				}

				{inputOpen &&
				<button type={"button"} className="close" onClick={openInput}><img
					src={"https://icon-library.com/images/close-icon/close-icon-12.jpg"} alt=""/>
				</button>}

			</form>
		</Wrapper>
	)
}

export default SearchInput

const Wrapper = styled.div`
  form {
    display: flex;
    align-items: center;
    position: relative;

    .search_input {
      background: #fff;
      //height: 60px;
      padding: 13px 10px;
      font-size: 20px;
      display: inline-block;
      font-family: "Lato";
      font-weight: 100;
      border: none;
      //border-radius: 50%;
      outline: none;
      color: #555;
      //padding: 3px;
      width: 450px;
      position: absolute;
      top: 3px;
      background: #FFFFFF;
      border-radius: 24.5px;
      right: 60px;
      //background: #fff;
      z-index: 102;
      //opacity: 1;
      transition: width .4s cubic-bezier(0.000, 0.795, 0.000, 1.000);
      //cursor: pointer;
      box-shadow: 3px 0px 24px 11px rgba(0,0,0,0.2);
      @media (max-width: 768px) {
        width: 200px;
        top: -3px;
        right: 45px;
        box-shadow: 3px 0px 24px 11px rgba(0,0,0,0.46);
      }
    }

    .submit_button {
      position: absolute;
      left: -60px;
      z-index: 1000;

      img {
        width: 40px;
        height: 40px;
      }

    }

    button {
      border: none;
      outline: none;
      background: #fff;
      border-radius: 50%;

      img {
        width: 50px;
        height: 50px;
        @media (max-width: 768px) {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
`
