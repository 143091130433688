    import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import SectionTitles from "../../constants/SectionTitles";
import imag_1 from "../../assets/images/homePartners/image-1.png"
import imag_2 from "../../assets/images/homePartners/image-2.png"
import imag_3 from "../../assets/images/homePartners/image-3.png"
    import noImage from "../../assets/images/homePartners/no-image-square.jpg"
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {getPartner} from "../../api/Requests";
    import {useLanguageContext} from "../../Context/Context";
    import { Swiper, SwiperSlide } from 'swiper/react';

const HomePartners = () => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const [partner, setPartner] = useState([])
    const { checkLanguage} = useLanguageContext()

    useEffect(() => {
        getPartner(setIsLoading, setPartner )
    }, [])

    return (
        <Wrapper>
            <div className="container">
                <div className="container-fluid">
                    <Link to={"/partners"}>
                        <SectionTitles>{t("Наши партнеры")}</SectionTitles>
                    </Link>
                        <Swiper
                            spaceBetween={10}
                            slidesPerView={3}
                            className="home_partner_content"
                            breakpoints={{
                                0: {
                                    width: 0,
                                    slidesPerView: 1,
                                },
                                768: {
                                    width: 768,
                                    slidesPerView: 2,
                                },
                                1200: {
                                    width: 1200,
                                    slidesPerView: 3,
                                },
                            }}
                        >
                        { partner?.map(el => {
                            return(
                                <SwiperSlide className="home_partner_item" key={el.id}>
                                    <div className="home_partner_item_image">
                                        <img src={el.logo ? el.logo : noImage } alt=""/>
                                    </div>
                                    <div className="home_partner_item_text">
                                        <a href={el.link} target={'_blank'}>
                                            {(checkLanguage(el).title)}
                                        </a>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                        </Swiper>

                </div>

            </div>
        </Wrapper>
    )
}

export default HomePartners

const Wrapper = styled.div`
  padding: 40px 0;
  padding-top: 1px;

  .home_partner_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    @media(max-width: 768px){
      justify-content: center;
        text-align: center;
    }
    //

    .home_partner_item {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-bottom: 30px;
      text-align: center;
      //max-width: 33.33%;
        background: #FFFFFF;
        //box-shadow: 0px 9px 18px rgba(204, 204, 204, 0.4);
        border-radius: 10px;
        padding: 20px;
      a {
        text-decoration: none;
      }
      height: 400px;

      .home_partner_item_text {
        //width: 200px;
        //margin: 0 auto;
          a{
              font-size: 15px;
          }
      }
    }
  }
  .home_partner_item_image{
 height:270px ;
    padding: 20px;
    @media(max-width: 600px){
      //background: #FFFFFF;
      //box-shadow: 0px 9px 18px rgba(204, 204, 204, 0.4);
      //border-radius: 10px;
    }
    img{
      width: 100%;
            height: 100%;
          object-fit: contain;
    }
  }
`
